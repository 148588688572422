/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./novelties-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@gipisistemas/ng-core/gipisistemas-ng-core.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@gipisistemas/ng-core";
import * as i5 from "@angular/material/dialog";
import * as i6 from "./novelties-dialog.component";
import * as i7 from "@angular/router";
var styles_GIPINoveltiesDialogComponent = [i0.styles];
var RenderType_GIPINoveltiesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GIPINoveltiesDialogComponent, data: {} });
export { RenderType_GIPINoveltiesDialogComponent as RenderType_GIPINoveltiesDialogComponent };
export function View_GIPINoveltiesDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "gipi-abstract-form", [["class", "form-content-novelties gipi-abstract-form"], ["id", "dialogNovelties"], ["name", "dialogNovelties"]], [[1, "id", 0], [1, "name", 0], [4, "margin", null]], null, null, i2.View_GIPIAbstractFormComponent_0, i2.RenderType_GIPIAbstractFormComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.GIPIAbstractFormComponent]), i1.ɵdid(3, 4308992, null, 4, i4.GIPIAbstractFormComponent, [], { id: [0, "id"], name: [1, "name"], dialog: [2, "dialog"], class: [3, "class"] }, null), i1.ɵqud(603979776, 2, { GIPIActionRowComponent: 1 }), i1.ɵqud(603979776, 3, { matDialogTitle: 0 }), i1.ɵqud(603979776, 4, { matDialogContent: 0 }), i1.ɵqud(603979776, 5, { matDialogActions: 0 }), (_l()(), i1.ɵeld(8, 0, [[3, 0]], 0, 7, "div", [["class", "novelties-header mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(9, 81920, null, 0, i5.MatDialogTitle, [[2, i5.MatDialogRef], i1.ElementRef, i5.MatDialog], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["role", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Novidades do sistema "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "span", [["class", "novelties-actions"], ["role", "textbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "gipi-button", [["class", "gipi-button"], ["gipi-icon", ""], ["icon", "close"], ["id", "btnClose"], ["tooltip", "Fechar"]], null, [[null, "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_GIPIButtonComponent_0, i2.RenderType_GIPIButtonComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.GIPIButtonComponent]), i1.ɵdid(15, 245760, null, 0, i4.GIPIButtonComponent, [i1.ElementRef], { id: [0, "id"], icon: [1, "icon"], tooltip: [2, "tooltip"] }, { onClick: "click" }), (_l()(), i1.ɵeld(16, 0, [[4, 0]], 0, 2, "div", [["class", "novelties-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(18, 0, [[1, 0], ["iframeRef", 1]], null, 0, "iframe", [["frameborder", "0"], ["height", "100%"], ["width", "100%"]], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = "dialogNovelties"; var currVal_4 = "dialogNovelties"; var currVal_5 = true; var currVal_6 = "form-content-novelties"; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); _ck(_v, 9, 0); var currVal_8 = "btnClose"; var currVal_9 = "close"; var currVal_10 = "Fechar"; _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).attrId; var currVal_1 = i1.ɵnov(_v, 3).attrName; var currVal_2 = i1.ɵnov(_v, 3).attrMargin; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = i1.ɵnov(_v, 9).id; _ck(_v, 8, 0, currVal_7); }); }
export function View_GIPINoveltiesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gipi-novelties-dialog", [], null, null, null, View_GIPINoveltiesDialogComponent_0, RenderType_GIPINoveltiesDialogComponent)), i1.ɵdid(1, 4440064, null, 0, i6.GIPINoveltiesDialogComponent, [i5.MatDialogRef, [2, i5.MAT_DIALOG_DATA], i4.MessageService, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GIPINoveltiesDialogComponentNgFactory = i1.ɵccf("gipi-novelties-dialog", i6.GIPINoveltiesDialogComponent, View_GIPINoveltiesDialogComponent_Host_0, { loading: "loading" }, { loadingChange: "loadingChange" }, []);
export { GIPINoveltiesDialogComponentNgFactory as GIPINoveltiesDialogComponentNgFactory };
