import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCreditClient } from '@gipi-financial/credit-client/models/credit-client.model';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class FinancialReceivement extends GIPIBaseAbstractModel {

    billInstallment: FinancialBillInstallment;

    fine: number;

    interest: number;

    discount: number;

    amount: number;

    amountReceivable: number;

    // When use credit
    amountReceived: number;

    amountNet: number;

    receivementDate: Date;

    bankAccount: FinancialBankAccount;

    chargeType: FinancialChargeType;

    checkReceivedList: FinancialCheckReceived[];

    comments: string;

    attachment: string;

    /** @Transient */
    fileAttachment: File;

    historic: string;

    usedCustomerCredit: number;

    creditClientList: FinancialCreditClient[];

    customerCreditAmount: number;

    shift: FinancialCashierShift;

    fineChangedOnReceipt: boolean;

    interestChangedOnReceipt: boolean;

    addition: number;

    receiptOfAdditionId: number;

    receivementCardList: FinancialReceivementCard[];

    billetReturn: boolean = false;

    dischargeDate: Date;

}
