import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationBenefitClub } from '@gipi-registration/benefit-club/models/benefit-club.model';
import { RegistrationBenefitClubService } from '@gipi-registration/benefit-club/services/benefit-club.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface BenefitClubData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    benefitClub: RegistrationBenefitClub;
}

@Component({
    templateUrl: './benefit-club-form-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class BenefitClubFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public benefitClub: RegistrationBenefitClub = new RegistrationBenefitClub();

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    constructor(
        protected service: RegistrationBenefitClubService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<BenefitClubFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BenefitClubData = { typeOperation: 'NEW', benefitClub: null },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.benefitClub = this.data.benefitClub;

            if (!this.benefitClub.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.benefitClub)) {
                return;
            }
            if (StringUtil.isEmpty(this.benefitClub.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.benefitClub.description.length < 3) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
                return;
            }
            this.loading = true;

            this.benefitClub.enabled = (this.optionActiveSituationValue === 'ENABLED');

            this.service.save(this.benefitClub).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
