import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { GIPIAbstractDTO } from '@gipisistemas/ng-core';
import { FinancialReceivement } from '../receivement.model';

export class FinancialReceivementRequestDTO extends GIPIAbstractDTO {

    /** @Required Lista de contas */
    billInstallmentList: FinancialBillInstallment[] = [];

    /** @Required Recebimento */
    receivement: FinancialReceivement;

    /** Lista de recebimentos de cartão */
    receivementCardList: FinancialReceivementCard[];

    /** Usar crédito do cliente */
    useClientCredit: boolean;

}
