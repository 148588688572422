import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class PaymentVoucherComponent extends AbstractComponent {
    constructor(_companyService, _customAuthenticationService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this._companyService = _companyService;
        this._customAuthenticationService = _customAuthenticationService;
        this.description = '';
        this.comments = '';
        this.documentNumber = '';
        this.isTwoWays = false;
        this.isCreated = new EventEmitter();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.findCompanyData();
        });
    }
    ngAfterViewInit() {
        setTimeout(() => this.isCreated.emit());
    }
    findCompanyData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const companyId = this._customAuthenticationService.token.user.currentCompany;
                yield this._companyService.findByIdIn([Number(companyId)]).toPromise().then(companyList => {
                    if (!ArrayUtil.isEmpty(companyList)) {
                        this.company = companyList[0];
                    }
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.handleError(e);
            }
        });
    }
    getCpfOrCnpjCompany() {
        if ((ObjectUtil.isNull(this.company))
            || (!ObjectUtil.isNull(this.company) && ObjectUtil.isNull(this.company.person))) {
            return '';
        }
        if (!ObjectUtil.isNull(this.company.person.legalPerson)) {
            return StringUtil.format(this.company.person.legalPerson.cnpj, '00.000.000/0000-00');
        }
        else if (!ObjectUtil.isNull(this.company.person.naturalPerson)) {
            return StringUtil.format(this.company.person.naturalPerson.cpf, '000.000.000-00');
        }
        return '';
    }
    getCpfOrCnpjProvider() {
        if ((ObjectUtil.isNull(this.provider))
            || (!ObjectUtil.isNull(this.provider) && ObjectUtil.isNull(this.provider.person))) {
            return '';
        }
        if (!ObjectUtil.isNull(this.provider.person.legalPerson)) {
            return StringUtil.format(this.provider.person.legalPerson.cnpj, '00.000.000/0000-00');
        }
        else if (!ObjectUtil.isNull(this.provider.person.naturalPerson)) {
            return StringUtil.format(this.provider.person.naturalPerson.cpf, '000.000.000-00');
        }
        return '';
    }
    getAmountPaidInFull() {
        const amountPaidFormatted = CurrencyUtil.transform(this.amountPaid, '1.2-2');
        const amountPaidNotCurrency = amountPaidFormatted.substring(2).trim();
        return `${amountPaidFormatted} (${amountPaidNotCurrency.numberFullName(true)})`;
    }
    getDescription() {
        if (!StringUtil.isEmpty(this.comments)) {
            return this.comments;
        }
        else if (!StringUtil.isEmpty(this.description)) {
            return this.description;
        }
        return '';
    }
    formatDateInFull() {
        const lDate = this.getDateWithoutTime(this.paymentDate);
        const day = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'][lDate.getDay()];
        const date = lDate.getDate();
        const month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][lDate.getMonth()];
        const year = lDate.getFullYear();
        return (`${day}, ${date} de ${month} de ${year}`);
    }
    getDateWithoutTime(pDate) {
        const day = pDate.toString().substring(8, 10);
        const month = pDate.toString().substring(5, 7);
        const year = pDate.toString().substring(0, 4);
        const lDate = `${year}/${month}/${day}`;
        return new Date(lDate);
    }
}
