import { formatNumber } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCardAdministrator } from '@gipi-financial/card-administrator/models/card-administrador.model';
import { FinancialCardAdministratorService } from '@gipi-financial/card-administrator/services/card-administrator.service';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialFlagCardService } from '@gipi-financial/flag-card/services/flag-card.service';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { FinancialReceivement } from '@gipi-financial/receivement/models/receivement.model';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, NumberUtil, ObjectUtil, PageDTO, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ReceivementCardData {
    receivement: FinancialReceivement;
    installmentList: FinancialBillInstallment[];
    isCashier: boolean;
    useClientCredit: boolean;
}

@Component({
    templateUrl: './receivement-card-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class ReceivementCardDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('actions', { static: true }) actions: TemplateRef<any>;

    private _receivement: FinancialReceivement = null;

    public receivementCardList: FinancialReceivementCard[] = [];

    public columns: TableColumnDTO[] = [];

    public receivementCard: FinancialReceivementCard = this._newReceivementCard();

    public quantityInstallment: number = 1;

    public optionAmountCard: any = { allowNegative: false, min: 0, max: this.amountRemaining };

    cardAdministratorFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialCardAdministrator> = await this._cardAdministratorService.findByValue(value, page, 10).toPromise();
        return result;
    };

    flagCardFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialFlagCard> = await this._flagCardService.findByValue(value, page, 10).toPromise();
        return result;
    };

    public get amountRemaining$(): Observable<number> {
        return of(this.amountRemaining);
    }

    public get isValidAddReceivementCard$(): Observable<boolean> {
        if (ObjectUtil.isNull(this.receivementCard)) {
            return of(false);
        }

        return of(
            !ObjectUtil.isNull(this.receivementCard.cardAdministrator) &&
            !ObjectUtil.isNull(this.receivementCard.flagCard) &&
            NumberUtil.isPositive(this.receivementCard.amountCard) &&
            (this.quantityInstallment >= 1)
        );
    }

    public get amountToReceive(): number {
        const receivementAux: FinancialReceivement = !ObjectUtil.isNull(this._receivement) ? this._receivement : this.data.receivement;
        return (receivementAux.amountReceived ? receivementAux.amountReceived : receivementAux.amountReceivable);
    }

    public get amountAdded(): number {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return 0;
        }
        const amountAdded: number = this.receivementCardList.reduce((sum, e) => sum += e.amountCard, 0);
        return amountAdded;
    }

    public get amountRemaining(): number {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            return this.amountToReceive;
        }
        return (this.amountToReceive - this.amountAdded);
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _cardAdministratorService: FinancialCardAdministratorService,
        private _flagCardService: FinancialFlagCardService,
        private _receivementService: FinancialReceivementService,
        public dialogRef: MatDialogRef<ReceivementCardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementCardData = null,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.columns = this._createTableColumns();

        if (!ObjectUtil.isNull(this.data)) {
            this._receivement = ObjectUtil.clone(this.data.receivement);
            this._receivement.amountNet = this._receivement.amountReceivable;
        }

        this._changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('numberInstallment')
                .name('N°')
                .value((obj: FinancialReceivementCard) => ('00' + obj.numberInstallment).slice(-2))
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('issuanceDate')
                .name('Emissão')
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.issuanceDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj: FinancialReceivementCard) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(8)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('cardAdministrator')
                .name('Administradora de cartão')
                .value((obj: FinancialReceivementCard) => obj.cardAdministrator.description)
                .marginLeft(15)
                .build(),
            TableColumnBuilder.instance()
                .property('flagCard')
                .name('Bandeira do cartão')
                .value((obj: FinancialReceivementCard) => obj.flagCard.description)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('amountCard')
                .name('Valor bruto')
                .value((obj: FinancialReceivementCard) => CurrencyUtil.transform(obj.amountCard, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('appliedFee')
                .name('Taxa (%)')
                .value((obj: FinancialReceivementCard) => `${formatNumber(obj.appliedFee, 'pt-BR', '1.2-2')} %`)
                .align('center center')
                .width(6)
                .build(),
            TableColumnBuilder.instance()
                .property('amountNet')
                .name('Valor líquido')
                .value((obj: FinancialReceivementCard) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(10)
                .build(),
        ];
    }

    private _newReceivementCard(): FinancialReceivementCard {
        const entity: FinancialReceivementCard = new FinancialReceivementCard();
        entity.chargeType = ObjectUtil.clone(this.data.receivement.chargeType);
        entity.bankAccount = ObjectUtil.clone(this.data.receivement.bankAccount);
        entity.cardAdministrator = null;
        entity.flagCard = null;
        entity.issuanceDate = new Date();
        entity.dueDate = new Date();
        entity.status = 'RECEIVABLE';
        entity.amountCard = this.amountRemaining;
        entity.amountNet = 0;
        entity.appliedFee = 0;
        return entity;
    }

    private _getOrderId(): number {
        const orderId: number[] = this.receivementCardList.map(e => e.orderId);
        if (orderId.length > 0) {
            return Math.max(...orderId) + 1;
        }

        return 1;
    }

    private _validateAddReceivementCard(): boolean {
        if (ObjectUtil.isNull(this.receivementCard.cardAdministrator)) {
            this.addWarningMessage('Campo administradora de cartão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.receivementCard.flagCard)) {
            this.addWarningMessage('Campo bandeira do cartão é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.quantityInstallment)) {
            this.addWarningMessage('Campo quantidade de parcelas é obrigatório e não foi informado');
            return false;
        }

        const amountAddedAux: number = this.amountAdded;
        const amountToReceiveAux: number = this.amountToReceive;
        if (amountAddedAux === amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${(amountAddedAux).toFixed(2)} já corresponde ao valor total do recebimento`);
            return false;
        }
        if ((this.receivementCard.amountCard + amountAddedAux) > amountToReceiveAux) {
            this.addWarningMessage(`O valor que você está tentando lançar R$ ${(amountAddedAux).toFixed(2)} ultprassa o valor total do recebimento`);
            return
        }
        if (!NumberUtil.isPositive(this.receivementCard.amountCard)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.receivementCard.issuanceDate)) {
            this.addWarningMessage('Campo data da emissão é obrigatório e não foi informado');
            return false;
        }
        if ((this.quantityInstallment > 1) && ((this.receivementCard.amountCard / this.quantityInstallment) < 1)) {
            this.addWarningMessage('Não é possível gerar parcelas com valor abaixo de R$ 1,00');
            return false;
        }

        return true;
    }

    public addReceivementCard(): void {
        try {
            if (!this._validateAddReceivementCard()) {
                return;
            }
            this.loading = true;

            const receivementCardListAux: FinancialReceivementCard[] = [];
            const now: Date = new Date();
            const orderId: number = this._getOrderId();
            const daysAdd: number = (
                (this.receivementCard.chargeType.type === 'DEBIT_CARD') ?
                    this.receivementCard.cardAdministrator.dayDebitReceipt :
                    this.receivementCard.cardAdministrator.dayCreditReceipt
            );
            // Taxa bruta
            const grossFee: number = (
                (this.receivementCard.chargeType.type === 'DEBIT_CARD') ?
                    this.receivementCard.cardAdministrator.debitFee :
                    ((this.quantityInstallment === 1) ? this.receivementCard.cardAdministrator.cashFee : this.receivementCard.cardAdministrator.installmentFee)
            );
            // Taxa rateada nas parcelas
            const proratedFee: number = (grossFee / this.quantityInstallment);

            const amountCard: number = this.receivementCard.amountCard;
            const amountCardInstallment: number = (this.receivementCard.amountCard / this.quantityInstallment);
            const amountNet: number = (this.receivementCard.amountCard - (this.receivementCard.amountCard * (grossFee / 100)));
            const amountNetInstallment: number = (amountNet / this.quantityInstallment);

            for (let i = 0; i < this.quantityInstallment; i++) {
                const receivementCard: FinancialReceivementCard = ObjectUtil.clone(this.receivementCard);
                receivementCard.amountCard = Number(amountCardInstallment.toFixed(2));
                receivementCard.amountNet = Number(amountNetInstallment.toFixed(2));
                receivementCard.appliedFee = Number(proratedFee.toFixed(2));
                receivementCard.dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                receivementCard.numberInstallment = i + 1;
                receivementCard.orderId = orderId

                receivementCardListAux.push(receivementCard);
            }

            // Ajusta os valores, caso ultrapasse ou falte em relação ao valor total
            this._adjustAmounts(receivementCardListAux, amountCard, amountNet, grossFee);

            this.receivementCardList = [...this.receivementCardList, ...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;

            this.loading = false;
            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    private _adjustAmounts(receivementCardList: FinancialReceivementCard[], amountCard: number, amountNet: number, grossFee: number): void {
        // Refatora o amountCard
        const amountCardAdded = receivementCardList.reduce((sum, e) => sum + e.amountCard, 0);
        const amountCardAddedDiff: number = Number(amountCardAdded.toFixed(2)) - amountCard;
        if (amountCardAddedDiff > 0) {
            receivementCardList[0].amountCard -= amountCardAddedDiff;
        } else if (amountCardAddedDiff < 0) {
            receivementCardList[0].amountCard += (amountCardAddedDiff * -1);
        }

        // Refatora o amountNet
        const amountNetAdded = receivementCardList.reduce((sum, e) => sum + e.amountNet, 0);
        const amountNetAddedDiff: number = Number(amountNetAdded.toFixed(2)) - amountNet;
        if (amountNetAddedDiff > 0) {
            receivementCardList[0].amountNet -= amountNetAddedDiff;
        } else if (amountNetAddedDiff < 0) {
            receivementCardList[0].amountNet += (amountNetAddedDiff * -1);
        }

        // Refatora a appliedFee
        const appliedFeeAdded = receivementCardList.reduce((sum, e) => sum + e.appliedFee, 0);
        const appliedFeeAddedDiff: number = Number(appliedFeeAdded.toFixed(2)) - grossFee;
        if (appliedFeeAddedDiff > 0) {
            receivementCardList[0].appliedFee -= appliedFeeAddedDiff;
        } else if (appliedFeeAddedDiff < 0) {
            receivementCardList[0].appliedFee += (appliedFeeAddedDiff * -1);
        }
    }

    public removeReceivementCard(entity: FinancialReceivementCard): void {
        try {
            this.loading = true;
            const receivementCardListAux: FinancialReceivementCard[] = ArrayUtil.clone(this.receivementCardList);
            const indexReceivementCard: number = receivementCardListAux.findIndex(e => (e.orderId === entity.orderId) && (e.numberInstallment === entity.numberInstallment));
            receivementCardListAux.splice(indexReceivementCard, 1);

            const receivementCardByOrderId: FinancialReceivementCard[] = receivementCardListAux.filter(e => e.orderId === entity.orderId);
            if (!ArrayUtil.isEmpty(receivementCardByOrderId)) {
                const now: Date = new Date();

                const daysAdd: number = (
                    (entity.chargeType.type === 'CREDIT_CARD') ?
                        entity.cardAdministrator.dayCreditReceipt :
                        entity.cardAdministrator.dayDebitReceipt
                );

                // Taxa bruta
                const grossFee: number = (
                    (entity.chargeType.type === 'DEBIT_CARD') ?
                        entity.cardAdministrator.debitFee :
                        ((receivementCardByOrderId.length === 1) ? entity.cardAdministrator.cashFee : entity.cardAdministrator.installmentFee)
                );
                // Taxa rateada nas parcelas
                const proratedFee: number = (grossFee / receivementCardByOrderId.length);

                const amountCard: number = receivementCardByOrderId.reduce((sum, e) => sum += e.amountCard, 0);
                const amountCardInstallment: number = (amountCard / receivementCardByOrderId.length);
                const amountNet: number = (amountCard - (amountCard * (grossFee / 100)));
                const amountNetInstallment: number = (amountNet / receivementCardByOrderId.length);

                for (let i = 0; i < receivementCardByOrderId.length; i++) {
                    receivementCardByOrderId[i].amountCard = Number(amountCardInstallment.toFixed(2));
                    receivementCardByOrderId[i].amountNet = Number(amountNetInstallment.toFixed(2));
                    receivementCardByOrderId[i].appliedFee = Number(proratedFee.toFixed(2));
                    receivementCardByOrderId[i].dueDate = new Date(now.getFullYear(), now.getMonth() + i, now.getDate() + daysAdd);
                    receivementCardByOrderId[i].numberInstallment = i + 1;
                }

                // Ajusta os valores, caso ultrapasse ou falte em relação ao valor total
                this._adjustAmounts(receivementCardByOrderId, amountCard, amountNet, grossFee);
            }

            this.receivementCardList = [...receivementCardListAux];
            this.receivementCard = this._newReceivementCard();
            this.quantityInstallment = 1;

            this.loading = false;
            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    private _validateConfirm(): boolean {
        if (ArrayUtil.isEmpty(this.receivementCardList)) {
            this.addWarningMessage('É necessário adicionar no mínimo uma parcela, para salvar o recebimento');
            return false;
        }

        const amountAddedAux: number = Number(this.amountAdded.toFixed(2));
        const amountToReceiveAux: number = this.amountToReceive;
        if (amountAddedAux < amountToReceiveAux) {
            this.addWarningMessage(`Ainda falta lançar R$ ${CurrencyUtil.transform(this.amountRemaining, '1.2-2')} para corresponder o valor total do recebimento`);
            return false;
        }
        if (amountAddedAux > amountToReceiveAux) {
            this.addWarningMessage(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAddedAux).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
            return false;
        }
        return true;
    }

    public confirm(): void {
        try {
            if (!this._validateConfirm()) {
                return;
            }
            this.loading = true;

            this._receivementService.receive(this._receivement, this.data.installmentList, this.receivementCardList, this.data.useClientCredit).toPromise().then(_ => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close(true);
            }).catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public close(isSaved: boolean): void {
        this.dialogRef.close(isSaved);
    }

}
