import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialFlagCard } from '@gipi-financial/flag-card/models/flag-card.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialCardAdministrator } from '../../card-administrator/models/card-administrador.model';
import { FinancialReceivementCardOrigin } from '../enums/receivement-card-origin.enum';
import { FinancialReceivementCardType } from '../enums/receivement-card-type.enum';
export class FinancialReceivementCard extends GIPIBaseAbstractModel {

    amountCard: number;

    amountNet: number;

    bankAccount: FinancialBankAccount;

    cardAdministrator: FinancialCardAdministrator;

    chargeType: FinancialChargeType;

    conciled: boolean;

    documentOrigin: string;

    dueDate: Date;

    flagCard: FinancialFlagCard;

    issuanceDate: Date;

    migrated: boolean;

    numberInstallment: number;

    origin: FinancialReceivementCardOrigin;

    postingCategory: FinancialPostingCategory;

    saleReceivementCardId: GIPIUuid;

    status: FinancialReceivementCardType;

    /** @Transient */
    appliedFee: number;

    /** @Transient */
    idLauchReference: number;

    /** @Transient */
    orderId: number;

}
