import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialCreditClient } from '@gipi-financial/credit-client/models/credit-client.model';
import { FinancialEmployee } from '@gipi-financial/employe/models/employee.model';
import { FinancialPaymentMethod } from '@gipi-financial/payment-method/models/payment-method.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialSaleStatus } from '../enums/sale-status.enum';
import { FinancialSaleTaxInvoiceStatus } from '../enums/sale-tax-invoice-status.enum';
import { FinancialSaleType } from '../enums/sale-type.enum';
import { FinancialSaleClient } from './sale-client.model';
import { FinancialSaleItem } from './sale-item.model';
import { FinancialSalePayment } from './sale-payment.model';

export class FinancialSale extends GIPIBaseAbstractModel {

    addition: number;

    amountNet: number;

    billList: FinancialBill[];

    cashierShift: FinancialCashierShift;

    client: FinancialClient;

    comments: string;

    creditClientList: FinancialCreditClient[];

    date: Date;

    discount: number;

    documentNumber: string;

    freight: number;

    grossAmount: number;

    paymentMethod: FinancialPaymentMethod;

    postingCategory: FinancialPostingCategory;

    saleClient: FinancialSaleClient;

    saleItemList: FinancialSaleItem[];

    salePaymentList: FinancialSalePayment[];

    seller: FinancialEmployee;

    status: FinancialSaleStatus;

    taxCouponNumber: number;

    taxCouponSeries: number;

    taxInvoiceStatus: FinancialSaleTaxInvoiceStatus;

    type: FinancialSaleType;

    userId: number;

    saleSaleId: GIPIUuid;

    /** @Transient */
    billInstallmentList: FinancialBillInstallment[];

    /** @Transient */
    selected: boolean = false;

    /** @Transient */
    children: FinancialSaleItem[];

    constructor(id?: GIPIUuid) {
        super();
        if (UUIDUtil.isValid(id)) {
            this.id = id;
        }
    }

}
