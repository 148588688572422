import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIUuid, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';
import { FinancialReceivementRequestDTO } from '../models/dto/receivement-request.dto';
import { FinancialReceivementDTO } from '../models/dto/receivement.dto';
import { FinancialReceivement } from '../models/receivement.model';

@Injectable({ providedIn: 'root' })
export class FinancialReceivementService extends GIPIAbstractCrudService<FinancialReceivement, BaseFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.receivement, 'v1', baseService);
    }

    findByBillInstallment(billInstallmentId: GIPIUuid): Observable<FinancialReceivement[]> {
        return this.baseService.httpClient.get(this.url(`find-by-bill-installment/${billInstallmentId}?page=${0}&size=300`), this.options()).pipe(
            map((json) => {
                const page: PageDTO<FinancialReceivement> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }
                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    receive(receivement: FinancialReceivement, billInstallmentList: FinancialBillInstallment[], receivementCardList: FinancialReceivementCard[], useClientCredit: boolean = false): Observable<FinancialReceivementDTO[]> {
        if (!ArrayUtil.isEmpty(receivementCardList)) {
            receivement.receivementCardList = receivementCardList;
        }
        const receivementDTO: FinancialReceivementDTO = FinancialReceivementDTO.transformInReceivementDTO(receivement, billInstallmentList, useClientCredit);

        return this.baseService.httpClient.post(this.url('receive', 'v2'), receivementDTO, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    receiveAll(receivementRequestDTO: FinancialReceivementRequestDTO[]): Observable<FinancialReceivementDTO[]> {
        const receivementListDTO: FinancialReceivementDTO[] = [];
        for (let i = 0; i < receivementRequestDTO.length; i++) {
            const receivementDTO: FinancialReceivementDTO = FinancialReceivementDTO.transformInReceivementDTO(receivementRequestDTO[i].receivement, receivementRequestDTO[i].billInstallmentList, receivementRequestDTO[i].useClientCredit);
            receivementListDTO.push(receivementDTO);
        }

        return this.baseService.httpClient.post(this.url('receive-all', 'v2'), receivementListDTO, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    partialCancellation(receivementId: GIPIUuid): Observable<void> {
        return this.baseService.httpClient.delete(this.url(`partial-cancellation/${receivementId}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findLinkedReceivements(receivementId: GIPIUuid): Observable<FinancialReceivement[]> {
        return this.baseService.httpClient.get(this.url(`find-linked-receivements/${receivementId}?page=0&size=300`), this.options()).pipe(
            map(json => {
                const page: PageDTO<FinancialReceivement> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }
                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
