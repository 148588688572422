import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, EmailUtil, StringUtil } from '@gipisistemas/ng-core';
export class ResendRecoveryComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute, _userPublicService) {
        super(messageService, router, activatedRoute);
        this.messageService = messageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._userPublicService = _userPublicService;
        localStorage.removeItem('token');
    }
    ngOnInit() {
        super.ngOnInit();
        this.username = this.activatedRoute.snapshot.params.username;
    }
    resend() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validate();
                this.loading = true;
                yield this._userPublicService.recovery(this.username).toPromise().then(() => {
                    this.addSuccessMessage('Operação realizada com sucesso');
                    const currentUrl = 'oauth/resend-recovery';
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl, this.username]));
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    validate() {
        if (StringUtil.isEmpty(this.username) || !EmailUtil.isValid(this.username.toLowerCase())) {
            throw new Error('E-mail inválido');
        }
    }
    returnToLogin() {
        this.router.navigate(['/oauth/login']);
    }
}
