import { GIPIUuid } from '@gipisistemas/ng-core';

export class NotificationNotificationClosingMonthCompanyBillStatementDTO {

    billStatementId: GIPIUuid;

    cpfOrCnpj: string;

    constructor(
        billStatementId?: GIPIUuid,
        cpfOrCnpj?: string,
    ) {
        this.billStatementId = billStatementId;
        this.cpfOrCnpj = cpfOrCnpj;
    }

}
