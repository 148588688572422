import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { RegistrationConnectConsultService } from '@gipi-registration/connect-consult/services/connect-consult.service';
import { GIPIAbstractComponent, GIPIBaseService, ObjectUtil } from '@gipisistemas/ng-core';
export class ConnectConsultDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, dialogRef, data = {
        consultBy: 'CPF_OR_CNPJ',
        clientId: null,
        cpfOrCnpj: '',
        consultType: 'PEFIN_TOP',
        documentType: 'LEGAL_PERSON',
        showDunningNotice: true,
    }) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this.dialogRef = dialogRef;
        this.data = data;
        this.connectSummary = null;
        this.showDunningNotice = false;
        this.consultCostApplied = false;
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        super.ngOnInit();
        if (!ObjectUtil.isNull(this.data)) {
            this.showDunningNotice = this.data.showDunningNotice;
            //     this._findConnectSummary();
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    findConnectSummary() {
        try {
            if (ObjectUtil.isNull(this.data)) {
                return;
            }
            this.loading = true;
            if (this.data.consultBy === 'ID') {
                this.service.summaryByClientId('PEFIN_TOP', this.data.documentType, this.data.clientId).toPromise().then(summary => {
                    this.connectSummary = ObjectUtil.clone(summary);
                    this.showDunningNotice = false;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                });
            }
            else {
                this.service.summaryByCpfOrCnpj('PEFIN_TOP', this.data.documentType, this.data.cpfOrCnpj).toPromise().then(summary => {
                    this.connectSummary = ObjectUtil.clone(summary);
                    this.showDunningNotice = false;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                });
            }
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    downloadConnectFull() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (ObjectUtil.isNull(this.connectSummary)) {
                    return;
                }
                this.loading = true;
                const file = yield this.service.recoverConsultPdfById(this.connectSummary.id).toPromise()
                    .then(resp => new Blob([resp.body], { type: 'application/pdf' }))
                    .catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                    return null;
                });
                this.loading = false;
                if (!ObjectUtil.isNull(file)) {
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            }
            catch (e) {
                this.loading = false;
                this.addErrorMessage(e);
            }
        });
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
