import { GIPIAbstractFilterModel } from '@gipisistemas/ng-core';

export class GIPIBaseAbstractFilterModel extends GIPIAbstractFilterModel {

    researchField: string;

    /** @Transient */
    itemsSelected: any[];

    constructor() {
        super();
        this.researchField = '';
        this.itemsSelected = [];
    }

}
