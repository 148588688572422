import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialSaleFilterDTO } from '@gipi-financial/sale/models/dto/sale-filter.dto';
import { FinancialSaleService } from '@gipi-financial/sale/services/sale.service';
import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { RegistrationClientCardDTO } from '@gipi-registration/client/models/dto/client-card.dto';
import { SaleDataDialogComponent } from '@gipi-sale/sale/components/sale-data-dialog/sale-data-dialog.component';
import { WhatsappContactDialogComponent } from '@gipi-shared/components/whatsapp-contact-dialog/whatsapp-contact-dialog.component';
import { WhatsappContact } from '@gipi-shared/models/whatsapp-contact.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, DateUtil, DialogService, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ClientDialogComponent extends AbstractComponent {
    constructor(data, _clientService, _saleService, _dialogService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.data = data;
        this._clientService = _clientService;
        this._saleService = _saleService;
        this._dialogService = _dialogService;
        this.columns = [];
    }
    ngOnInit() {
        super.ngOnInit();
        this.createTableColumns();
        this.page = new PageDTO();
        this.client = this.data;
    }
    createTableColumns() {
        this.columns = [
            TableColumnBuilder.instance()
                .property('date')
                .name('Data')
                .value((obj) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Documento')
                .value((obj) => obj.documentNumber)
                .build(),
            TableColumnBuilder.instance()
                .property('salePayment')
                .name('Total Líquido')
                .value((obj) => CurrencyUtil.transform(this._calculateSalePayment(obj), '1.2-2'))
                .build(),
            TableColumnBuilder.instance()
                .property('chargeType')
                .name('Forma de pagamento')
                .value((obj) => obj.paymentMethod.chargeType.description)
                .build(),
            TableColumnBuilder.instance()
                .property('seller')
                .name('Vendedor')
                .value((obj) => obj.seller ? obj.seller.person.name : '')
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(10)
                .align('center center')
                .template(this.actions)
                .build(),
        ];
    }
    _calculateSalePayment(entity) {
        const paymentList = [...entity.salePaymentList];
        if (ArrayUtil.isEmpty(paymentList)) {
            return 0;
        }
        const creditClientAmount = entity.creditClientList.filter(e => e.type === 'DEBIT').reduce((sum, e) => sum += e.amount, 0);
        const paymentAmount = paymentList.filter(e => e.chargeType.type !== 'CREDIT_CLIENT').reduce((sum, payment) => {
            if ((payment.chargeType.type === 'DISCOUNT') ||
                (payment.chargeType.type === 'CASH_CHANGE')) {
                return sum -= payment.amountPaid;
            }
            else {
                return sum += payment.amountPaid;
            }
        }, 0);
        return Number((creditClientAmount + paymentAmount).toFixed());
    }
    _newFilterDTO() {
        this._filter = new FinancialSaleFilterDTO();
        this._filter.client = new RegistrationClient();
        this._filter.client.id = this.client.id;
    }
    find(pageEvent) {
        this.loading = true;
        this.loadingChange.emit(this.loading);
        this.page = new PageDTO();
        if (ObjectUtil.isNull(this._filter)) {
            this._newFilterDTO();
        }
        this._filter.pageSize = 10;
        if (pageEvent) {
            this._filter.pageNumber = pageEvent.pageIndex;
            this._filter.offset = pageEvent.pageIndex * pageEvent.pageSize;
        }
        else {
            this._filter.pageNumber = 0;
            this._filter.offset = 0;
        }
        this._saleService.findAll(this._filter).toPromise().then(page => {
            this.page = page;
            this.loading = false;
            this.loadingChange.emit(this.loading);
        }, error => this.handleError(error));
    }
    getColorByAmount(amount) {
        if (amount > 0) {
            return '#47ca82';
        }
        else if (amount < 0) {
            return '#ca0045';
        }
        else if (amount === 0) {
            return '#696969';
        }
    }
    navigateSaleData(entity) {
        this._dialogService.open({
            componentOrTemplateRef: SaleDataDialogComponent,
            data: entity.saleSaleId,
            width: '90%',
            height: '90%',
        });
    }
    whatsappContact() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!ObjectUtil.isNull(this.client)) {
                    const getRecipientPhone = (phoneList) => {
                        if (ArrayUtil.isEmpty(phoneList)) {
                            return '';
                        }
                        const phone = phoneList.find(e => e.type.type === 'WHATSAPP');
                        return !ObjectUtil.isNull(phone) ? phone.number : '';
                    };
                    const recipient = yield this._clientService.getOne(this.client.id).toPromise();
                    const recipientPhoneAux = getRecipientPhone(recipient.person.phoneList);
                    const keyLocalStorage = '@GIPIClientCardMessage';
                    const savedMessage = WhatsappContactDialogComponent.getMessage(keyLocalStorage);
                    const whatsappContact = new WhatsappContact();
                    whatsappContact.recipientName = this.client.name;
                    whatsappContact.recipientPhone = recipientPhoneAux;
                    whatsappContact.message = !StringUtil.isEmpty(savedMessage) ? savedMessage : '';
                    this._dialogService.open({
                        componentOrTemplateRef: WhatsappContactDialogComponent,
                        data: whatsappContact,
                        width: '55rem',
                        maxHeight: '55rem',
                    }).afterClosed().toPromise().then((message) => {
                        if (!StringUtil.isEmpty(message)) {
                            WhatsappContactDialogComponent.saveMessage(keyLocalStorage, message);
                        }
                    });
                }
            }
            catch (e) {
                this.handleError(e);
            }
        });
    }
}
