<gipi-abstract-form id="dialogReceivement"
                    name="dialogReceivement"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogReceivement">
        Efetuar recebimento
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-currency label="Valor bruto"
                                 [(ngModel)]="amountTotalInstallment"
                                 disabled="true"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Juros"
                                 [(ngModel)]="receivement.interest"
                                 required="true"
                                 [disabled]="isLoad || receivement.discount > 0 || clientCreditBalance >= receivement.amountReceivable || generatedInterest"
                                 (keyup)="setAmountNet()"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Multa"
                                 [(ngModel)]="receivement.fine"
                                 required="true"
                                 [disabled]="isLoad || receivement.discount > 0 || clientCreditBalance >= receivement.amountReceivable || generatedFine"
                                 (keyup)="setAmountNet()"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Acréscimo"
                                 [(ngModel)]="receivement.addition"
                                 required="true"
                                 [disabled]="isLoad || receivement.discount > 0"
                                 (keyup)="setAmountNet()"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Desconto"
                                 [(ngModel)]="receivement.discount"
                                 required="true"
                                 [disabled]="isLoad || receivement.interest > 0 || receivement.fine > 0 || receivement.addition > 0"
                                 [options]="{max: amountTotalInstallment}"
                                 (keyup)="setAmountNet()"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Valor líquido"
                                 [(ngModel)]="amountNet"
                                 disabled="true"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-currency #firstInput
                                 label="Valor a receber"
                                 [(ngModel)]="receivement.amountReceivable"
                                 (keyup)="setAmountReceived(); useClientCredit ? setClientCredit() : null"
                                 [options]="{min: 0}"
                                 required="true"
                                 [disabled]="isLoad"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Valor troco"
                                 *ngIf="receivement.amountReceivable > amountNet"
                                 [value]="receivement.customerCreditAmount"
                                 [disabled]="true"
                                 class="credit"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Crédito do cliente"
                                 *ngIf="useClientCredit && (receivement.amountReceivable <= amountNet)"
                                 [(ngModel)]="clientCredit"
                                 [required]="useClientCredit"
                                 [disabled]="isLoad"
                                 (keyup)="setAmountReceived()"
                                 [options]="{ min: 0, max: getMaxClientCredit() }"
                                 class="credit"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Saldo do cliente"
                                 *ngIf="useClientCredit && (receivement.amountReceivable <= amountNet)"
                                 class="credit"
                                 [value]="clientCreditBalance - clientCredit"
                                 disabled="true"
                                 fxFlex="calc(15% - 16px)"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <div fxFlex="calc(15% - 16px)"
                 fxFlex.lt-md="100"
                 fxLayoutAlign="start center"
                 [fxHide]="receivement.amountReceivable > amountNet">
                <itss-checkbox label="Abater crédito do cliente"
                               *ngIf="(clientCreditBalance > 0) && (receivement.amountReceivable <= amountNet)"
                               color="primary"
                               [disabled]="isLoad"
                               [(ngModel)]="useClientCredit"
                               (ngModelChange)="setClientCredit()">
                </itss-checkbox>
            </div>
            <div fxFlex="calc(15% - 16px)"
                 fxFlex.lt-md="100"
                 fxLayoutAlign="start center"
                 [fxHide]="useClientCredit">
                <itss-checkbox label="Lançar troco como crédito"
                               *ngIf="receivement.amountReceivable > amountNet"
                               color="primary"
                               [disabled]="isLoad"
                               [(ngModel)]="postClientCredit">
                </itss-checkbox>
            </div>
            <div fxFlex="calc(15% - 16px)"
                 [fxHide]="useClientCredit">
            </div>
            <div fxFlex="calc(15% - 16px)"></div>
            <div fxFlex="calc(15% - 16px)"></div>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-currency label="Total recebido"
                                 [(ngModel)]="receivement.amountReceived"
                                 [value]="receivement.amountReceived - clientCredit"
                                 disabled="true"
                                 fxFlex="15.27%"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <gipi-datepicker label="Data recebimento"
                             [(ngModel)]="receivement.receivementDate"
                             [disabled]="isLoad || !this.hasPermission('DATA_RECEIVE')"
                             (focusout)="validateReceivementDate()"
                             required="true"
                             [max]="currentDate"
                             fxFlex="15.27%"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-select-entity label="Tipo cobrança"
                                [(model)]="receivement.chargeType"
                                [entities]="chargeTypeList"
                                (selectionChange)="validateBankAccountByCashierShift($event)"
                                *ngIf="!useClientCredit || !(amountNet === clientCredit)"
                                required="true"
                                property="description"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
            <itss-select-entity label="Conta bancária"
                                [(model)]="receivement.bankAccount"
                                [entities]="bankAccountList"
                                [disabled]="isLoad"
                                required="true"
                                property="description"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="isLoad || loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('REMARK_SELECTED')"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>