import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';

import { FinancialBillStatusEnum } from '@gipi-financial/bill/enums/bill-status.enum';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBillInstallmentService } from '@gipi-financial/bill/services/bill-installment.service';
import { FinancialPerson } from '@gipi-financial/person/models/person.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, ConfirmationService, CurrencyUtil, DateUtil, INJECTOR, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

@Component({
    selector: 'app-delete-installment-dialog',
    templateUrl: './delete-installment-dialog.component.html',
    styleUrls: ['./delete-installment-dialog.component.scss']
})
export class DeleteInstallmentDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;

    @ViewChild('checkAllTemplate', { static: true }) checkAllTemplate: TemplateRef<any>;

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    selected: boolean = false;

    indeterminate: boolean = false;

    billInstallmentData: FinancialBillInstallment = new FinancialBillInstallment();

    billInstallmentList: FinancialBillInstallment[] = [];

    billInstallmentSelectList: FinancialBillInstallment[] = [];

    deleteIntallmentSelected: string = '0';

    private date: Date = new Date();
    compareDate: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0);

    options: boolean = false;

    isLoad: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { billInstallment: FinancialBillInstallment },
        public dialogRef: MatDialogRef<DeleteInstallmentDialogComponent>,
        private _billIntallmentService: FinancialBillInstallmentService,
        private _confirmationService: ConfirmationService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isLoad = true;

        this.billInstallmentData = this.data.billInstallment;

        this.createTableColumnsBillInstallments();

        setTimeout(async () => {
            const lBillInstallmentList: FinancialBillInstallment[] = await this._billIntallmentService.findByBillId(this.billInstallmentData.bill.id).toPromise();

            if (!ArrayUtil.isEmpty(lBillInstallmentList)) {
                this.billInstallmentList = lBillInstallmentList.filter(i => i.status === 'RECEIVABLE' || i.status === 'RECEIVABLE_OVERDUE' || i.status === 'PAYABLE' || i.status === 'PAYABLE_OVERDUE');
                this.billInstallmentList.forEach(installment => {
                    const duoDate: Date = new Date(moment(installment.dueDate).format('yyyy/MM/DD'));
                    if (DateUtil.isLessThan(duoDate, this.compareDate) && installment.status === 'RECEIVABLE') {
                        installment.status = 'RECEIVABLE_OVERDUE';
                    } else if (DateUtil.isLessThan(duoDate, this.compareDate) && installment.status === 'PAYABLE') {
                        installment.status = 'PAYABLE_OVERDUE';
                    }
                });
                this.sortBillInstallment();
                this.validateOptionRadioButton();
            }
        });

        this.isLoad = false;
    }

    private sortBillInstallment(): void {
        this.billInstallmentList.sort((a, b) => {
            const lFieldA: string | number = a.documentNumber;
            const lFieldB: string | number = b.documentNumber;
            return this.compareSort(lFieldA, lFieldB, true);
        });
    }

    private compareSort(a: number | string, b: number | string, isAsk: boolean): number {
        return (a < b ? -1 : 1) * (isAsk ? 1 : -1);
    }

    getNamePerson(): string {
        let objectPerson: FinancialPerson;
        if (this.billInstallmentData.bill.type === 'RECEIVABLE') {
            objectPerson = this.billInstallmentData.bill.client.person;
        } else {
            objectPerson = this.billInstallmentData.bill.provider.person;
        }
        return (objectPerson.name.length > 50) ? objectPerson.name.slice(0, 50) + '...' : objectPerson.name;
    }

    createTableColumnsBillInstallments(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj: FinancialBillInstallment) => DateUtil.format(obj.dueDate, DateUtil.DATE_FORMAT))
                .width(30)
                .align('center center')
                .sortable(true)
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Nº Documento')
                .value((obj: FinancialBillInstallment) => obj.documentNumber)
                .width(30)
                .align('center center')
                .sortable(true)
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj: FinancialBillInstallment) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .width(30)
                .align('center center')
                .sortable(true)
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(20)
                .template(this.statusTemplate)
                .build(),
        ];
    }

    checkAll(): void {
        this.billInstallmentSelectList = [];
        this.billInstallmentList.forEach((i) => {
            i.selected = this.selected;
            this.select(i, false, true, false);
        });

        const installment: FinancialBillInstallment[] = this.billInstallmentSelectList.filter(i => i.id === this.billInstallmentData.id);
        if (installment.length <= 0) {
            setTimeout(() => {
                this.billInstallmentList.filter(i => i.id === this.billInstallmentData.id).map(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
            });
            this.handleError('A parcela principal não pode ser desmarcada');
        }

        this.validateAllSelected();
    }

    select(billInstallment: FinancialBillInstallment, validateAllSelected: boolean, validateRadioButton: boolean, validateInstallmentData: boolean): void {
        if (billInstallment.selected) {
            this.billInstallmentSelectList.push(billInstallment);
        } else {
            this.billInstallmentSelectList.splice(this.billInstallmentSelectList.findIndex((i) => i.id === billInstallment.id), 1);
        }
        if (validateAllSelected) {
            this.validateAllSelected();
        }
        if (validateRadioButton && (billInstallment.id !== this.billInstallmentData.id)) {
            this.deleteIntallmentSelected = null;
        }
        if (validateInstallmentData && (billInstallment.id === this.billInstallmentData.id) && !billInstallment.selected) {
            setTimeout(() => {
                billInstallment.selected = true;
                this.select(billInstallment, validateAllSelected, validateRadioButton, validateInstallmentData);
            });
            this.handleError('A parcela principal não pode ser desmarcada');
        }
    }

    private validateAllSelected(): void {
        const list: FinancialBillInstallment[] = this.billInstallmentList.filter(i => i.selected);
        this.selected = list.length > 0;
        this.indeterminate = list.length > 0 && list.length !== this.billInstallmentList.length;
    }

    setColorStatus(entity: FinancialBillInstallment) {
        switch (String(entity.status)) {
            case 'RECEIVABLE':
                return '#b4b4b4';
            case 'RECEIVED':
                return '#56b910';
            case 'RECEIVED_PARTIAL':
                return '#62757f';
            case 'GROUPED':
                return '#8256D0';
            case 'RENEGOTIATED':
                return '#2bd7d9';
            case 'RECEIVABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE_OVERDUE':
                return '#f5db00';
            case 'PAYABLE':
                return '#b4b4b4';
            case 'PAID':
                return '#56b910';
            default:
                return '#b4b4b4';
        }
    }

    setHintStatus(entity: FinancialBillInstallment): string {
        switch (String(entity.status)) {
            case 'RECEIVABLE':
                return 'A receber';
            case 'RECEIVED':
                return 'Recebida';
            case 'RECEIVED_PARTIAL':
                return 'Recebida parcialmente';
            case 'GROUPED':
                return 'Agrupada';
            case 'RENEGOTIATED':
                return 'Renegociada';
            case 'RECEIVABLE_OVERDUE':
                return 'A receber atrasada';
            case 'PAYABLE_OVERDUE':
                return 'A pagar atrasada';
            case 'PAYABLE':
                return 'A pagar';
            case 'PAID':
                return 'Paga';
            default:
                return 'Não identificado';
        }
    }

    validateOptionRadioButton(): void {
        this.billInstallmentList.forEach(i => {
            i.selected = false;
            this.select(i, true, false, false);
        });

        switch (this.deleteIntallmentSelected) {
            case '0': { // Somente esta
                this.billInstallmentList.filter(i => i.id === this.billInstallmentData.id).map(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
            case '1': { // Esta e as anteriores
                const listBillInstallments: FinancialBillInstallment[] = this.billInstallmentList.filter(i => {
                    const dateInitial: Date = new Date(moment(i.dueDate).format('yyyy/MM/DD'));
                    const dateFinal: Date = new Date(moment(this.billInstallmentData.dueDate).format('yyyy/MM/DD'));
                    return DateUtil.isLessThanOrEqual(dateInitial, dateFinal);
                });

                listBillInstallments.forEach(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
            case '2': { // Esta e as futuras
                const listBillInstallments: FinancialBillInstallment[] = this.billInstallmentList.filter(i => {
                    const dateInitial: Date = new Date(moment(i.dueDate).format('yyyy/MM/DD'));
                    const dateFinal: Date = new Date(moment(this.billInstallmentData.dueDate).format('yyyy/MM/DD'));
                    return DateUtil.isGreaterThanOrEqual(dateInitial, dateFinal);
                });

                listBillInstallments.forEach(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
            case '3': { // Todas as contas não pagas
                this.billInstallmentList.forEach(i => {
                    i.selected = true;
                    this.select(i, true, false, false);
                });
                break;
            }
        }
    }

    private validateDeletion(): void {
        if (this.billInstallmentSelectList.length <= 0) {
            throw new Error('Deve ter ao menos uma parcela selecionada');
        }
    }

    async confirm(): Promise<void> {
        try {
            this.validateDeletion();

            if (this.billInstallmentData.billRenegotiated) {
                this.deleteBillRenegotiated();
            } else if (this.billInstallmentData.billGrouped) {
                this.deleteBillGrouped();
            } else {
                this.deleteInstallment();
            }

        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    async deleteInstallment(): Promise<void> {
        const descriptionList: string[] = this.billInstallmentSelectList.map(i => i.documentNumber);
        const textMessage: string = (this.billInstallmentSelectList.length === 1)
            ? `Confirma a exclusão da parcela: ${descriptionList}?`
            : `Confirma a exclusão das parcelas: ${descriptionList.join(', ')}?`;

        this._confirmationService.confirm({
            title: 'Confirmação',
            message: textMessage,
            accept: async () => {
                this.isLoad = true;
                this.loading = true;
                await this._billIntallmentService.deleteAll(this.billInstallmentSelectList.map(i => i.id)).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close('RELOAD_TABLE');
                }, error => {
                    this.isLoad = false;
                    this.loading = false;
                    this.handleError(error);
                });
            },
            cancel: () => {
                this.isLoad = false;
                this.loading = false;
            }
        });
    }

    private async deleteBillRenegotiated(): Promise<void> {
        try {
            await this._billIntallmentService.findByBillId(this.billInstallmentData.bill.id).toPromise().then((installmentList) => {
                const listMessages: string[] = [];

                const list: FinancialBillInstallment[] = installmentList.filter(b => b.status === 'RENEGOTIATED' || b.status === 'RECEIVED' || b.status === 'RECEIVED_PARTIAL' || b.status === 'GROUPED');
                if (list.length > 0) {
                    listMessages.push(...list.map(r => `Conta a receber n.° ${r.documentNumber} do cliente ${r.bill.client.person.name} com status ${FinancialBillStatusEnum[r.status]}.`));
                    this._confirmationService.confirm({
                        title: 'Aviso',
                        message: 'Não é possível realizar a exclusão. Para prosseguir cancele a movimentação dos seguintes documentos:',
                        width: '30%',
                        type: 'warning',
                        listMessage: listMessages,
                        accept: () => { }
                    });
                    this.loading = false;
                    return;
                }

                listMessages.push(...installmentList.map(r => `Conta a receber n.° ${r.documentNumber} do cliente ${r.bill.client.person.name}`));
                const textMessage: string = (installmentList.length > 1
                    ? `A parcela foi gerada através de uma renegociação. Portanto, sua exclusão irá excluir todas as parcelas. Documentos que serão excluídos:`
                    : `A parcela foi gerada através de uma renegociação, deseja continuar?`);

                this._confirmationService.confirm({
                    title: 'Confirmação',
                    width: '30%',
                    message: textMessage,
                    listMessage: ((installmentList.length > 1) ? listMessages : []),
                    accept: async () => {
                        await this._billIntallmentService.delete(this.billInstallmentData.id).toPromise().then(() => {
                            this.loading = true;
                            this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                            this.close('RELOAD_TABLE');
                        }, error => {
                            throw new Error(error);
                        });
                    },
                    cancel: () => { this.loading = false; }
                });
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private async deleteBillGrouped(): Promise<void> {
        try {
            const oldInstallmetsList: FinancialBillInstallment[] = await this._billIntallmentService.getBillInstallmentGroupedList(this.billInstallmentData.id).toPromise().catch(error => { throw new Error(error); });
            const listMessages: string[] = [];
            listMessages.push(...oldInstallmetsList.map(r => `Conta a receber nº ${r.documentNumber} do cliente ${r.bill.client.person.name}`));

            this._confirmationService.confirm({
                title: 'Confirmação',
                width: '30%',
                message: 'A parcela foi gerada através de um agrupamento. Portanto, sua exclusão irá estornar os seguintes documentos:',
                listMessage: listMessages,
                accept: async () => {
                    this.loading = true;
                    oldInstallmetsList.forEach((bi) => {
                        bi.status = 'RECEIVABLE';
                        bi.groupedBillInstallmentId = null;
                    });
                    await this._billIntallmentService.delete(this.billInstallmentData.id).toPromise().then(async () => {
                        await this._billIntallmentService.updateBillInstallmentList(oldInstallmetsList).toPromise().then(() => {
                            this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                            this.close('RELOAD_TABLE');
                        }, error => {
                            throw new Error(error);
                        });
                    }, error => {
                        throw new Error(error);
                    });
                },
                cancel: () => { this.loading = false; }
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
