import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialBankService } from '@gipi-financial/bank/services/bank.service';
import { FinancialCheckReceivedConciliationDTO } from '@gipi-financial/bill-conciliation/models/dto/check-received-concilition.dto';
import { FinancialTransactionConciliationService } from '@gipi-financial/bill-conciliation/services/transaction-concilitation.service';
import { FinancialClientSelectDTO } from '@gipi-financial/client/models/dto/client-select.dto';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { ArrayUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, NumberUtil, ObjectUtil, PhoneUtil, StringUtil } from '@gipisistemas/ng-core';
export class AddCheckReceivedDialogComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _clientService, _bankService, _postingCategoryService, _transactionConciliationService, _bankAccountService, data = {
        checkReceivedType: 'NEW',
        checkReceivedUpdate: null,
        transactionConciliation: null,
        bankAccount: null,
        compensateDate: null,
    }, dialogRef) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._clientService = _clientService;
        this._bankService = _bankService;
        this._postingCategoryService = _postingCategoryService;
        this._transactionConciliationService = _transactionConciliationService;
        this._bankAccountService = _bankAccountService;
        this.data = data;
        this.dialogRef = dialogRef;
        this.checkReceivedType = 'NEW';
        this.entity = this._newEntity();
        this.bankAccountList = [];
        this.compensateDate = null;
        this.clientFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this.baseService.sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.clientFn = (obj) => this._clientService.getDescription(obj);
        this.bankFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankService.findByValue(value, page, 50, { property: 'name', direction: 'asc' }).toPromise();
            return result;
        });
        this.bankFn = (obj) => `${obj.code} - ${obj.name}`;
        this.postingCategoryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'CREDIT', true).toPromise();
            return result;
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            _super.ngOnInit.call(this);
            this.checkReceivedType = this.data.checkReceivedType;
            this.compensateDate = this._transactionConciliationService.getDateTransaction(this.data.transactionConciliation.date);
            this.entity.amount = this.data.transactionConciliation.value;
            this.entity.issuanceDate = this.compensateDate;
            this.entity.dueDate = this.compensateDate;
            this.entity.date = this.compensateDate;
            if ((this.data.checkReceivedType === 'UPDATE') || (this.data.checkReceivedType === 'INTEREST_AND_FINE')) {
                this.entity = ObjectUtil.clone(this.data.checkReceivedUpdate);
            }
            this._setBankAccount();
            this._setClient();
            this._setPostingCategory();
            this.loading = false;
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    _newEntity() {
        const entity = new FinancialCheckReceivedConciliationDTO();
        entity.clientId = null;
        entity.bankAccountId = null;
        entity.bankId = null;
        entity.phone = '';
        return entity;
    }
    _setClient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (ObjectUtil.isNull(this.data.checkReceivedUpdate)) {
                this.entity.client = null;
                this.entity.clientId = null;
                return;
            }
            const client = yield this._clientService.getOne(this.data.checkReceivedUpdate.clientId).toPromise().catch(e => {
                this.handleError(e);
                return null;
            });
            if (!ObjectUtil.isNull(client)) {
                this.entity.client = FinancialClientSelectDTO.transformToDTO(client);
                this.entity.clientId = this.entity.client.id;
            }
        });
    }
    _setPostingCategory() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (ObjectUtil.isNull(this.data.checkReceivedUpdate)) {
                this.entity.postingCategory = null;
                this.entity.postingCategoryId = null;
                return;
            }
            const postingCategory = yield this._postingCategoryService.getOne(this.data.checkReceivedUpdate.postingCategoryId).toPromise().catch(e => {
                this.handleError(e);
                return null;
            });
            if (!ObjectUtil.isNull(postingCategory)) {
                this.entity.postingCategory = ObjectUtil.clone(postingCategory);
                this.entity.postingCategoryId = this.entity.postingCategory.id;
            }
        });
    }
    _setBankAccount() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const bankAccount = yield this._bankAccountService.getOne(this.data.bankAccount.id).toPromise().catch(e => {
                this.handleError(e);
                return null;
            });
            if (!ObjectUtil.isNull(bankAccount)) {
                this.bankAccountList = [ObjectUtil.clone(bankAccount)];
                this.entity.bankAccount = ObjectUtil.clone(bankAccount);
                this.entity.bankAccountId = this.entity.bankAccount.id;
            }
        });
    }
    _isValid() {
        if (StringUtil.isEmpty(this.entity.cpfOrCnpjIssuer)) {
            this.addWarningMessage('Campo CPF/CNPJ é obrigatório e não foi informado');
            return false;
        }
        if ((this.entity.cpfOrCnpjIssuer.length !== 11) && (this.entity.cpfOrCnpjIssuer.length !== 14)) {
            this.addWarningMessage('Campo CPF/CNPJ foi informado incorretamente');
            return false;
        }
        if (StringUtil.isEmpty(this.entity.issuer)) {
            this.addWarningMessage('Campo emitente é obrigatório e não foi informado');
            return false;
        }
        if ((!StringUtil.isEmpty(this.entity.phone)) && !PhoneUtil.isValid(this.entity.phone)) {
            this.addWarningMessage('Campo telefone foi informado incorretamente');
            return false;
        }
        if (!NumberUtil.isPositive(this.entity.checkNumber)) {
            this.addWarningMessage('Campo nº cheque é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.entity.amount)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.issuanceDate)) {
            this.addWarningMessage('Campo data de emissão é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNull(this.entity.dueDate)) {
            this.addWarningMessage('Campo data de vencimento é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNewModel(this.entity.postingCategory)) {
            this.addWarningMessage('Campo categoria é obrigatório e não foi informado');
            return false;
        }
        if (ObjectUtil.isNewModel(this.entity.bankAccount)) {
            this.addWarningMessage('Campo conta bancária para depósito é obrigatório e não foi informado');
            return false;
        }
        return true;
    }
    saveCheckReceived() {
        try {
            if (!this._isValid()) {
                return;
            }
            this.loading = true;
            this.entity.bankAccountId = this.entity.bankAccount.id;
            this.entity.bankAccount = null;
            this.entity.bankId = null;
            if (!ObjectUtil.isNull(this.entity.bank)) {
                this.entity.bankId = this.entity.bank.id;
                this.entity.bank = null;
            }
            this.entity.clientId = null;
            if (!ObjectUtil.isNull(this.entity.client)) {
                this.entity.clientId = this.entity.client.id;
                this.entity.client = null;
            }
            this.entity.postingCategoryId = null;
            if (!ObjectUtil.isNull(this.entity.postingCategory)) {
                this.entity.postingCategoryId = this.entity.postingCategory.id;
                this.entity.postingCategory = null;
            }
            if ((this.checkReceivedType === 'NEW') || this.checkReceivedType === 'DIFFERENCE') {
                if (ArrayUtil.isEmpty(this.data.transactionConciliation.checkReceivedListInternal)) {
                    this.data.transactionConciliation.checkReceivedListInternal = [];
                }
                this.entity.orderId = this.data.transactionConciliation.checkReceivedListInternal.length + 1;
                this.data.transactionConciliation.checkReceivedListInternal.push(this.entity);
            }
            else if (this.checkReceivedType === 'UPDATE') {
                const indexCheckReceived = this.data.transactionConciliation.checkReceivedListInternal
                    .findIndex(e => (e.id === this.entity.id) || (e.orderId === this.entity.orderId) || (e === this.entity));
                if (indexCheckReceived >= 0) {
                    this.data.transactionConciliation.checkReceivedListInternal.splice(indexCheckReceived, 1, this.entity);
                }
            }
            this.close('RELOAD_TABLE');
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
