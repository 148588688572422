import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, CurrencyUtil, DateUtil, DialogService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { RegistrationIntegrationConsultCnpjCorporateActivityDTO } from '../../models/dto/integration-consult-cnpj-corporate-activity.dto';
import { RegistrationIntegrationConsultCnpjCorporateStructureDTO } from '../../models/dto/integration-consult-cnpj-corporate-structure.dto';
import { RegistrationIntegrationConsultCnpjDTO } from '../../models/dto/integration-consult-cnpj.dto';
import { RegistrationConsultCnpjService } from '../../services/consult-cnpj.service';

@Component({
    templateUrl: './client-sheet-cnpj-dialog.component.html',
    styleUrls: ['./client-sheet-cnpj-dialog.component.scss'],
})
export class GIPIClientSheetCnpjDialogComponent extends AbstractComponent implements OnInit {

    public integrationConsultCnpjDTO: RegistrationIntegrationConsultCnpjDTO = new RegistrationIntegrationConsultCnpjDTO();

    public get corporateMainActivityList(): RegistrationIntegrationConsultCnpjCorporateActivityDTO[] {
        if (ArrayUtil.isEmpty(this.integrationConsultCnpjDTO.corporateActivityList)) {
            return [];
        }
        return this.integrationConsultCnpjDTO.corporateActivityList.filter(ca => ca.mainActivity);
    }

    public get corporateSecondaryActivityList(): RegistrationIntegrationConsultCnpjCorporateActivityDTO[] {
        if (ArrayUtil.isEmpty(this.integrationConsultCnpjDTO.corporateActivityList)) {
            return [];
        }
        return this.integrationConsultCnpjDTO.corporateActivityList.filter(ca => !ca.mainActivity);
    }

    public get corporateStructureList(): RegistrationIntegrationConsultCnpjCorporateStructureDTO[] {
        if (ArrayUtil.isEmpty(this.integrationConsultCnpjDTO.corporateStructureList)) {
            return [];
        }
        return this.integrationConsultCnpjDTO.corporateStructureList;
    }

    constructor(
        public dialogRef: MatDialogRef<GIPIClientSheetCnpjDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: string,
        private _consultCnpjService: RegistrationConsultCnpjService,
        private _dialogService: DialogService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    ngOnInit() {
        super.ngOnInit();
        this._consultCompanyData();
    }

    public close(): void {
        this.dialogRef.close();
    }

    private _consultCompanyData(): void {
        try {
            if (StringUtil.isEmpty(this.data)) {
                this.addWarningMessage('Cnpj é obrigatório e não foi informado');
                return;
            }

            this.loading = true;

            this._consultCnpjService.consultCompanyData(this.data).toPromise().then(integrationConsultCnpj => {
                if (!ObjectUtil.isNull(integrationConsultCnpj)) {
                    this.integrationConsultCnpjDTO = integrationConsultCnpj;
                    this.loading = false;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public formatCnpj(cnpj: string): string {
        if (StringUtil.isEmpty(cnpj)) {
            return '';
        }

        return StringUtil.format(cnpj, '00.000.000/0000-00');
    }

    public formatCnae(cnae: string): string {
        if (StringUtil.isEmpty(cnae)) {
            return '';
        }
        return StringUtil.format(cnae, '0.00-0-00');
    }

    public formatZipCode(cep: string): string {
        if (StringUtil.isEmpty(cep)) {
            return '';
        }
        return StringUtil.format(cep, '00.000-000');
    }

    public formatCurrency(value: number): string {
        if (!value || (value < 0)) {
            return '';
        }

        const valueFormatted: string = CurrencyUtil.transform(value, '1.2-2');
        const valueNotCurrency: string = valueFormatted.substring(2).trim();
        return `${valueFormatted} (${valueNotCurrency.numberFullName(true)})`;
    }

    public formatDate(date: Date): string {
        if (ObjectUtil.isNull(date) || !DateUtil.isValid(date)) {
            return '';
        }

        const diff: number = Math.floor(new Date().getTime() - new Date(date).getTime());
        const day: number = 1000 * 60 * 60 * 24;
        const days = Math.floor(diff / day);
        const months = Math.floor(days / 31);
        const years = Math.floor(months / 12);
        const dateFormatted: string = DateUtil.format(date, DateUtil.DATE_FORMAT);

        if (years > 0) {
            const desc: string = years > 1 ? 'anos' : 'ano';
            return `${dateFormatted} - ${years} ${desc} atrás`;
        } else if (months) {
            const desc: string = months > 1 ? 'meses' : 'mês';
            return `${dateFormatted} - ${months} ${desc} atrás`;
        } else {
            if (days === 0) {
                return `${dateFormatted} - Hoje`;
            }
            const desc: string = days > 1 ? 'dias' : 'dia';
            return `${dateFormatted} - ${days} ${desc} atrás`;
        }
    }

}
