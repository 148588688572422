import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { UUIDUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialBankSlipConfigurationService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.bankSlipConfiguration, http, authenticationService);
    }
    findByBankAccountId(bankAccountId) {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Conta bancária inválida');
        }
        return this.http.get(this.url(`find-by-bank-account-id/${bankAccountId}`)).pipe(map(this.mapper), catchError(this.handleError));
    }
    getNextShipmentNumber(bankAccountId) {
        if (!UUIDUtil.isValid(bankAccountId)) {
            throw new Error('Conta bancária inválida');
        }
        return this.http.get(this.url(`next-shipment-number/find-by-bank-account-id/${bankAccountId}`)).pipe(map(this.mapper), catchError(this.handleError));
    }
}
FinancialBankSlipConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBankSlipConfigurationService_Factory() { return new FinancialBankSlipConfigurationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialBankSlipConfigurationService, providedIn: "root" });
