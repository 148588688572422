<gipi-abstract-form id="dialogWhatsappContact"
                    name="dialogWhatsappContact"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogWhatsappContact">
        Contato via WhatsApp
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <div class="input-content">
                <label class="text-label">Para</label>
                <div class="user">
                    <div class="avatar"> {{ (recipientInitial | async) }} </div>
                    <div class="information">
                        <h3 class="name"> {{ (recipientFirstName | async) }} </h3>
                        <small class="phone"> {{ (recipientPhone | async) }} </small>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <div class="input-content">
                <div class="input-label">
                    <label class="text-label"> Mensagem </label>

                    <div class="actions">
                        <button class="btn-action clear"
                                (click)="clearMessageDefault()">
                            Limpar
                        </button>
                        <button class="btn-action"
                                *ngIf="data?.defaultMessage"
                                (click)="setMessageDefault()">
                            Padrão
                        </button>
                    </div>
                </div>
                <itss-textarea label=""
                               [disabled]="loading"
                               [rows]="5"
                               [minHeight]="'130'"
                               [height]="'130'"
                               [maxHeight]="'180'"
                               [maxlength]="1000"
                               [(ngModel)]="entity.message"
                               fxFlex
                               fxFlex.lt-md="100">
                </itss-textarea>
            </div>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <div class="format-messages">
                <div class="format">
                    <label class="title"> _texto_ </label>
                    <label class="subtitle">
                        <i>Itálico</i>
                    </label>
                </div>

                <div class="format">
                    <label class="title"> *texto* </label>
                    <label class="subtitle">
                        <b>Negrito</b>
                    </label>
                </div>

                <div class="format">
                    <label class="title"> ~texto~ </label>
                    <label class="subtitle">
                        <s>Riscado</s>
                    </label>
                </div>

                <div class="format">
                    <label class="title"> ```texto``` </label>
                    <label class="subtitle">
                        <pre>Monoespaço</pre>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Enviar"
                     gipi-primary
                     id="btnSendMessage"
                     [loading]="loading"
                     (click)="sendMessage()"
                     [disabled]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     [disabled]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
