import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialClient } from '@gipi-financial/client/models/client.model';
import { FinancialUnlockBlockedClientEnum } from '@gipi-financial/configuration/enums/unlock-blocked-client.enum';
import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { wppOptionMessageList } from '@gipi-financial/configuration/shared/wpp-option-messages';
import { FinancialPerson } from '@gipi-financial/person/models/person.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { NotificationCustomMessagingConfiguration } from '@gipi-notification/custom-messaging-configuration/models/custom-messaging-configuration.model';
import { NotificationCustomMessagingConfigurationService } from '@gipi-notification/custom-messaging-configuration/services/custom-messaging-configuration.service';
import { NotificationNotificationSubTypeEnum } from '@gipi-notification/invoice-integrations/enums/notification-subtype.enum';
import { FindClientDialogComponent } from '@gipi-registration/client/components/find-client-dialog/find-client-dialog.component';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { AbstractComponent, ArrayUtil, ConfirmationService, DialogService, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
export class ConfigurationDialogComponent extends AbstractComponent {
    constructor(messageService, router, activatedRoute, _dialogService, _confirmationService, _sessionStorageService, _postingCategoryService, _customMessagingConfigurationService, _configurationService, dialogRef, data) {
        super(messageService, router, activatedRoute);
        this.messageService = messageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._dialogService = _dialogService;
        this._confirmationService = _confirmationService;
        this._sessionStorageService = _sessionStorageService;
        this._postingCategoryService = _postingCategoryService;
        this._customMessagingConfigurationService = _customMessagingConfigurationService;
        this._configurationService = _configurationService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.wppOptionMessageList = [];
        // For configuration
        this.configuration = new FinancialConfiguration();
        this.unlockBlockedClientEnum = FinancialUnlockBlockedClientEnum;
        this.isLoad = false;
        this.postingCategoryForAdditionToCreditFindByValueFn = (value, page) => {
            return this._findPostingCategory(value, page, 'CREDIT');
        };
        this.postingCategoryForAdditionToDebitFindByValueFn = (value, page) => {
            return this._findPostingCategory(value, page, 'DEBIT');
        };
        this.defaultClientCreditCategoryToCreditFindByValueFn = (value, page) => {
            return this._findPostingCategory(value, page, 'CREDIT');
        };
        this.defaultClientCreditCategoryToDebitFindByValueFn = (value, page) => {
            return this._findPostingCategory(value, page, 'DEBIT');
        };
        this.postingCategoryForReceivementCardAdministratorFindByValueFn = (value, page) => {
            return this._findPostingCategory(value, page, 'CREDIT');
        };
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
        this.dialogRef.disableClose = true;
        this._findConfiguration();
        this._getWppOptionMessageList();
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoad = true;
            _super.ngOnInit.call(this);
            if (StringUtil.isEmpty(this.configuration.unlockBlockedClient)) {
                this.configuration.unlockBlockedClient = 'NO_SITUATION';
            }
            this.isLoad = false;
        });
    }
    _findConfiguration() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoad = true;
                this.configuration = yield this._configurationService.findEnabled().toPromise().catch(error => {
                    this.isLoad = false;
                    this.addErrorMessage(error);
                    return null;
                });
            }
            catch (e) {
                this.loading = false;
                this.isLoad = false;
                throw new Error(e);
            }
        });
    }
    _findPostingCategory(value, page, typeOperation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', typeOperation, true).toPromise();
            return Promise.resolve(result);
        });
    }
    selectClient() {
        try {
            this._dialogService.open({
                componentOrTemplateRef: FindClientDialogComponent,
                data: { byConfiguration: true },
                width: '85%',
                height: '65rem'
            }).afterClosed().toPromise().then((client) => {
                if (!ObjectUtil.isNull(client) && this.UUIDIsValid(client.id)) {
                    this.configuration.defaultClientForSales = new FinancialClient(client.id);
                    this.configuration.defaultClientForSales.person = new FinancialPerson();
                    this.configuration.defaultClientForSales.person.name = client.name;
                }
            });
        }
        catch (e) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(e);
        }
    }
    confirm() {
        try {
            this.isLoad = true;
            this.loading = true;
            if (ObjectUtil.isNull(this.configuration)) {
                this.addWarningMessage('A configuração não foi criada, verifique e tente novamente');
                return;
            }
            const customMessagginList = this._prepareForSaveCustomMessagingConfig();
            this._customMessagingConfigurationService.saveAll(customMessagginList).toPromise()
                .then(_ => this._saveConfiguration())
                .catch(error => {
                this.isLoad = false;
                this.loading = false;
                this.handleError(error);
            });
        }
        catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }
    _saveConfiguration() {
        if (ObjectUtil.isNull(this.configuration.graceDays)) {
            this.configuration.graceDays = 0;
        }
        if (ObjectUtil.isNull(this.configuration.daysToNegative)) {
            this.configuration.daysToNegative = 0;
        }
        if (ObjectUtil.isNull(this.configuration.interest)) {
            this.configuration.interest = 0;
        }
        if (ObjectUtil.isNull(this.configuration.fine)) {
            this.configuration.fine = 0;
        }
        this._configurationService.save(this.configuration).toPromise()
            .then((configuration) => {
            if (!ObjectUtil.isNull(configuration) && !ObjectUtil.isNewModel(configuration)) {
                this._sessionStorageService.set('configuration', configuration);
                this.close();
            }
            else {
                this.loading = false;
                this.isLoad = false;
            }
        }).catch(error => {
            this.isLoad = false;
            this.loading = false;
            this.handleError(error);
        });
    }
    close() {
        this.dialogRef.close();
    }
    // For tab WhatsApp
    get hasWppOptionMessageSelected() {
        const selectedList = this.wppOptionMessageList.filter(e => e.selected);
        return !ArrayUtil.isEmpty(selectedList);
    }
    get wppOptionMessageSelected() {
        if (ArrayUtil.isEmpty(this.wppOptionMessageList)) {
            return null;
        }
        return this.wppOptionMessageList.find(e => e.selected);
    }
    get _wppTextEditorRef() {
        if (ObjectUtil.isNull(this.wppTextEditorRef)) {
            return null;
        }
        return this.wppTextEditorRef.nativeElement;
    }
    selectWppOptionMessage(option) {
        if (ArrayUtil.isEmpty(this.wppOptionMessageList) || this.isLoad || this.loading) {
            return;
        }
        this.wppOptionMessageList.forEach(e => {
            e.selected = false;
            e.oldMessage = '';
        });
        const selectedOption = this.wppOptionMessageList.find(e => e.key === option.key);
        if (!ObjectUtil.isNull(selectedOption)) {
            selectedOption.selected = true;
            selectedOption.oldMessage = selectedOption.message;
        }
    }
    _getWppOptionMessageList() {
        const baseList = ArrayUtil.clone(wppOptionMessageList);
        const filter = new GIPIBaseAbstractFilterModel();
        filter.pageNumber = 0;
        filter.pageSize = 300;
        filter.researchField = '';
        this._customMessagingConfigurationService.findAll(filter).toPromise().then((page) => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                for (const customMessage of page.content) {
                    baseList
                        .filter(e => (customMessage.notificationType === 'ACCOUNT_RECEIVABLE') ? (e.key === customMessage.notificationSubType) : (e.key === customMessage.notificationType))
                        .map(e => {
                        e.message = customMessage.message;
                        e.enabled = customMessage.enabled;
                        e.objectApi = ObjectUtil.clone(customMessage);
                        return e;
                    });
                }
            }
            else {
                baseList.map(e => e.message = e.defaultMessage);
            }
            this.wppOptionMessageList = ArrayUtil.clone(baseList);
        }).catch(error => {
            this.addErrorMessage(error);
            this.isLoad = false;
            this.loading = false;
        });
    }
    applyFormat(format) {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || ObjectUtil.isNull(this._wppTextEditorRef)) {
            return;
        }
        const selectionStart = this._wppTextEditorRef.selectionStart;
        const selectionEnd = this._wppTextEditorRef.selectionEnd;
        const selectedText = this.wppOptionMessageSelected.message.substring(selectionStart, selectionEnd);
        if (!StringUtil.isEmpty(selectedText)) {
            const formattedText = format + selectedText + format;
            this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + formattedText + this.wppOptionMessageSelected.message.substring(selectionEnd);
        }
        else {
            const formattedText = format + format;
            const cursorPointer = selectionStart + format.length;
            this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + formattedText + this.wppOptionMessageSelected.message.substring(selectionEnd);
            setTimeout(() => this._wppTextEditorRef.setSelectionRange(cursorPointer, cursorPointer));
        }
        this._wppTextEditorRef.focus();
    }
    insertParam(param) {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || ObjectUtil.isNull(this._wppTextEditorRef)) {
            return;
        }
        const selectionStart = this._wppTextEditorRef.selectionStart;
        const selectionEnd = this._wppTextEditorRef.selectionEnd;
        const cursorPointer = selectionStart + param.length;
        this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + param + this.wppOptionMessageSelected.message.substring(selectionEnd);
        setTimeout(() => this._wppTextEditorRef.setSelectionRange(cursorPointer, cursorPointer));
        this._wppTextEditorRef.focus();
    }
    clearFormatting() {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || ObjectUtil.isNull(this._wppTextEditorRef)) {
            return;
        }
        const selectionStart = this._wppTextEditorRef.selectionStart;
        const selectionEnd = this._wppTextEditorRef.selectionEnd;
        const selectedText = this.wppOptionMessageSelected.message.substring(selectionStart, selectionEnd);
        if (!StringUtil.isEmpty(selectedText)) {
            const cleanedText = selectedText.replace(/[*_~`]/g, '');
            this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.message.substring(0, selectionStart) + cleanedText + this.wppOptionMessageSelected.message.substring(selectionEnd);
            setTimeout(() => this._wppTextEditorRef.setSelectionRange(selectionStart, selectionStart + cleanedText.length));
        }
        this._wppTextEditorRef.focus();
    }
    isValidParamToShow(param) {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected)) {
            return false;
        }
        const hasParam = this.wppOptionMessageSelected.params.includes(param);
        return hasParam;
    }
    restoreDefaultMessage(restoreAll = false) {
        if ((!restoreAll && ObjectUtil.isNull(this.wppOptionMessageSelected)) ||
            (restoreAll && ArrayUtil.isEmpty(this.wppOptionMessageList)) ||
            this.isLoad || this.loading) {
            return;
        }
        this._confirmationService.confirm({
            title: 'Confirmação',
            width: '25%',
            message: `Deseja retornar ${restoreAll ? `todas as mensagens` : `a mensagem`} para o padrão?`,
            accept: () => {
                if (restoreAll) {
                    this.wppOptionMessageList.forEach(e => {
                        e.message = e.defaultMessage;
                        e.selected = false;
                    });
                }
                else {
                    this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.defaultMessage;
                }
            }
        });
    }
    cancelCustomMessage() {
        if (ObjectUtil.isNull(this.wppOptionMessageSelected) || this.isLoad || this.loading) {
            return;
        }
        this.wppOptionMessageSelected.message = this.wppOptionMessageSelected.oldMessage;
        this.wppOptionMessageList.forEach(e => e.selected = false);
    }
    _prepareForSaveCustomMessagingConfig() {
        try {
            this.isLoad = true;
            this.loading = true;
            const isNotificationSubType = (key) => Object.keys(NotificationNotificationSubTypeEnum).includes(key);
            const customMessagingConfigList = this.wppOptionMessageList.map(wpp => {
                const isSubType = isNotificationSubType(wpp.key);
                const customMessaging = !ObjectUtil.isNull(wpp.objectApi) ? ObjectUtil.clone(wpp.objectApi) : new NotificationCustomMessagingConfiguration();
                customMessaging.message = !StringUtil.isEmpty(wpp.message) ? wpp.message : wpp.defaultMessage;
                customMessaging.enabled = wpp.enabled;
                customMessaging.notificationType = isSubType ? 'ACCOUNT_RECEIVABLE' : wpp.key;
                customMessaging.notificationSubType = isSubType ? wpp.key : null;
                return customMessaging;
            });
            return customMessagingConfigList;
        }
        catch (error) {
            this.loading = false;
            this.isLoad = false;
            this.handleError(error);
        }
    }
}
