import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialPostingCategoryService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.postingCategory, 'v1', baseService);
        this.baseService = baseService;
    }
    getNextCode(upperCategory, version) {
        return this.baseService.httpClient.get(this.url(`next-code?upperCategoryId=${ObjectUtil.isNewModel(upperCategory) ? 0 : upperCategory.id}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByOperation(operation, page, size = 10, version) {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        return this.baseService.httpClient.get(this.url(`find-by-operation/${operation}?page=${page}&size=${size}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByValue(value, page, size, sort, version, operation, onlyChildren) {
        const validatedValue = value.trim();
        const validatedPage = Math.max(page, 0);
        const validatedSize = Math.max(size, 10);
        const validatedOperation = !StringUtil.isEmpty(operation) ? operation : 'CREDIT';
        const validatedOnlyChildren = onlyChildren || true;
        const params = new URLSearchParams();
        params.set('value', validatedValue.toString());
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        params.set('operation', validatedOperation.toString());
        params.set('onlyChildren', validatedOnlyChildren.toString());
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            params.set('sort', `${sort.field},${sort.direction}`);
        }
        const queryString = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';
        return this.baseService.httpClient.get(this.url(`find-by-value${queryString}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialPostingCategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialPostingCategoryService_Factory() { return new FinancialPostingCategoryService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialPostingCategoryService, providedIn: "root" });
