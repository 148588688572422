<gipi-abstract-form id="dialogRefundCheckReceived"
                    name="dialogRefundCheckReceived"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRefundCheckReceived">
        Devolução
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px"
             fxFlexFill>
            <gipi-datepicker label="Data devolução"
                             [(ngModel)]="moveDate"
                             required="true"
                             [disabled]="loading"
                             fxFlex="30"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-select-entity-paged label="Motivo da devolução"
                                      required="true"
                                      property="description"
                                      [(model)]="reasonRefund"
                                      [nextBatchFn]="reasonRefundFindByValueFn"
                                      [disabled]="loading"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnConfirm"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="32"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="32"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
