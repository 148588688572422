import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { RegistrationConnectConsultType } from '@gipi-registration/connect-consult/enums/connect-consult-type.enum';
import { RegistrationConnectConsultSummarizeDTO } from '@gipi-registration/connect-consult/models/connect-consult-summarized.dto';
import { RegistrationConnectConsultService } from '@gipi-registration/connect-consult/services/connect-consult.service';
import { TypePerson } from '@gipi-shared/enums/type-person.enum';
import { GIPIAbstractComponent, GIPIBaseService, GIPIUuid, ObjectUtil } from '@gipisistemas/ng-core';

export interface ConnectConsultData {
    consultBy: 'ID' | 'CPF_OR_CNPJ';
    clientId?: GIPIUuid;
    cpfOrCnpj?: string;
    consultType: RegistrationConnectConsultType;
    documentType: TypePerson;
    showDunningNotice: boolean;
}

@Component({
    templateUrl: './connect-consult-dialog.component.html',
    styleUrls: ['./connect-consult-dialog.component.scss']
})
export class ConnectConsultDialogComponent extends GIPIAbstractComponent implements OnInit {

    public connectSummary: RegistrationConnectConsultSummarizeDTO = null;

    public showDunningNotice: boolean = false;

    public consultCostApplied: boolean = false;

    constructor(
        protected service: RegistrationConnectConsultService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<ConnectConsultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConnectConsultData = {
            consultBy: 'CPF_OR_CNPJ',
            clientId: null,
            cpfOrCnpj: '',
            consultType: 'PEFIN_TOP',
            documentType: 'LEGAL_PERSON',
            showDunningNotice: true,
        },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        if (!ObjectUtil.isNull(this.data)) {
            this.showDunningNotice = this.data.showDunningNotice;
            //     this._findConnectSummary();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public findConnectSummary(): void {
        try {
            if (ObjectUtil.isNull(this.data)) {
                return;
            }
            this.loading = true;

            if (this.data.consultBy === 'ID') {
                this.service.summaryByClientId('PEFIN_TOP', this.data.documentType, this.data.clientId).toPromise().then(summary => {
                    this.connectSummary = ObjectUtil.clone(summary);
                    this.showDunningNotice = false;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                });
            } else {
                this.service.summaryByCpfOrCnpj('PEFIN_TOP', this.data.documentType, this.data.cpfOrCnpj).toPromise().then(summary => {
                    this.connectSummary = ObjectUtil.clone(summary);
                    this.showDunningNotice = false;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                });
            }
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public async downloadConnectFull(): Promise<void> {
        try {
            if (ObjectUtil.isNull(this.connectSummary)) {
                return;
            }
            this.loading = true;

            const file: Blob = await this.service.recoverConsultPdfById(this.connectSummary.id).toPromise()
                .then(resp => new Blob([resp.body], { type: 'application/pdf' }))
                .catch(error => {
                    this.loading = false;
                    this.addErrorMessage(error);
                    return null;
                });

            this.loading = false;

            if (!ObjectUtil.isNull(file)) {
                const fileURL: string = URL.createObjectURL(file);
                window.open(fileURL);
            }
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
