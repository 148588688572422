import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

import { SaleClientSelectDTO } from '@gipi-sale/client/models/dto/client-select.dto';
import { SaleClientService } from '@gipi-sale/client/services/client.service';
import { SaleConfiguration } from '@gipi-sale/configuration/models/configuration.model';
import { SaleStatusEnum } from '@gipi-sale/sale/enums/sale-status.enum';
import { SaleTaxInvoiceStatusEnum } from '@gipi-sale/sale/enums/sale-tax-invoice-status.enum';
import { SaleFilterDTO } from '@gipi-sale/sale/models/dto/sale-filter.dto';
import { SaleSale } from '@gipi-sale/sale/models/sale.model';
import { SaleService } from '@gipi-sale/sale/services/sale.service';
import { AppliedFilter } from '@gipi-shared/applied-filter.dto';
import { ArrayUtil, CurrencyUtil, DateUtil, DialogService, GIPIAbstractComponent, GIPIBaseService, GIPIFormFieldComponent, GIPISortModel, GIPIUuid, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';
import { SaleDataDialogComponent } from '../sale-data-dialog/sale-data-dialog.component';

export type FilterTypes = 'begin_and_end_date' | 'begin_date' | 'end_date' | 'tax_coupon_number' | 'tax_coupon_series' | 'document' | 'total' | 'client';

export interface SaleListData {
    salesSelected: SaleSale[];
    selectOne: boolean;
}

@Component({
    templateUrl: './sale-list-dialog.component.html',
    styleUrls: ['./sale-list-dialog.component.scss'],
})
export class SaleListDialogComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;
    @ViewChild('checkAllTemplate', { static: true }) checkAllTemplate: TemplateRef<any>;
    @ViewChild('search', { static: true }) search: GIPIFormFieldComponent;
    @ViewChild('actions', { static: true }) actions: TemplateRef<any>;
    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public columns: TableColumnDTO[] = [];

    public filter: SaleFilterDTO = new SaleFilterDTO();
    public salePage: PageDTO<SaleSale> = new PageDTO<SaleSale>();

    public salesSelected: SaleSale[] = [];
    public selectOne: boolean = false;

    public selected: boolean = false;
    public indeterminate: boolean = false;

    public allTypesChecked: boolean = true;
    public indeterminateType: boolean = false;
    public finishedChecked: boolean = false;
    public partialRefoundCheked: boolean = false;

    public clientIdSelect: GIPIUuid = null;

    public saleStatusEnum: typeof SaleStatusEnum = SaleStatusEnum;

    public saleTaxInvoiceStatusEnum: typeof SaleTaxInvoiceStatusEnum = SaleTaxInvoiceStatusEnum;

    public appliedFilters: AppliedFilter<FilterTypes>[] = [];

    clientFindByValueFn = async (value: string, page: number) => {
        const configuration: SaleConfiguration = this.baseService.sessionStorageService.get('configuration');
        let propertySort: string = 'person.name';
        if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
            propertySort = 'person.fantasyName';
        }
        const result: PageDTO<SaleClientSelectDTO> = await this._clientService.findByValue<SaleClientSelectDTO>(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
        return result;
    };
    clientFn = (obj: SaleClientSelectDTO) => this._clientService.getDescription(obj);

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _saleService: SaleService,
        private _clientService: SaleClientService,
        private _dialogService: DialogService,
        public dialogRef: MatDialogRef<SaleListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SaleListData = { salesSelected: [], selectOne: false },
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        this.selectOne = this.data.selectOne;
        this.salesSelected = [...this.data.salesSelected];

        if (!ArrayUtil.isEmpty(this.salesSelected)) {
            this.clientIdSelect = this.salesSelected[0].client.id;
        }

        this.columns = this._createTableColumnsSale();
        super.ngOnInit();

        this._changeDetectorRef.markForCheck();
    }

    clear(): void {
        this.filter = new SaleFilterDTO();
        this.appliedFilters = [];
        this.find();
    }

    private _setAppliedFilters(): void {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.appliedFilters = [];

        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description: string = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new AppliedFilter<FilterTypes>(description, 'begin_and_end_date'));
        } else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description: string = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter<FilterTypes>(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description: string = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter<FilterTypes>(description, 'end_date'));
            }
        }
        if (this.filter.taxCouponNumber) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Número da nota fiscal', 'tax_coupon_number'));
        }
        if (this.filter.taxCouponSeries) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Série da nota fiscal', 'tax_coupon_series'));
        }
        if (!StringUtil.isEmpty(this.filter.document)) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Documento', 'document'));
        }
        if (this.filter.total) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Valor', 'total'));
        }
        if (!ObjectUtil.isNull(this.filter.client)) {
            this.appliedFilters.push(new AppliedFilter<FilterTypes>('Cliente', 'client'));
        }
    }

    removeAppliedFilter(filter: { chip: AppliedFilter<FilterTypes>, index: number }): void {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'tax_coupon_number': {
                this.filter.taxCouponNumber = null;
                break;
            }
            case 'tax_coupon_series': {
                this.filter.taxCouponNumber = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                break;
            }
            case 'document': {
                this.filter.document = '';
                break;
            }
            case 'total': {
                this.filter.total = null;
                break;
            }
        }

        this.find(null);
    }

    find(pageEvent?: PageEvent): void {
        try {
            if (DateUtil.isGreaterThan(this.filter.begin, this.filter.end)) {
                this.addWarningMessage('Data final não pode ser menor que data inicial');
                return;
            }

            this.loading = true;
            this.salePage = new PageDTO<SaleSale>();

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
            }

            this._setStatusEnumList(this.filter);

            // this.filter.saleIdsExcluded = this.salesSelected.map(sale => sale.id);
            this.filter.taxInvoiceStatusList = ['NOT_TRANSMITTED', 'AUTHORIZED', 'NOT_GENERATED']

            this._setAppliedFilters();

            this._saleService.find(this.filter).toPromise().then((page: PageDTO<SaleSale>) => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    page.content.map(sale => sale.selected = (this.salesSelected.findIndex(s => s.id === sale.id) >= 0));
                }

                this.salePage = page;
                this._validateAllSelected();
                this.loading = false;
            }, (error) => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    private _setStatusEnumList(filter: SaleFilterDTO): void {
        filter.saleStatusList = [];
        if (!this.allTypesChecked) {
            if (this.finishedChecked) {
                filter.saleStatusList.push('FINISHED');
            }
            if (this.partialRefoundCheked) {
                filter.saleStatusList.push('PARTIALLY_RETURNED');
            }
            if (filter.saleStatusList.length === 0) {
                filter.saleStatusList.push('FINISHED');
                filter.saleStatusList.push('PARTIALLY_RETURNED');
            }
        } else {
            filter.saleStatusList.push('FINISHED');
            filter.saleStatusList.push('PARTIALLY_RETURNED');
        }
    }

    private _createTableColumnsSale(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .width(5)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('createdDate')
                .name('Data')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj: SaleSale) => DateUtil.format(obj.date, DateUtil.DATE_FORMAT))
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('clientName')
                .name('Cliente')
                .sortable(true)
                .value((obj: SaleSale) => StringUtil.isEmpty(obj.client.person.fantasyName) ? obj.client.person.name : obj.client.person.fantasyName)
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('documentNumber')
                .name('Documento')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj: SaleSale) => obj.documentNumber)
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('taxCouponNumber')
                .name('N° nota fiscal')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj: SaleSale) => obj.taxCouponNumber)
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('taxCouponSeries')
                .name('Série nota fiscal')
                .width(10)
                .align('center center')
                .sortable(true)
                .value((obj: SaleSale) => obj.taxCouponSeries)
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Total líquido')
                .width(10)
                .align('center center')
                .value((obj: SaleSale) => CurrencyUtil.transform(obj.amountNet, '1.2-2'))
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('taxInvoiceStatus')
                .name('Status nota fiscal')
                .align('center center')
                .width(10)
                .marginLeft(5)
                .marginRight(5)
                .value((obj: SaleSale) => this.saleTaxInvoiceStatusEnum[obj.taxInvoiceStatus])
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(10)
                .marginLeft(5)
                .marginRight(5)
                .template(this.statusTemplate)
                .action((obj: SaleSale) => this._handleActionTable(obj))
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .width(10)
                .align('center center')
                .template(this.actions)
                .build(),
        ];
    }

    private _handleActionTable(entity: SaleSale): void {
        if (ObjectUtil.isNull(entity) || !this.checkboxIsValid(entity)) {
            return;
        }

        entity.selected = !entity.selected;
        this.select(entity, true);

        if (this.selectOne) {
            this.close(true, entity);
        }

        this._changeDetectorRef.detectChanges();
    }

    public close(isConfirm: boolean, entitySelected?: SaleSale): void {
        try {
            if (isConfirm) {
                if (this.selectOne) {
                    this.dialogRef.close([entitySelected]);
                } else {
                    this.dialogRef.close(this.salesSelected);
                }
            } else {
                this.dialogRef.close(null);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    public select(sale: SaleSale, validate: boolean): void {
        try {
            const idClient: GIPIUuid = sale.client.id;

            if ((this.clientIdSelect !== null) && (this.clientIdSelect !== idClient)) {
                setTimeout(() => sale.selected = false);
                this.addWarningMessage('Não é possível selecionar vendas de clientes distintos.');
                return;
            }

            if (sale.selected) {
                this.clientIdSelect = idClient;
                this.salesSelected.push(sale);
            } else {
                this.salesSelected.splice(this.salesSelected.findIndex(s => s.id === sale.id), 1);
                if (this.salesSelected.length <= 0) {
                    this.clientIdSelect = null;
                }
            }

            if (validate) {
                this._validateAllSelected();
            }

            this._changeDetectorRef.detectChanges();
        } catch (e) {
            this.handleError(e);
        }
    }

    private _validateAllSelected(): void {
        this.selected = this.salesSelected.length > 0;
        this.indeterminate = this.salesSelected.length > 0 && this.salesSelected.length !== this.salePage.content.length;
    }

    public checkAll(): void {
        for (let i = 0; i < this.salePage.content.length; i++) {
            this.salePage.content[i].selected = this.selected;
            this.select(this.salePage.content[i], false);
        }

        this._validateAllSelected();
    }

    public checkboxIsValid(entity: SaleSale): boolean {
        if (ObjectUtil.isNull(this.clientIdSelect)) {
            return true;
        }

        const idClient: GIPIUuid = entity.client.id;
        return (this.UUIDIsValid(this.clientIdSelect) && (this.clientIdSelect === idClient));
    }

    public checkAllStatus(): void {
        this.finishedChecked = false;
        this.partialRefoundCheked = false;
        this.indeterminateType = false;

        this._changeDetectorRef.detectChanges();
    }

    public validateAllStatusSelected(): void {
        if (
            this.finishedChecked &&
            this.partialRefoundCheked
        ) {
            this.allTypesChecked = true;
            this.indeterminateType = false;
        } else if (
            this.finishedChecked ||
            this.partialRefoundCheked
        ) {
            this.indeterminateType = true;
            this.allTypesChecked = false;
        } else {
            this.indeterminateType = false;
            this.allTypesChecked = false;
        }

        this._changeDetectorRef.markForCheck();
    }

    public navigateSaleData(entity: SaleSale): void {
        this._dialogService.open({
            componentOrTemplateRef: SaleDataDialogComponent,
            data: entity.id,
            width: '90%',
            height: '90%',
        });
    }

    public setColorStatus(entity: SaleSale): string {
        switch (entity.status) {
            case 'IN_PROGRESS': return '#ffefe3';
            case 'OPENED': return '#d0e6f7';
            case 'FINISHED': return '#d2f8dc';
            case 'CANCELED': return '#eec2c2';
            case 'RETURNED': return '#3B405A';
            case 'PARTIALLY_RETURNED': return '#8256d066';
        }
    }

    public setTextColor(entity: SaleSale): string {
        switch (entity.status) {
            case 'IN_PROGRESS': return '#ffa160';
            case 'OPENED': return '#19348c';
            case 'FINISHED': return '#197837';
            case 'CANCELED': return '#ec5040';
            case 'RETURNED': return '#4A54AB';
            case 'PARTIALLY_RETURNED': return '#8256D0';
        }
    }

}
