export const environment = {
    production: false,
    hmr: false,
    bucketBasePath: 'https://s3.fr-par.scw.cloud/gipi/',
    name: 'developer',
    client: 'GIPI',
    secret: 'lPKomhJE',
    hotjarId: 3315416,
    // movideskId: '6F61887862B547469F6F1DB322677A17',
    zendeskId: '47e7edf8-6594-46ae-b7e0-98eb9b445625',
    noveltiesUrl: 'https://docs.google.com/document/d/e/2PACX-1vQPqG59Lt5pa6szMLWyMfx5k_4Td8Q20lwH7C4efsPgPfBhtvCdFjU7Ejrqw7Ka1wi_KwXy9bOxCIi5/pub?embedded=true',
    recaptcha: {
        siteKey: '6LfxfswnAAAAAI5NkelpLSYhdMnQZl2ZXT99Ba7x',
        secretKey: '6LfxfswnAAAAAIV8642RDOsFw2oYPmIwOs3r6ZXj',
    },
    keyWhatsapp: '@Whats4pp_G1P1%@',
    cnpjCompany: '12924111000173', // Usado para validar se a company é a GIPI
};
