import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, EmailUtil, StringUtil } from '@gipisistemas/ng-core';
export class RequestPasswordComponent extends AbstractComponent {
    constructor(_userPublicService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this._userPublicService = _userPublicService;
    }
    send() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validate();
                this.loading = true;
                yield this._userPublicService.recovery(this.username).toPromise().then(() => {
                    this.addSuccessMessage('Operação realizada com sucesso');
                    this.router.navigate(['oauth/resend-recovery', this.username]);
                }, error => {
                    throw new Error(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    validate() {
        if (!this.isValidUser()) {
            throw new Error('E-mail inválido');
        }
    }
    isValidUser() {
        return !StringUtil.isEmpty(this.username) && EmailUtil.isValid(this.username.toLowerCase());
    }
    keydownSend() {
        if (this.isValidUser()) {
            this.send();
        }
    }
    returnToLogin() {
        this.router.navigate(['/oauth/login']);
    }
}
