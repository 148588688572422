import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialCheckReceivedFilterDTO } from '@gipi-financial/check-received/models/dto/check-received-filter.dto';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { FinancialClientService } from '@gipi-financial/client/services/client.service';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { AppliedFilter } from '@gipi-shared/applied-filter.dto';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, ObjectUtil, PageDTO, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ListCheckReceivedDialogComponent extends GIPIAbstractComponent {
    constructor(dialogRef, data = { checkReceivedIdList: [], onlyStatus: ['OPENED'] }, baseService, activatedRoute, _service, _clientService, _bankAccountService, _postingCategoryService, _sessionStorageService, _changeDetectorRef) {
        super(baseService, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._service = _service;
        this._clientService = _clientService;
        this._bankAccountService = _bankAccountService;
        this._postingCategoryService = _postingCategoryService;
        this._sessionStorageService = _sessionStorageService;
        this._changeDetectorRef = _changeDetectorRef;
        this.filter = new FinancialCheckReceivedFilterDTO();
        this.checkReceivedPage = new PageDTO();
        this.selected = false;
        this.indeterminate = false;
        this.columnsTable = [];
        this.appliedFilters = [];
        this.clientFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const configuration = this._sessionStorageService.get('configuration');
            let propertySort = 'person.name';
            if (!ObjectUtil.isNull(configuration) && configuration.showBusinessNameInReceiptQuery) {
                propertySort = 'person.fantasyName';
            }
            const result = yield this._clientService.findByValue(value, page, 10, new GIPISortModel(propertySort, 'ASC')).toPromise();
            return result;
        });
        this.clientFn = (obj) => this._clientService.getDescription(obj);
        this.bankAccountFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.postingCategoryFindByOperationFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'CREDIT', true).toPromise();
            return result;
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
    }
    ngOnInit() {
        this.columnsTable = this.createTableColumnsCheckReceived();
        super.ngOnInit();
        // this.find();
    }
    ngOnChanges(changes) {
        this._changeDetectorRef.detectChanges();
    }
    ngOnDestroy() {
        this._changeDetectorRef.detach();
    }
    clear() {
        this.filter = new FinancialCheckReceivedFilterDTO();
        this.clearAppliedFilters();
        this.find(null);
    }
    clearAppliedFilters() {
        this.appliedFilters = [];
    }
    _setAppliedFilters() {
        if (ObjectUtil.isNull(this.filter)) {
            return;
        }
        this.clearAppliedFilters();
        if (!ObjectUtil.isNull(this.filter.begin) && !ObjectUtil.isNull(this.filter.end)) {
            const description = `${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)} á ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
            this.appliedFilters.push(new AppliedFilter(description, 'begin_and_end_date'));
        }
        else {
            if (!ObjectUtil.isNull(this.filter.begin)) {
                const description = `Data inicial: ${DateUtil.format(this.filter.begin, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter(description, 'begin_date'));
            }
            if (!ObjectUtil.isNull(this.filter.end)) {
                const description = `Data final: ${DateUtil.format(this.filter.end, DateUtil.DATE_FORMAT)}`;
                this.appliedFilters.push(new AppliedFilter(description, 'end_date'));
            }
        }
        if (!StringUtil.isEmpty(this.filter.issuer)) {
            this.appliedFilters.push(new AppliedFilter('Emitente', 'issuer'));
        }
        if (this.filter.checkNumber) {
            this.appliedFilters.push(new AppliedFilter('Número do cheque', 'check_number'));
        }
        if (this.filter.amount) {
            this.appliedFilters.push(new AppliedFilter('Valor', 'amount'));
        }
        if (!ObjectUtil.isNull(this.filter.client)) {
            this.appliedFilters.push(new AppliedFilter('Cliente', 'client'));
        }
        if (!ObjectUtil.isNull(this.filter.bankAccount)) {
            this.appliedFilters.push(new AppliedFilter('Conta bancária', 'bank_account'));
        }
        if (!ObjectUtil.isNull(this.filter.category)) {
            this.appliedFilters.push(new AppliedFilter('Categoria', 'posting_category'));
        }
    }
    removeAppliedFilter(filter) {
        switch (filter.chip.key) {
            case 'begin_and_end_date': {
                this.filter.begin = null;
                this.filter.end = null;
                break;
            }
            case 'begin_date': {
                this.filter.begin = null;
                break;
            }
            case 'end_date': {
                this.filter.end = null;
                break;
            }
            case 'issuer': {
                this.filter.issuer = '';
                break;
            }
            case 'check_number': {
                this.filter.checkNumber = null;
                break;
            }
            case 'amount': {
                this.filter.amount = null;
                break;
            }
            case 'client': {
                this.filter.client = null;
                break;
            }
            case 'bank_account': {
                this.filter.bankAccount = null;
                break;
            }
            case 'posting_category': {
                this.filter.category = null;
                break;
            }
        }
        this.find(null);
    }
    find(pageEvent) {
        try {
            if (DateUtil.isGreaterThan(this.filter.begin, this.filter.end)) {
                throw new Error('Data final não pode ser menor que data inicial');
            }
            this.loading = true;
            this.filter.statusList = ArrayUtil.clone(this.data.onlyStatus);
            this.checkReceivedPage = new PageDTO();
            this.filter.checkReceivedIdsExcluded = [];
            if (!ObjectUtil.isNull(this.data)) {
                this.filter.checkReceivedIdsExcluded = this.data.checkReceivedIdList;
            }
            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
            }
            else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
            }
            this.filter.offset = 0;
            this.selected = false;
            this.indeterminate = false;
            this._service.findAll(this.filter).toPromise().then((page) => {
                this.checkReceivedPage = page;
                this._setAppliedFilters();
                this.loading = false;
            }, error => {
                this.loading = false;
                this.handleError(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    createTableColumnsCheckReceived() {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkAllTemplate)
                .template(this.checkboxTemplate)
                .width(5)
                .build(),
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .sortable(true)
                .value((obj) => DateUtil.format(obj.check.dueDate, DateUtil.DATE_FORMAT))
                .action((obj) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .width(7)
                .build(),
            TableColumnBuilder.instance()
                .property('checkNumber')
                .name('Nº cheque')
                .sortable(true)
                .value((obj) => obj.check.checkNumber)
                .action((obj) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .width(7)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('category.description')
                .name('Categoria')
                .sortable(true)
                .value((obj) => obj.check.category ? obj.check.category.description : '')
                .action((obj) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .sliceLength(30)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('issuer')
                .name('Emitente')
                .sortable(true)
                .value((obj) => obj.issuer)
                .action((obj) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .build(),
            TableColumnBuilder.instance()
                .property('bankAccount.description')
                .name('Conta bancária')
                .sortable(true)
                .value((obj) => obj.bankAccount ? obj.bankAccount.description : '')
                .action((obj) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .sortable(true)
                .value((obj) => CurrencyUtil.transform(obj.check.amount, '1.2-2'))
                .action((obj) => { obj.selected = !obj.selected; this.validateAllSelected(); })
                .width(10)
                .build(),
        ];
    }
    close(confirmation) {
        if (confirmation) {
            this.dialogRef.close(this.checkReceivedPage.content.filter(e => e.selected));
        }
        else {
            this.dialogRef.close([]);
        }
    }
    validateAllSelected() {
        const list = this.checkReceivedPage.content.filter(checkReceived => checkReceived.selected);
        this.selected = list.length > 0;
        this.indeterminate = list.length > 0 && (list.length !== this.checkReceivedPage.content.length);
    }
    checkAll() {
        this.checkReceivedPage.content.forEach(checkReceived => {
            checkReceived.selected = this.selected;
        });
        this.validateAllSelected();
        this._changeDetectorRef.detectChanges();
    }
}
