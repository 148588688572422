import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ObjectUtil, PasswordUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends AbstractComponent implements OnInit {

    token: string;

    newPassword: string = '';

    newPasswordConfirm: string = '';

    isViewNewPassword: boolean = false;

    isViewNewPasswordConfirm: boolean = false;

    passwordFocused: boolean = false;

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _userPublicService: OAuthUserPublicService,
        private _authenticationService: CustomAuthenticationService,
    ) {
        super(messageService, router, activatedRoute);
        localStorage.removeItem('token');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.token = this.activatedRoute.snapshot.params.token;
    }

    public async updatePassword(): Promise<void> {
        try {
            if (this._validate()) {
                this.loading = true;

                const reponseUser: OAuthUser = await this._userPublicService.updatePassword(this.token, this.newPassword).toPromise().catch(error => {
                    this.loading = false;
                    this.handleError(error);
                    return null;
                });

                if (!ObjectUtil.isNull(reponseUser)) {
                    reponseUser.password = this.newPassword;

                    this._authenticationService.login(reponseUser.username, reponseUser.password).toPromise().then(() => {
                        this.addSuccessMessage('Operação realizada com sucesso');
                        location.replace('/dashboard');
                    }).catch(error => {
                        this.loading = false;
                        this.handleError(error);
                    });
                }
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _validate(): boolean {
        if (StringUtil.isEmpty(this.newPassword)) {
            this.addWarningMessage('Campo "Nova senha" é obrigatório e não foi informado');
            return false;
        } else if (StringUtil.isEmpty(this.newPasswordConfirm)) {
            this.addWarningMessage('Campo "Confirmação nova senha" é obrigatório e não foi informado');
            return false;
        } else if (!PasswordUtil.isValidMinLength(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 6 caracteres');
            return false;
        } else if (!PasswordUtil.isValidMaxLength(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no máximo 15 caracteres');
            return false;
        } else if (!PasswordUtil.hasUppercase(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 1 caractere maiúsculo');
            return false;
        } else if (!PasswordUtil.hasLowercase(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 1 caractere minúsculo');
            return false;
        } else if (!PasswordUtil.hasNumber(this.newPassword)) {
            this.addWarningMessage('A nova senha deve conter no mínimo 1 número');
            return false;
        } else if (PasswordUtil.hasBackspace(this.newPassword)) {
            this.addWarningMessage('A nova senha não deve conter espaços');
            return false;
        } else if (this.newPassword !== this.newPasswordConfirm) {
            this.addWarningMessage('A nova senha e a confirmação da nova senha devem ser iguais');
            return false;
        }

        return true;
    }

    private _isValid(): boolean {
        return (
            !StringUtil.isEmpty(this.newPassword) &&
            !StringUtil.isEmpty(this.newPasswordConfirm) &&
            (this.newPassword === this.newPasswordConfirm)
        );
    }

    public keydownChangePassword(): void {
        if (this._isValid()) {
            this.updatePassword();
        }
    }

    public returnToLogin(): void {
        this.router.navigate(['/oauth/login']);
    }

}
