import { catchError, map } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPISortModel, ObjectUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationCountryService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.country, 'v1', baseService);
        this.baseService = baseService;
    }
    findByCode(code) {
        return this.findByValue(code, 0, 10, new GIPISortModel('name', 'ASC')).pipe(map(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content[0];
            }
            return null;
        }), catchError(this.handleError));
    }
}
RegistrationCountryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationCountryService_Factory() { return new RegistrationCountryService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationCountryService, providedIn: "root" });
