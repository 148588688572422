<gipi-abstract-form id="replicateAccessControl"
                    name="replicateAccessControl"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolabrDialogReplicateAccessControl">
        Replicar cadastro de perfil
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <itss-select-entity label="Para empresa"
                            [(model)]="company"
                            [entities]="companyList"
                            property="person.name"
                            required="true"
                            fxFlex
                            fxFlex.lt-md="100">
        </itss-select-entity>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     (click)="replicate()"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close()"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>

</gipi-abstract-form>
