import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIAbstractFilterModel, GIPIBaseService, GIPISortModel, ObjectUtil } from '@gipisistemas/ng-core';
import { RegistrationCountry } from '../models/country.model';

@Injectable({ providedIn: 'root' })
export class RegistrationCountryService extends GIPIAbstractCrudService<RegistrationCountry, GIPIAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.country, 'v1', baseService);
    }

    findByCode(code: string): Observable<RegistrationCountry> {
        return this.findByValue(code, 0, 10, new GIPISortModel('name', 'ASC')).pipe(
            map(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content[0];
                }
                return null;
            }),
            catchError(this.handleError)
        );
    }

}
