import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIUuid, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';
import { FinancialPaymentFilterDTO } from '../models/dto/payment-filter.dto';
import { FinancialPaymentDTO } from '../models/dto/payment.dto';
import { FinancialPayment } from '../models/payment.model';

@Injectable({ providedIn: 'root' })
export class FinancialPaymentService extends GIPIAbstractCrudService<FinancialPayment, FinancialPaymentFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.payment, 'v1', baseService);
    }

    findByBillInstallment(billInstallmentId: GIPIUuid): Observable<FinancialPayment[]> {
        return this.baseService.httpClient.get(this.url(`find-by-bill-installment/${billInstallmentId}?page=${0}&size=300`), this.options()).pipe(
            map((json) => {
                const page: PageDTO<FinancialPayment> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }

                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    pay(paymentDto: FinancialPaymentDTO): Observable<FinancialPayment[]> {
        return this.baseService.httpClient.post(this.url('pay'), paymentDto).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
