<gipi-abstract-form id="dialogAddBillInstallment"
                    name="dialogAddBillInstallment"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogAddbillInstallment"
                  name="toolbarDialogAddbillInstallment">
        Adicionar conta a {{ isBillReceivable ? 'receber' : 'pagar' }}
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="10px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-input label="Descrição"
                        id="addBillInstallmentDescription"
                        name="addBillInstallmentDescription"
                        [required]="true"
                        [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                        [(ngModel)]="billInstallment.description"
                        fxFlex>
            </itss-input>
            <itss-input label="Número documento"
                        id="addBillInstallmentDocumentNumber"
                        name="addBillInstallmentDocumentNumber"
                        *ngIf="!isBillReceivable"
                        [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                        [(ngModel)]="billInstallment.documentNumber"
                        fxFlex="32"
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity-paged label="Cliente"
                                      id="addBillInstallmentClient"
                                      name="addBillInstallmentClient"
                                      *ngIf="isBillReceivable"
                                      [required]="true"
                                      [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                      [(model)]="billInstallment.bill.client"
                                      [nextBatchFn]="clientFindByValueFn"
                                      [propertyFn]="clientFn"
                                      fxFlex>
            </itss-select-entity-paged>
            <itss-select-entity-paged label="Fornecedor"
                                      id="addBillInstallmentProvider"
                                      name="addBillInstallmentProvider"
                                      *ngIf="!isBillReceivable"
                                      [required]="true"
                                      [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                      [(model)]="billInstallment.bill.provider"
                                      [nextBatchFn]="providerFindByValueFn"
                                      [propertyFn]="providerFn"
                                      fxFlex>
            </itss-select-entity-paged>
            <itss-select-entity-paged label="Categoria"
                                      id="addBillInstallmentCategory"
                                      name="addBillInstallmentCategory"
                                      [required]="true"
                                      [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                      [(model)]="billInstallment.postingCategory"
                                      [nextBatchFn]="postingCategoryFindByValueFn"
                                      [propertyFn]="postingCategoryFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <div fxLayout="row"
                 fxLayoutGap="16px"
                 fxLayout.lt-md="column"
                 fxFlex>
                <gipi-datepicker label="Data emissão"
                                 id="addBillInstallmentIssuanceDate"
                                 name="addBillInstallmentIssuanceDate"
                                 [required]="true"
                                 [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                 [(ngModel)]="billInstallment.issuanceDate"
                                 (focusout)="validateDateIsGreaterThanTransaction()"
                                 fxFlex
                                 fxFlex.lt-md="100">
                </gipi-datepicker>
                <gipi-datepicker label="Data vencimento"
                                 id="addBillInstallmentDueDate"
                                 name="addBillInstallmentDueDate"
                                 [required]="true"
                                 [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                 [(ngModel)]="billInstallment.dueDate"
                                 (focusout)="validateDateIsGreaterThanTransaction()"
                                 fxFlex
                                 fxFlex.lt-md="100">
                </gipi-datepicker>
            </div>
            <itss-select-entity label="Tipo de cobrança"
                                id="addBillInstallmentChargeTypeBill"
                                name="addBillInstallmentChargeTypeBill"
                                [required]="true"
                                [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                [entities]="billInstallmentChargeTypeList"
                                [(model)]="billInstallment.chargeType"
                                [property]="'description'"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-textarea label="Observação"
                           id="billInstallmentComments"
                           name="billInstallmentComments"
                           [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                           [(ngModel)]="billInstallment.comments"
                           [rows]="3"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-textarea>
        </div>

        <div class="content-payment">
            <label for="contentPayment">
                {{ isBillReceivable ? 'Recebimento' : 'Pagamento' }}
            </label>
            <div id="contentPayment"
                 fxLayout="column"
                 fxLayoutAlign="start"
                 fxLayoutGap="16px">
                <div fxLayout="row"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px"
                     fxLayout.lt-md="column">
                    <itss-input-currency label="Valor bruto"
                                         id="addBillInstallmentAmount"
                                         name="addBillInstallmentAmount"
                                         placeholder="R$ 0,00"
                                         [required]="true"
                                         [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                         [(ngModel)]="billInstallment.amount"
                                         (keyup)="setAmountNet()"
                                         fxFlex="calc(15% - 16px)"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                    <itss-input-currency label="Juros"
                                         id="addBillInstallmentInterest"
                                         name="addBillInstallmentInterest"
                                         placeholder="R$ 0,00"
                                         [disabled]="isLoad || (billInstallment.discount > 0)"
                                         [(ngModel)]="billInstallment.interest"
                                         (keyup)="setAmountNet()"
                                         fxFlex="calc(15% - 16px)"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                    <itss-input-currency label="Multa"
                                         id="addBillInstallmentFine"
                                         name="addBillInstallmentFine"
                                         placeholder="R$ 0,00"
                                         [(ngModel)]="billInstallment.fine"
                                         [disabled]="isLoad || (billInstallment.discount > 0)"
                                         (keyup)="setAmountNet()"
                                         fxFlex="calc(15% - 16px)"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                    <itss-input-currency label="Acréscimo"
                                         id="addBillInstallmentAddition"
                                         name="addBillInstallmentAddition"
                                         *ngIf="isBillReceivable"
                                         placeholder="R$ 0,00"
                                         [(ngModel)]="billInstallment.addition"
                                         [disabled]="isLoad || (billInstallment.discount > 0) || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                         (keyup)="setAmountNet()"
                                         fxFlex="calc(15% - 16px)"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                    <itss-input-currency label="Desconto"
                                         id="addBillInstallmentDiscount"
                                         name="addBillInstallmentDiscount"
                                         placeholder="R$ 0,00"
                                         [(ngModel)]="billInstallment.discount"
                                         [disabled]="isLoad || (billInstallment.interest > 0) || (billInstallment.fine > 0) || (billInstallment.addition > 0) || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                         [options]="{ max: billInstallment.amount }"
                                         (keyup)="setAmountNet()"
                                         fxFlex="calc(15% - 16px)"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                </div>
                <div *ngIf="isBillReceivable"
                     fxLayout="row"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px"
                     fxLayout.lt-md="column">
                    <gipi-datepicker label="Data do recebimento"
                                     id="addBillInstallmentReceivementDate"
                                     name="addBillInstallmentReceivementDate"
                                     [required]="true"
                                     [disabled]="true"
                                     [(ngModel)]="receivementDate"
                                     fxFlex="calc(20% - 13px)"
                                     fxFlex.lt-md="100">
                    </gipi-datepicker>
                    <itss-select-entity label="Tipo de cobrança do recebimento"
                                        id="addBillInstallmentChargeTypeReceivement"
                                        name="addBillInstallmentChargeTypeReceivement"
                                        *ngIf="isBillReceivable"
                                        [required]="true"
                                        [disabled]="isLoad || (data.billInstallmentType === 'INTEREST_AND_FINE')"
                                        [(model)]="billInstallment.chargeTypeReceivement"
                                        [entities]="chargeTypeList"
                                        [property]="'description'"
                                        fxFlex="100"
                                        fxFlex.lt-md="100">
                    </itss-select-entity>
                    <itss-input-currency label="Valor líquido"
                                         id="addBillInstallmentAmountNet"
                                         name="addBillInstallmentAmountNet"
                                         placeholder="R$ 0,00"
                                         [disabled]="true"
                                         [(ngModel)]="billInstallmentAmountNet"
                                         fxFlex="calc(20% - 13px)"
                                         fxFlex.lt-md="100">
                    </itss-input-currency>
                </div>
                <div *ngIf="!isBillReceivable"
                     fxLayout="row"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px"
                     fxLayout.lt-md="column">
                    <div fxLayout="row"
                         fxLayoutAlign="start"
                         fxLayoutGap="16px"
                         fxFlex
                         fxFlex.lt-md="100">
                        <gipi-datepicker label="Data do pagamento"
                                         id="addBillInstallmentReceivementDate"
                                         name="addBillInstallmentReceivementDate"
                                         [disabled]="true"
                                         [(ngModel)]="receivementDate"
                                         fxFlex
                                         fxFlex.lt-md="100">
                        </gipi-datepicker>
                        <itss-input-currency label="Valor líquido"
                                             id="addBillInstallmentAmountNet"
                                             name="addBillInstallmentAmountNet"
                                             placeholder="R$ 0,00"
                                             [disabled]="true"
                                             [(ngModel)]="billInstallment.amount"
                                             fxFlex
                                             fxFlex.lt-md="100">
                        </itss-input-currency>
                    </div>
                    <div fxLayout="row"
                         fxFlex
                         fxFlex.lt-md="100"
                         style="align-items: flex-end; justify-content: flex-end;">
                        <!-- <button class="attachment-button"
                                (click)="">
                            <mat-icon
                                      [svgIcon]="(billInstallment.attachment || billInstallment.fileAttachment) ? 'trash' : 'attach'">
                            </mat-icon>
                            {{
                            (billInstallment.attachment || billInstallment.fileAttachment)
                            ? 'Remover anexo'
                            : 'Anexar arquivo'
                            }}
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="isLoad"
                     [loading]="loading"
                     (click)="saveBillInstallment()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
