import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseFilterDTO } from '@gipi-shared/models/dto/base-filter.dto';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIUuid } from '@gipisistemas/ng-core';
import { BilletBillet } from '../models/billet.model';

@Injectable({ providedIn: 'root' })
export class FinancialBilletFinancialService extends GIPIAbstractCrudService<BilletBillet, BaseFilterDTO> {

    constructor(protected baseService: GIPIBaseService,) {
        super(FinancialPaths.billet, 'v1', baseService);
    }

    download(installmentIds: GIPIUuid[]): Observable<any> {
        return this.baseService.httpClient.post(this.url('download'), installmentIds, { ...this.options(), observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    generateBillet(billInstallment: FinancialBillInstallment, generatePdf: boolean): Observable<any> {
        const httpParams: HttpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.baseService.httpClient.post(this.url('generate-billet'), billInstallment, { ...this.options(httpParams), observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    generateBilletAll(billInstallmentList: FinancialBillInstallment[], generatePdf: boolean): Observable<any> {
        const httpParams: HttpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.baseService.httpClient.post(this.url('generate-billet-all'), billInstallmentList, { ...this.options(httpParams), observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    generateBilletForBillInstallmentWithoutBillet(): Observable<any> {
        return this.baseService.httpClient.post(this.url('generate-billet-for-bill-installment-without-billet'), null, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    downloadBillet(billInstallmentList: GIPIUuid[]): Observable<any> {
        return this.baseService.httpClient.post(this.url('download'), billInstallmentList, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
