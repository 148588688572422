import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialConfiguration } from '@gipi-financial/configuration/models/configuration.model';
import { FinancialTypePerson } from '@gipi-financial/person/enums/type-person.enum';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, GIPIUuid, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialClient } from '../models/client.model';
import { FinancialClientFilterDTO } from '../models/dto/client-filter.dto';
import { FinancialClientSelectDTO } from '../models/dto/client-select.dto';

@Injectable({ providedIn: 'root' })
export class FinancialClientService extends GIPIAbstractCrudService<FinancialClient, FinancialClientFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.client, 'v1', baseService);
    }

    getDescription(entity: FinancialClientSelectDTO | FinancialClient): string {
        if (ObjectUtil.isNull(entity)) {
            return '';
        }
        const isDTO: boolean = !('person' in entity);

        let name: string = isDTO ? (entity as FinancialClientSelectDTO).name : (entity as FinancialClient).person.name;

        const configuration: FinancialConfiguration = this.baseService.sessionStorageService.get('configuration');
        if (!ObjectUtil.isNull(configuration)) {
            const fantasyName: string = isDTO ? (entity as FinancialClientSelectDTO).fantasyName : (entity as FinancialClient).person.fantasyName;
            name = configuration.showBusinessNameInReceiptQuery
                ? (!StringUtil.isEmpty(fantasyName) ? fantasyName : name)
                : name;
        }

        const typePerson: FinancialTypePerson = isDTO
            ? (entity as FinancialClientSelectDTO).type
            : (!ObjectUtil.isNull((entity as FinancialClient).person.legalPerson) ? 'LEGAL_PERSON' : 'NATURAL_PERSON');

        const cpfOrCnpj: string = (
            isDTO
                ? (entity as FinancialClientSelectDTO).cpfOrCnpj
                : ((typePerson === 'LEGAL_PERSON') ? (entity as FinancialClient).person.legalPerson.cnpj : (entity as FinancialClient).person.naturalPerson.cpf)
        );

        const formatCpfOrCnpj: string = (typePerson === 'LEGAL_PERSON') ? '00.000.000/0000-00' : '000.000.000-00';
        const cpfOrCnpjFormatted: string = !StringUtil.isEmpty(cpfOrCnpj) ? StringUtil.format(cpfOrCnpj, formatCpfOrCnpj) + ' - ' : '';

        return `${cpfOrCnpjFormatted}${name}`;
    }

    findByCpfOrCnpj(cpfOrCnpj: string): Observable<FinancialClient[]> {
        return this.baseService.httpClient.get(this.url(`find-by-cpf-or-cnpj/${cpfOrCnpj}?page=0&size=1`), this.options()).pipe(
            map((json) => {
                const page: GIPIPageModel<FinancialClient> = this.handleMapper(json);
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    return page.content;
                }
                return [];
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    getCard<T = any>(id: GIPIUuid): Observable<T> {
        return this.baseService.httpClient.get(this.url(`card/${id}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string): Observable<GIPIPageModel<T>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`;
        }

        return this.baseService.httpClient.get(this.url(`find-by-value?migrated=true&page=${page}&size=${size}${lSort}&value=${lValue}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
