<gipi-abstract-form id="dialogConnectConsult"
                    name="dialogConnectConsult"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogConnectConsult">
        Análise de crédito
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div *ngIf="!loading && showDunningNotice"
             class="content-dunning"
             fxLayout="column"
             fxLayoutGap="16px">
            <mat-icon class="icon-information"
                      svgIcon="credit_analisys_cost_applied"> </mat-icon>
            <div class="content-information"
                 (click)="consultCostApplied = !consultCostApplied">
                <gipi-input-checkbox label=""
                                     id="consultCostApplied"
                                     name="consultCostApplied"
                                     [disabled]="loading"
                                     [value]="consultCostApplied"
                                     (click)="$event.stopPropagation()"
                                     (change)="consultCostApplied = !consultCostApplied">
                </gipi-input-checkbox>
                Declaro que li e aceito os termos de uso, estando ciente de que a consulta de análise de crédito, está
                sujeita a cobranças, cujos valores serão aplicados conforme acordado com a Connect SA.
            </div>
        </div>

        <div *ngIf="loading"
             class="centered-loading">
            <div class="dot-pulse">
            </div>
        </div>

        <div *ngIf="!loading && !showDunningNotice"
             fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px">
                <div class="box"
                     fxFlex="25"
                     fxFlex.lt-md="100">
                    <span class="box-title"> Protocolo </span>
                    <span class="box-value"> {{ connectSummary?.protocol }} </span>
                </div>
                <div class="box"
                     fxFlex
                     fxFlex.lt-md="100">
                    <span class="box-title"> Nome do consultante </span>
                    <span class="box-value"> {{ connectSummary?.consultationName }} </span>
                </div>
                <div class="box"
                     fxFlex="25"
                     fxFlex.lt-md="100">
                    <span class="box-title"> Consulta estática </span>
                    <span class="box-value"> {{ connectSummary?.staticConsult === 'S' ? 'Sim' : 'Não' }} </span>
                </div>
            </div>
            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px">
                <div class="box"
                     fxFlex="25"
                     fxFlex.lt-md="100">
                    <span class="box-title"> Documento </span>
                    <span class="box-value"> {{ connectSummary?.consultedDocument }} </span>
                </div>
                <div class="box"
                     fxFlex
                     fxFlex.lt-md="100">
                    <span class="box-title"> Nome do consultado </span>
                    <span class="box-value"> {{ connectSummary?.consultedName }} </span>
                </div>
                <div class="box"
                     fxFlex="25"
                     fxFlex.lt-md="100">
                    <span class="box-title"> Pendências </span>
                    <span class="box-value"> {{ connectSummary?.descriptionResponseCode }} </span>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnConfirm"
                     *ngIf="!loading && showDunningNotice"
                     [disabled]="loading || !consultCostApplied"
                     (click)="findConnectSummary()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
        <gipi-button label="Baixar"
                     gipi-primary
                     id="btnDownload"
                     icon="get_app"
                     *ngIf="!showDunningNotice"
                     [disabled]="loading"
                     (click)="downloadConnectFull()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>