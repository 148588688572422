<gipi-abstract-form id="dialogListPerson"
                    name="dialogListPerson"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogListPerson">
        {{ titleDialog }}
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
        <itss-table [columns]="columns"
                    [paginator]="true"
                    [backendPagination]="false"
                    [paginatorLength]="entityList.length"
                    [paginatorPageSize]="5"
                    [loading]="loading"
                    [data]="entityList">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayout.lt-sm="column"
         fxLayoutAlign="end center"
         fxLayoutGap="16px"
         class="actions">
        <label>
            Para selecionar o cliente clique sobre ele
        </label>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close(false, null)"
                     fxFlex="15"
                     fxFlex.lt-sm="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #statusTemplate
             let-entity>
    <gipi-badge [label]="setTextStatus(entity)"
                [color]="setColorStatus(entity)"
                style="width: 80%;">
    </gipi-badge>
</ng-template>
