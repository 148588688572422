<gipi-abstract-form id="dialogFindReceivementCard"
                    name="dialogFindReceivementCard"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogFindReceivementCard">
        Selecionar recebimentos de cartão
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">

        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="find(null)"
                                     (onSearch)="find(null)">
                    </gipi-form-field>

                    <gipi-button tooltip="Filtrar"
                                 gipi-primary-icon
                                 #searchButton
                                 id="btnOptionsSearch"
                                 svgIcon="filter"
                                 [notification]="appliedFilters && (appliedFilters.length > 0)"
                                 [popover]="true"
                                 [disabled]="loading">
                        <div fxLayout="column"
                             fxLayoutGap="12px"
                             style="max-width: 36rem; padding: 8px;">
                            <div fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlexAlign="space-between center">
                                <gipi-datepicker label="Data inicial"
                                                 id="inputBeginDate"
                                                 [(ngModel)]="filter.begin"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                                <gipi-datepicker label="Data final"
                                                 id="inputEndDate"
                                                 [(ngModel)]="filter.end"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-datepicker>
                            </div>

                            <div fxFlex
                                 fxLayout="row"
                                 fxFlexAlign="space-between center"
                                 fxLayoutGap="12px">
                                <gipi-form-field label="Documento de origem"
                                                 id="inputDocumentNumberOrigin"
                                                 type="text"
                                                 placeholder="Ex: 0001"
                                                 [(ngModel)]="filter.documentNumberOrigin"
                                                 [disabled]="loading"
                                                 fxFlex="48">
                                </gipi-form-field>
                                <gipi-input-currency label="Valor"
                                                     id="inputFilterAmount"
                                                     name="currencyAmount"
                                                     type="currency"
                                                     placeholder="R$ 0,00"
                                                     [(ngModel)]="filter.amount"
                                                     [disabled]="loading"
                                                     fxFlex="48">
                                </gipi-input-currency>
                            </div>

                            <gipi-input-select-paged label="Administradora do cartão"
                                                     [(model)]="filter.cardAdministrator"
                                                     [nextBatchFn]="cardAdministratorFindByValueFn"
                                                     [property]="'description'"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <gipi-input-select-paged label="Bandeira do cartão"
                                                     [(model)]="filter.flagCard"
                                                     [nextBatchFn]="flagCardFindByValueFn"
                                                     [property]="'description'"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <gipi-input-select-paged label="Tipo de cobrança"
                                                     [(model)]="filter.chargeType"
                                                     [nextBatchFn]="chargeTypeFindByValueFn"
                                                     [property]="'description'"
                                                     [disabled]="loading"
                                                     fxFlex>
                            </gipi-input-select-paged>

                            <div style="margin-top: 12px;"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlex>
                                <gipi-button label="Pesquisar"
                                             gipi-primary
                                             id="btnFind"
                                             icon="search"
                                             [disabled]="loading"
                                             (click)="find(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                                <gipi-button label="Limpar"
                                             gipi-secondary
                                             id="btnClear"
                                             icon="clear"
                                             [disabled]="loading"
                                             (click)="clear(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>

                <div fxFlex="15"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-button label="Exibir"
                                 gipi-tertiary
                                 id="btnDisplay"
                                 #displayButton
                                 icon="expand_more"
                                 [disabled]="loading"
                                 [popover]="true"
                                 fxFlex
                                 fxFlex.lt-md="100">
                        <div class="btn-display-content"
                             style="flex-direction: column !important; min-width: 140px !important; gap: 8px;">
                            <itss-checkbox label="Todos"
                                           color="primary"
                                           [(ngModel)]="allStatusChecked"
                                           [(indeterminate)]="indeterminateStatusChecked"
                                           (change)="checkAllStatus()">
                            </itss-checkbox>
                            <itss-checkbox label="A receber"
                                           color="primary"
                                           [(ngModel)]="receivableStatusChecked"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Recebida"
                                           color="primary"
                                           [(ngModel)]="receivedStatusChecked"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Em atraso"
                                           color="primary"
                                           [(ngModel)]="overdueStatusChecked"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Cartão de crédito"
                                           color="primary"
                                           [(ngModel)]="creditCardStatusChecked"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <itss-checkbox label="Cartão de débito"
                                           color="primary"
                                           [(ngModel)]="debitCardStatusChecked"
                                           (change)="validateAllStatusSelected()">
                            </itss-checkbox>
                            <div>
                                <gipi-button label="Aplicar"
                                             gipi-primary
                                             id="btnApplyFilter"
                                             (click)="find(); displayButton.closePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
            <gipi-chips label="Filtros aplicados:"
                        style="max-width: 70%;"
                        [(ngModel)]="appliedFilters"
                        [showRemovable]="true"
                        [showRemovableAll]="true"
                        [disabled]="loading"
                        (removeChange)="removeAppliedFilter($event)"
                        (removeAllChange)="clear()">
            </gipi-chips>
        </div>

        <itss-table [columns]="columns"
                    [loading]="loading"
                    [paginator]="true"
                    [paginatorLength]="page.totalElements"
                    [paginatorPageIndex]="page.number"
                    [paginatorPageSize]="5"
                    [data]="page.content"
                    (page)="find($event)">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Usar selecionados"
                     gipi-primary
                     id="btnConfirm"
                     [disabled]="loading || (receivementCardListSelected?.length === 0)"
                     (click)="close(true)"
                     [loading]="loading"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close(false)"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #checkAllTemplate>
    <!-- <itss-checkbox color="primary"
                   [(ngModel)]="selected"
                   [(indeterminate)]="indeterminate"
                   (change)="checkAll()"
                   fxFlex="4">
    </itss-checkbox> -->
</ng-template>

<ng-template #checkboxTemplate
             let-entity>
    <itss-checkbox color="primary"
                   [disabled]="!checkboxIsValid(entity)"
                   [(ngModel)]="entity.selected"
                   (change)="select(entity, true)"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #statusTemplate
             let-entity>
    <itss-icon class="icon-table"
               icon="fiber_manual_record"
               [tooltip]="receivementCardTypeEnum[entity.status]"
               [color]="setColorStatus(entity)">
    </itss-icon>
</ng-template>