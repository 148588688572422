<gipi-abstract-form id="dialogReceivementCheck"
                    name="dialogReceivementCheck"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogReceivementCheck">
        Receber com cheque
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         (keydown.enter)="loading ? null : confirm()">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-input label="CPF/CNPJ"
                        id="cpfOrCnpjIssuer"
                        [(ngModel)]="checkReceived.cpfOrCnpjIssuer"
                        name="cpfOrCnpjIssuer"
                        mask="000.000.000-00||00.000.000/0000-00"
                        required="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Emitente"
                        id="issuer"
                        [(ngModel)]="checkReceived.issuer"
                        name="issuer"
                        required="true"
                        fxFlex="50"
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-phone label="Telefone"
                              id="numberPhone"
                              name="numberPhone"
                              [placeholder]="'(00) 0 0000-0000'"
                              [(ngModel)]="checkReceived.phone"
                              fxFlex
                              fxFlex.lt-md="100">
            </gipi-input-phone>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity-paged label="Banco"
                                      id="bank"
                                      placeholder="Selecione"
                                      required="true"
                                      [(model)]="checkReceived.bank"
                                      [nextBatchFn]="bankFindByValueFn"
                                      [propertyFn]="bankFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input label="Agência"
                        id="agency"
                        name="agency"
                        [(ngModel)]="checkReceived.agency"
                        mask="0*"
                        maxlength="5"
                        fxFlex="11.2">
            </itss-input>
            <itss-input label="Dígito da agência"
                        id="agencyDigit"
                        name="agencyDigit"
                        [(ngModel)]="checkReceived.agencyDigit"
                        maxlength="3"
                        fxFlex="11.2">
            </itss-input>
            <itss-input label="Conta"
                        id="account"
                        [(ngModel)]="checkReceived.account"
                        name="account"
                        [dropSpecialCharacters]="false"
                        mask="0*"
                        maxlength="20"
                        fxFlex="11.2">
            </itss-input>
            <itss-input label="Dígito da conta"
                        id="accountDigit"
                        name="accountDigit"
                        [(ngModel)]="checkReceived.accountDigit"
                        maxlength="3"
                        fxFlex="11.2">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-input label="N° cheque"
                        id="checkNumber"
                        name="checkNumber"
                        [(ngModel)]="checkReceived.check.checkNumber"
                        [dropSpecialCharacters]="false"
                        mask="0*"
                        required="true"
                        maxlength="10"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input-currency label="Valor"
                                 id="amount"
                                 [(ngModel)]="checkReceived.check.amount"
                                 name="amount"
                                 required="true"
                                 [options]="{nullable: false, min: 0, max: receivement.amountReceivable}"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <gipi-datepicker label="Data emissão"
                             id="issuanceDate"
                             name="issuanceDate"
                             [(ngModel)]="checkReceived.check.issuanceDate"
                             required="true"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <gipi-datepicker label="Data vencimento"
                             id="dueDate"
                             name="dueDate"
                             [(ngModel)]="checkReceived.check.dueDate"
                             required="true"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <itss-select-entity-paged label="Categoria"
                                      id="category"
                                      placeholder="Selecione"
                                      required="true"
                                      *ngIf="billInstallmentList && billInstallmentList.length > 1"
                                      [(model)]="checkReceived.check.category"
                                      [nextBatchFn]="postingCategoryFindByValueFn"
                                      [propertyFn]="postingCategoryFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input label="Observações"
                        id="comments"
                        [(ngModel)]="checkReceived.comments"
                        name="comments"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="end"
             fxLayoutGap="16px"
             fxLayout.lt-md="column">
            <gipi-button label="Adicionar"
                         gipi-primary
                         id="btnAddCheck"
                         (click)="addCheck()"
                         fxFlex="20"
                         fxFlex.lt-md="100">
            </gipi-button>
        </div>
        <itss-table id="tableCheck"
                    [paginator]="false"
                    [columns]="createTableColumns()"
                    [paginatorPageSize]="receivement.checkReceivedList.length"
                    [data]="receivement.checkReceivedList"
                    [backendPagination]="false"
                    [loading]="loading">
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     *ngIf="hasPermission('CHECK_RECEIVED_MAKE') || hasPermission('CHECK_RECEIVED_UPDATE')"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close(false)"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #actions
             let-entity>
    <itss-button tooltip="Excluir"
                 id="buttonRemoveCheck"
                 svgIcon="trash"
                 color="warn"
                 type="icon"
                 [disabled]="loading"
                 (click)="removeCheck(entity)">
    </itss-button>
</ng-template>