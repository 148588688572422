import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationPhone } from '@gipi-registration/person/models/phone.model';
import { RegistrationPhoneType } from '@gipi-registration/phone-type/models/phone-type.model';
import { RegistrationPhoneTypeService } from '@gipi-registration/phone-type/services/phone-type.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, ObjectUtil, UUIDUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'itss-phone-form',
    templateUrl: './phone-form.component.html',
    styleUrls: ['./phone-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneFormComponent),
            multi: true
        }
    ],
})
export class PhoneFormComponent extends AbstractComponent implements OnInit {

    public phoneTypeList: RegistrationPhoneType[] = [];

    private _phoneList: RegistrationPhone[] = [];
    @Input() get phoneList(): RegistrationPhone[] {
        return this._phoneList;
    }
    set phoneList(value: RegistrationPhone[]) {
        this._phoneList = value;
        this.phoneListChange.emit(this._phoneList);
        this._changeDetectorRef.detectChanges();
    }

    @Output() phoneListChange: EventEmitter<RegistrationPhone[]> = new EventEmitter<RegistrationPhone[]>();

    @Input() person: RegistrationPerson = new RegistrationPerson();

    @Input() isCreating: boolean = false;

    @Input() isViewing: boolean = false;

    constructor(
        protected messageService: CustomMessageService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _phoneTypeService: RegistrationPhoneTypeService,
    ) {
        super(messageService, router, activatedRoute);
        this._getPhoneType();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    private _getPhoneType(): void {
        try {
            this._phoneTypeService.findAllEnabled(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.phoneTypeList = page.content;
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public deletePhone(phone: RegistrationPhone): void {
        if (UUIDUtil.isValid(phone.id)) {
            this.phoneList.splice(this.phoneList.findIndex(a => a.id === phone.id), 1);
        } else {
            this.phoneList.splice(this.phoneList.findIndex(a => a.key === phone.key), 1);
        }

        if ((phone.useInInvoice) && (!ArrayUtil.isEmpty(this.phoneList))) {
            this.phoneList[0].useInInvoice = true;
        }
    }

    public addNewPhone(): void {
        const phone = new RegistrationPhone();
        phone.type = new RegistrationPhoneType();
        phone.disabled = true;
        phone.key = this.phoneList.length;

        if ((ArrayUtil.isEmpty(this.phoneList) || this.isCreating) && phone.key === 0) {
            phone.useInInvoice = true;
        }

        this.phoneList.push(phone);
    }

    public validateUseInInvoice(phone: RegistrationPhone): void {
        this.phoneList.map(p => p.useInInvoice = false);

        setTimeout(() => {
            this.phoneList.filter(p => p === phone).map(p => p.useInInvoice = true);
        }, 10);
    }

    public validPhone(phone: RegistrationPhone): void {
        if (!ObjectUtil.isNull(phone.type)) {
            phone.disabled = false;
        } else {
            phone.disabled = true;
        }
    }

}
