import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { CustomerNotificationConfigDialogComponent } from '@gipi-notification/customer-notification-config/components/customer-notification-config-dialog/customer-notification-config-dialog.component';
import { RegistrationConnectConfig } from '@gipi-registration/connect-config/models/connect-config.model';
import { RegistrationConnectConfigService } from '@gipi-registration/connect-config/services/connect-config.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, ArrayUtil, EmailUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, InputComponent, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './integration-connect-dialog.component.html',
    styleUrls: ['./integration-connect-dialog.component.scss'],
})
export class IntegrationConnectDialogComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('panelConnectInputPassword', { static: false, read: InputComponent }) panelConnectInputPasswordRef: InputComponent;

    public connectConfig: RegistrationConnectConfig = new RegistrationConnectConfig();

    public panelConnectOptionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public panelConnectOptionActiveSituationValue: OptionActiveSituation = 'DISABLED';

    public panelConnectBtnViewPassword: boolean = false;

    public get panelConnectIsNewRecord(): Observable<boolean> {
        return of(ObjectUtil.isNull(this.connectConfig) || ObjectUtil.isNewModel(this.connectConfig));
    }

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _renderer2: Renderer2,
        private _connectConfigService: RegistrationConnectConfigService,
        public dialogRef: MatDialogRef<CustomerNotificationConfigDialogComponent>,
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
        this._findConnectConfig();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    private _findConnectConfig(): void {
        try {
            this.loading = true;

            if (!ObjectUtil.isNull(this.connectConfig)) {
                this.connectConfig.version = 'V1';
            }

            this.panelConnectOptionActiveSituationValue = 'ENABLED';

            this._connectConfigService.findAllEnabled<RegistrationConnectConfig>(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this.connectConfig = ObjectUtil.clone(page.content[0]);
                    this.panelConnectOptionActiveSituationValue = (this.connectConfig.enabled ? 'ENABLED' : 'DISABLED');
                }
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                this.addErrorMessage(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _isValid(): boolean {
        if (StringUtil.isEmpty(this.connectConfig.idClient)) {
            this.addWarningMessage('Campo ID Client é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.connectConfig.login)) {
            this.addWarningMessage('Campo login é obrigatório e não foi informado');
            return false;
        }
        if (StringUtil.isEmpty(this.connectConfig.password)) {
            this.addWarningMessage('Campo senha é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this._isValid()) {
                return;
            }
            this.loading = true;

            this.connectConfig.enabled = (this.panelConnectOptionActiveSituationValue === 'ENABLED');
            this.connectConfig.version = 'V1';


            this._connectConfigService.save(this.connectConfig).toPromise().then(response => {
                if (!ObjectUtil.isNull(response)) {
                    this.loading = false;
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);

                    if (ObjectUtil.isNull(this._connectConfigService.updated$)) {
                        this._connectConfigService.updated$ = new BehaviorSubject(false);
                    }
                    this._connectConfigService.updated$.next(true);

                    this.close();
                }
            }).catch(e => {
                this.loading = false;
                this.handleError(e);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public validateEmail(email: string): void {
        if (!StringUtil.isEmpty(email) && !EmailUtil.isValid(email)) {
            this.addWarningMessage('O e-mail informado é inválido');
        }
    }

    public setAutoCompleteInPassword(): void {
        if (!ObjectUtil.isNull(this.panelConnectInputPasswordRef)) {
            this._renderer2.setAttribute(this.panelConnectInputPasswordRef._elementRef.nativeElement, 'autocomplete', 'new-password');
        }
    }

}
