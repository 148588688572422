import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { NotificationNotificationClosingMonthCompanyBillStatementDTO } from './notification-closing-month-company-bill-statement.dto';

export class NotificationNotificationClosingMonthDTO extends GIPIBaseAbstractModel {

    notificationClosingMonthCompanyBillStatementDTOList: NotificationNotificationClosingMonthCompanyBillStatementDTO[];

    endDate: Date;

    startDate: Date;

}
