import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FinancialCheckStatusEnum } from '@gipi-financial/check/enums/check-status.enum';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { ArrayUtil, ConfirmationService, CurrencyUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "../../receivement/services/receivement.service";
import * as i2 from "@gipisistemas/ng-core";
import * as i3 from "@angular/common/http";
import * as i4 from "../../../shared/services/custom-authentication.service";
export class FinancialCancelPaymentService extends BaseCrudService {
    constructor(_receivementService, _confirmationService, http, authenticationService) {
        super(FinancialPaths.cancelPayment, http, authenticationService);
        this._receivementService = _receivementService;
        this._confirmationService = _confirmationService;
    }
    cancel(cancelPayment, billTypeEnum) {
        const url = `cancel/${billTypeEnum}`;
        return this.http.post(this.url(url), cancelPayment, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    numberDocumentPaymentByCheck(installmentIdList) {
        if (ArrayUtil.isEmpty(installmentIdList)) {
            return of(null);
        }
        const installmentList = installmentIdList.map(id => { return { id: id }; });
        return this.http.post(this.url('number-document-payment-by-check'), installmentList, this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    _verifyReceivementsByCardOrCheckReceived(receivementList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const listMessages = [];
                let typeReceivementMessage = '';
                const receivementCardList = [];
                receivementList.filter(r => ((r.chargeType.type === 'CREDIT_CARD') || (r.chargeType.type === 'DEBIT_CARD')) && (!ArrayUtil.isEmpty(r.receivementCardList))).forEach(r => receivementCardList.push(...r.receivementCardList));
                const receivementCheckReceivedList = [];
                receivementList.filter(r => (r.chargeType.type === 'CHECK') && (!ArrayUtil.isEmpty(r.checkReceivedList))).forEach(r => receivementCheckReceivedList.push(...r.checkReceivedList));
                if (!ArrayUtil.isEmpty(receivementCardList)) {
                    const receivementCardReceiptList = receivementCardList.filter(rc => rc.status === 'RECEIVED');
                    if (!ArrayUtil.isEmpty(receivementCardReceiptList)) {
                        for (let i = 0; i < receivementCardReceiptList.length; i++) {
                            const cardAdministrator = receivementCardReceiptList[i].cardAdministrator.description;
                            const flagCard = receivementCardReceiptList[i].flagCard.description;
                            const amount = CurrencyUtil.transform(receivementCardReceiptList[i].amountCard, '1.2-2');
                            const message = `Conta a receber da adm. de cartão ${cardAdministrator} bandeira ${flagCard} valor ${amount}`;
                            listMessages.push(message);
                        }
                        typeReceivementMessage = 'cartão de crédito ou débito';
                    }
                }
                if (!ArrayUtil.isEmpty(receivementCheckReceivedList)) {
                    const checkReceivedReceiptList = receivementCheckReceivedList.filter(cr => cr.check.status !== 'OPENED');
                    if (!ArrayUtil.isEmpty(checkReceivedReceiptList)) {
                        for (let i = 0; i < checkReceivedReceiptList.length; i++) {
                            const checkNumber = checkReceivedReceiptList[i].check.checkNumber.toString();
                            const nameClient = checkReceivedReceiptList[i].client.person.name;
                            const statusCheck = FinancialCheckStatusEnum[checkReceivedReceiptList[i].check.status];
                            const message = `Cheque n° "${checkNumber}", recebido de ${nameClient}, com status "${statusCheck.toLowerCase()}"`;
                            listMessages.push(message);
                        }
                        typeReceivementMessage = (typeReceivementMessage !== '' ? (typeReceivementMessage + ' e ') : '') + 'cheque';
                    }
                }
                if (listMessages.length > 0) {
                    yield this._confirmationService.confirm({
                        title: 'Aviso',
                        message: `Este recebimento foi realizado com ${typeReceivementMessage}, para prosseguir você deverá realizar o cancelamento dos seguintes documentos:`,
                        width: '30%',
                        type: 'warning',
                        listMessage: listMessages
                    });
                    return Promise.resolve(true);
                }
                else {
                    return Promise.resolve(false);
                }
            }
            catch (e) {
                throw new Error(e);
            }
        });
    }
    _verifyLinkedBillsReceived(receivementList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const listMessages = [];
                let typeReceivementMessage = '';
                const receivementLinkedList = [];
                const receivementCardList = [];
                const receivementCheckReceivedList = [];
                for (let i = 0; i < receivementList.length; i++) {
                    if (!ArrayUtil.isEmpty(receivementList[i].receivementCardList) || !ArrayUtil.isEmpty(receivementList[i].checkReceivedList)) {
                        const list = yield this._receivementService.findLinkedReceivements(receivementList[i].id).toPromise();
                        receivementLinkedList.push(...list);
                        if (!ArrayUtil.isEmpty(receivementList[i].receivementCardList)) {
                            receivementCardList.push(...receivementList[i].receivementCardList);
                        }
                        if (!ArrayUtil.isEmpty(receivementList[i].checkReceivedList)) {
                            receivementCheckReceivedList.push(...receivementList[i].checkReceivedList);
                        }
                    }
                }
                if (!ArrayUtil.isEmpty(receivementLinkedList)) {
                    for (let i = 0; i < receivementLinkedList.length; i++) {
                        const documentNumber = receivementLinkedList[i].billInstallment.documentNumber
                            ? `de documento n° "${receivementLinkedList[i].billInstallment.documentNumber}"`
                            : '';
                        const amount = CurrencyUtil.transform(receivementLinkedList[i].billInstallment.amount, '1-2.2');
                        const message = `Conta ${documentNumber} no valor de ${amount}`;
                        listMessages.push(message);
                    }
                    if (!ArrayUtil.isEmpty(receivementCardList)) {
                        typeReceivementMessage = 'cartão de crédito ou débito';
                    }
                    if (!ArrayUtil.isEmpty(receivementCheckReceivedList)) {
                        typeReceivementMessage = (typeReceivementMessage !== '' ? (typeReceivementMessage + ' e ') : '') + 'cheque';
                    }
                }
                if (listMessages.length > 0) {
                    const isConfirmed = yield this._confirmationService.confirm({
                        title: 'Confirmação',
                        message: `Este recebimento foi realizado com ${typeReceivementMessage}. Portanto, ao confirmar o cancelamento irá estornar os seguintes documentos:`,
                        width: '30%',
                        listMessage: listMessages
                    });
                    return Promise.resolve(isConfirmed);
                }
                else {
                    return Promise.resolve(true);
                }
            }
            catch (e) {
                throw new Error(e);
            }
        });
    }
    /** Utilizado para contas a receber */
    isCancelReceivementAllowed(installmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const receivementList = yield this._receivementService.findByBillInstallment(installmentId).toPromise().catch(error => {
                    throw new Error(error);
                });
                const hasReceivement = yield this._verifyReceivementsByCardOrCheckReceived(receivementList);
                if (hasReceivement) {
                    return Promise.resolve(false);
                }
                else {
                    const hasLinkedBillsReceived = yield this._verifyLinkedBillsReceived(receivementList);
                    return Promise.resolve(hasLinkedBillsReceived);
                }
            }
            catch (e) {
                throw new Error(e);
            }
        });
    }
    /** Utilizado para contas a pagar */
    isCancelPaymentAllowed(installmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const paymentByCheck = yield this.numberDocumentPaymentByCheck([installmentId]).toPromise().catch(error => {
                    throw new Error(error);
                });
                if (ObjectUtil.isNull(paymentByCheck)) {
                    return Promise.resolve(false);
                }
                if (ArrayUtil.isEmpty(paymentByCheck.checkIssuedList) && ArrayUtil.isEmpty(paymentByCheck.checkReceivedList)) {
                    return Promise.resolve(true);
                }
                let listMessages = [];
                let typePaymentMessage = '';
                let numberDocumentPayment = '';
                if (paymentByCheck.checkReceivedList.length > 0) {
                    if (!ArrayUtil.isEmpty(paymentByCheck.numberDocumentPaymentCheckReceived) && (paymentByCheck.numberDocumentPaymentCheckReceived.length > 1)) {
                        numberDocumentPayment = paymentByCheck.numberDocumentPaymentCheckReceived.join(', ');
                    }
                    const isMoreOne = (paymentByCheck.checkReceivedList.length > 1) ? 's' : '';
                    let firstText = '';
                    if (!StringUtil.isEmpty(numberDocumentPayment)) {
                        firstText = `A${isMoreOne} conta${isMoreOne} de documento${isMoreOne} nº ${numberDocumentPayment} ${isMoreOne === 's' ? 'foram pagas' : 'foi paga'} com cheque${isMoreOne} recebido${isMoreOne}`;
                    }
                    else {
                        firstText = `Este pagamento foi realizado com cheque${isMoreOne} recebido${isMoreOne}`;
                    }
                    typePaymentMessage = `${firstText}. Ao prosseguir, os seguintes documentos serão estornados e o pagamento será cancelado.`;
                    for (let i = 0; i < paymentByCheck.checkReceivedList.length; i++) {
                        const checkNumber = paymentByCheck.checkReceivedList[i].checkNumber.toString();
                        const issuerName = paymentByCheck.checkReceivedList[i].issuerName;
                        const statusCheck = FinancialCheckStatusEnum[paymentByCheck.checkReceivedList[i].status].toLowerCase();
                        const message = `Cheque n° "${checkNumber}", recebido de ${issuerName}, com status "${statusCheck}"`;
                        listMessages.push(message);
                    }
                    if (listMessages.length > 0) {
                        const isConfirmed = yield this._confirmationService.confirm({
                            title: 'Aviso',
                            message: typePaymentMessage,
                            width: '30%',
                            type: 'warning',
                            listMessage: listMessages,
                        });
                        return Promise.resolve(isConfirmed);
                    }
                    else {
                        return Promise.resolve(true);
                    }
                }
                else if ((paymentByCheck.checkIssuedList.length > 0) || (paymentByCheck.checkWithMovementList.length > 0)) {
                    if (!ArrayUtil.isEmpty(paymentByCheck.numberDocumentPaymentCheckIssued) && (paymentByCheck.numberDocumentPaymentCheckIssued.length > 1)) {
                        numberDocumentPayment = paymentByCheck.numberDocumentPaymentCheckIssued.join(', ');
                    }
                    const isMoreOne = (paymentByCheck.checkIssuedList.length > 1) ? 's' : '';
                    let firstText = '';
                    if (!StringUtil.isEmpty(numberDocumentPayment)) {
                        firstText = `A${isMoreOne} conta${isMoreOne} de documento${isMoreOne} nº ${numberDocumentPayment} ${isMoreOne === 's' ? 'foram pagas' : 'foi paga'} com cheque${isMoreOne} emitido${isMoreOne}`;
                    }
                    else {
                        firstText = `Este pagamento foi realizado com cheque${isMoreOne} emitido${isMoreOne}`;
                    }
                    const checkIssuedCompensatedList = paymentByCheck.checkWithMovementList.filter(e => e.status === 'COMPENSATED');
                    if (!ArrayUtil.isEmpty(checkIssuedCompensatedList)) {
                        typePaymentMessage = `${firstText}, para prosseguir você deverá realizar o cancelamento dos seguintes documentos:`;
                        for (let i = 0; i < checkIssuedCompensatedList.length; i++) {
                            const checkNumber = checkIssuedCompensatedList[i].checkNumber.toString();
                            const issuerName = checkIssuedCompensatedList[i].issuerName;
                            const statusCheck = FinancialCheckStatusEnum[checkIssuedCompensatedList[i].status].toLowerCase();
                            const message = `Cheque n° "${checkNumber}", emitido por ${issuerName}, com status "${statusCheck}"`;
                            listMessages.push(message);
                        }
                    }
                    else {
                        typePaymentMessage = `${firstText}. Ao prosseguir, os seguintes documentos serão estornados e o pagamento será cancelado.`;
                        for (let i = 0; i < paymentByCheck.checkIssuedList.length; i++) {
                            const checkNumber = paymentByCheck.checkIssuedList[i].checkNumber.toString();
                            const issuerName = paymentByCheck.checkIssuedList[i].issuerName;
                            const statusCheck = FinancialCheckStatusEnum[paymentByCheck.checkIssuedList[i].status].toLowerCase();
                            const message = `Cheque n° "${checkNumber}", emitido por ${issuerName}, com status "${statusCheck}"`;
                            listMessages.push(message);
                        }
                    }
                    if (listMessages.length > 0) {
                        const isConfirmed = yield this._confirmationService.confirm({
                            title: 'Aviso',
                            message: typePaymentMessage,
                            width: '30%',
                            type: 'warning',
                            listMessage: listMessages,
                        });
                        const result = !ArrayUtil.isEmpty(checkIssuedCompensatedList) ? false : isConfirmed;
                        return Promise.resolve(result);
                    }
                    else {
                        return Promise.resolve(true);
                    }
                }
                else {
                    return Promise.resolve(true);
                }
            }
            catch (e) {
                throw new Error(e);
            }
        });
    }
}
FinancialCancelPaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCancelPaymentService_Factory() { return new FinancialCancelPaymentService(i0.ɵɵinject(i1.FinancialReceivementService), i0.ɵɵinject(i2.ConfirmationService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.CustomAuthenticationService)); }, token: FinancialCancelPaymentService, providedIn: "root" });
