<div fxLayout="column"
     fxLayoutAlign="center center">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         fxLayoutGap="150px"
         fxFlex="100vh">
        <div fxFlex="20"
             fxHide.lt-md="true">
            <h1>TEMOS O HÁBITO DE SIMPLIFICAR AS ROTINAS DOS EMPREENDEDORES</h1>
        </div>
        <itss-card class="login"
                   [class.login-recaptcha]="numberFailedAttempts >= 3">
            <div fxLayout="column"
                 fxLayoutAlign="center center">
                <img width="180"
                     src="../../../assets/logo/logo_and_name.png" />
            </div>

            <div fxLayout="column"
                 fxLayoutGap="16px">
                <itss-input label="E-mail"
                            class="input-rounded"
                            #firstInput
                            id="email"
                            placeholder="E-mail"
                            [(ngModel)]="username"
                            [disabled]="loading"
                            fxFlex="100"
                            (keydown.enter)="login()">
                </itss-input>

                <itss-input label="Senha"
                            class="input-rounded"
                            id="password"
                            [type]="isViewPassword ? 'text' : 'password'"
                            placeholder="Senha"
                            [disabled]="loading"
                            [(ngModel)]="password"
                            fxFlex="100"
                            (keydown.enter)="login()">
                    <ng-template #suffix>
                        <itss-button [tooltip]="isViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                     id="buttonViewPassword"
                                     [svgIcon]="isViewPassword ? 'eye' : 'eye_off'"
                                     color="primary"
                                     type="icon"
                                     (click)="isViewPassword = !isViewPassword">
                        </itss-button>
                    </ng-template>
                </itss-input>

                <div class="recaptcha"
                     *ngIf="numberFailedAttempts >= 3">
                    <re-captcha id="recaptcha"
                                name="recaptcha"
                                #recaptcha="ngModel"
                                [(ngModel)]="tokenReCAPTCHA"
                                [required]="true"
                                [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
                    </re-captcha>
                </div>

                <div fxLayoutAlign="center center"
                     fxLayout="row"
                     fxLayoutGap="0"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="10px">
                    <a id="buttonRequestPassword"
                       routerLink="/oauth/request-password">
                        Esqueci minha senha
                    </a>
                    <div fxFlex> </div>
                    <button class="btn-rounded confirm"
                            id="buttonLogin"
                            aria-label="Entrar"
                            [disabled]="loading"
                            (click)="login()"
                            fxFlex>
                        Entrar
                    </button>
                </div>
            </div>
        </itss-card>
    </div>
</div>