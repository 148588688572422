import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { TypePerson } from '@gipi-shared/enums/type-person.enum';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIUuid, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import { RegistrationConnectConsultType } from '../enums/connect-consult-type.enum';
import { RegistrationConnectConsultSummarizeDTO } from '../models/connect-consult-summarized.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationConnectConsultService extends GIPIAbstractCrudService<RegistrationConnectConsultSummarizeDTO, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.connectConsult, 'v1', baseService);
    }

    summaryByClientId(consultType: RegistrationConnectConsultType, documentType: TypePerson, clientId: GIPIUuid, version?: string): Observable<RegistrationConnectConsultSummarizeDTO> {
        if (
            StringUtil.isEmpty(consultType) ||
            StringUtil.isEmpty(documentType) ||
            (!StringUtil.isEmpty(documentType) && (documentType === 'FOREIGN_PERSON')) ||
            !UUIDUtil.isValid(clientId)
        ) {
            return of(null);
        }
        return this.baseService.httpClient.get(this.url(`summary-by-client-id/${consultType}/${documentType}/${clientId}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    summaryByCpfOrCnpj(consultType: RegistrationConnectConsultType, documentType: TypePerson, cpfOrCnpj: string, version?: string): Observable<RegistrationConnectConsultSummarizeDTO> {
        if (
            StringUtil.isEmpty(consultType) ||
            StringUtil.isEmpty(documentType) ||
            (!StringUtil.isEmpty(documentType) && (documentType === 'FOREIGN_PERSON')) ||
            StringUtil.isEmpty(cpfOrCnpj)
        ) {
            return of(null);
        }
        return this.baseService.httpClient.get(this.url(`summary-by-cpf-or-cnpj/${consultType}/${documentType}/${cpfOrCnpj}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    recoverConsultPdfById(idConsult: GIPIUuid, version?: string): Observable<any> {
        return this.baseService.httpClient.get(this.url(`recover-consult-pdf-by-id/${idConsult}`, version), { observe: 'response', responseType: 'blob' as 'json' }).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
