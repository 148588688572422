<div fxLayout="column"
     fxFlex
     fxLayoutGap="16px">
    <ng-container *ngFor="let address of addressList; let i = index; trackBy: ">
        <div [attr.id]="'groupAddress' + i"
             *ngIf="addressList && (addressList.length > 0) && !loading"
             class="group-panel-register"
             fxLayout="column"
             fxLayoutGap="16px"
             fxFlex>
            <gipi-button tooltip="Excluir"
                         gipi-icon
                         class="btn-delete-group"
                         id="buttonDeleteAddress"
                         name="buttonDeleteAddress"
                         icon="close"
                         *ngIf="!loading && !isViewing"
                         (click)="deleteAddress(address)">
            </gipi-button>

            <div fxLayout="row"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px"
                 fxFlex>
                <itss-select-enum label="Tipo de endereço"
                                  id="typeAddress"
                                  name="typeAddress"
                                  [required]="true"
                                  [disabled]="loading || isViewing || disabledTypeAddress || address.useInInvoice"
                                  [enum]="typeAddressEnum"
                                  [(ngModel)]="address.type"
                                  (selectionChange)="setUseInInvoice(address)"
                                  fxFlex="15"
                                  fxFlex.lt-sm="100">
                </itss-select-enum>
                <itss-checkbox label="Endereço padrão"
                               id="useInInvoiceAddress"
                               name="useInInvoiceAddress"
                               class="checkbox-center checkbox-use-invoice"
                               color="primary"
                               *ngIf="address?.type === 'RESIDENTIAL'"
                               [disabled]="loading || isViewing"
                               [ngModel]="address.useInInvoice"
                               (change)="validateUseInInvoice(address)">
                </itss-checkbox>
            </div>

            <ng-container *ngIf="person.typePerson !== 'FOREIGN_PERSON'">
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px">
                    <itss-input label="CEP"
                                id="cepAddress"
                                [required]="true"
                                [disabled]="loading || isViewing"
                                [mask]="'00.000-000'"
                                [(ngModel)]="address.zipCode"
                                [min]="1"
                                [max]="10"
                                [maxlength]="10"
                                (keydown.enter)="findCep(address)"
                                fxFlex="15"
                                fxFlex.lt-sm="100">
                        <ng-template #suffix>
                            <div fxLayout="row"
                                 fxLayoutGap="5px">
                                <gipi-button tooltip="Buscar CEP"
                                             gipi-icon
                                             id="btnSearchCepAddress"
                                             svgIcon="search"
                                             [forInput]="true"
                                             [disabled]="loadingFindCep || loading || isViewing"
                                             (click)="findCep(address)">
                                </gipi-button>
                            </div>
                        </ng-template>
                    </itss-input>
                    <itss-input label="Endereço"
                                id="streetAddress"
                                [min]="3"
                                [max]="60"
                                [maxlength]="60"
                                [required]="true"
                                [disabled]="loading || isViewing"
                                [(ngModel)]="address.street"
                                fxFlex
                                fxFlex.lt-sm="100">
                    </itss-input>
                    <itss-input label="Número"
                                id="streetNumberAddress"
                                styleClassFormField="input-checkbox-in-input"
                                [disabled]="loading || isViewing || address.withoutStreetNumber"
                                [required]="true"
                                [(ngModel)]="address.streetNumber"
                                [min]="2"
                                [max]="6"
                                [maxlength]="6"
                                (ngModelChange)="validateStreetNumber($event, address)"
                                fxFlex="15"
                                fxFlex.lt-sm="100">
                        <ng-template #suffix
                                     *ngIf="!loading && !isViewing">
                            <div class="hover-street-number">
                                <gipi-input-checkbox label="Sem número"
                                                     id="withoutStreetNumberAddress"
                                                     name="withoutStreetNumberAddress"
                                                     [disabled]="loading || isViewing"
                                                     [(ngModel)]="address.withoutStreetNumber"
                                                     (change)="onChangeStreetNumber($event, address)">
                                </gipi-input-checkbox>
                            </div>
                        </ng-template>
                    </itss-input>
                    <itss-input label="Bairro"
                                id="neighborhoodAddress"
                                [min]="3"
                                [max]="50"
                                [maxlength]="50"
                                [required]="true"
                                [disabled]="loading || isViewing"
                                [(ngModel)]="address.neighborhood"
                                fxFlex
                                fxFlex.lt-sm="100">
                    </itss-input>
                </div>
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px"
                     fxFlex>
                    <itss-select-entity label="País"
                                        id="countryCityAddress"
                                        [required]="true"
                                        [disabled]="true"
                                        [entities]="countryList"
                                        [(model)]="address.country"
                                        [property]="'name'"
                                        fxFlex="15"
                                        fxFlex.lt-sm="100">
                    </itss-select-entity>
                    <itss-select-entity label="Estado"
                                        id="stateCityAddress"
                                        name="stateCityAddress"
                                        [entities]="stateList"
                                        [(model)]="address.state"
                                        (selectionChange)="getCity(address)"
                                        [disabled]="loading || isViewing"
                                        [showClear]="true"
                                        [required]="true"
                                        property="acronym"
                                        fxFlex="15"
                                        fxFlex.lt-sm="100">
                    </itss-select-entity>
                    <itss-select-entity-paged label="Cidade"
                                              id="cityAddress"
                                              name="cityAddress"
                                              required="true"
                                              property="name"
                                              [(model)]="address.city"
                                              [disabled]="loading || isViewing || (!address?.state)"
                                              [paramNextBatch]="address"
                                              [nextBatchFn]="cityFindByValueFn"
                                              fxFlex
                                              fxFlex.lt-sm="100">
                    </itss-select-entity-paged>
                </div>
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px">
                    <itss-textarea label="Complemento"
                                   id="complementAddress"
                                   name="complementAddress"
                                   [maxlength]="80"
                                   [rows]="4"
                                   [disabled]="loading || isViewing"
                                   [(ngModel)]="address.complement"
                                   fxFlex
                                   fxFlex.lt-sm="100">
                    </itss-textarea>
                </div>
            </ng-container>

            <ng-container *ngIf="person.typePerson === 'FOREIGN_PERSON'">
                <div fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutAlign="start"
                     fxLayoutGap="16px">
                    <itss-select-entity label="País"
                                        id="countryCityAddress"
                                        [required]="true"
                                        [disabled]="loading || isViewing"
                                        [entities]="countryList"
                                        [valuesExclud]="countryExcludedList"
                                        [(model)]="address.country"
                                        (selectionChange)="setCountryCode(address)"
                                        [property]="'name'"
                                        fxFlex="15"
                                        fxFlex.lt-sm="100">
                    </itss-select-entity>
                    <itss-input label="CEP"
                                id="cepAddress"
                                [required]="true"
                                [disabled]="loading || isViewing"
                                [mask]="''"
                                [(ngModel)]="address.zipCode"
                                [min]="1"
                                [max]="9"
                                [maxlength]="9"
                                fxFlex="15"
                                fxFlex.lt-sm="100">
                    </itss-input>
                    <itss-input label="Endereço"
                                id="streetAddress"
                                [min]="3"
                                [max]="60"
                                [maxlength]="60"
                                [required]="true"
                                [disabled]="loading || isViewing"
                                [(ngModel)]="address.street"
                                fxFlex
                                fxFlex.lt-sm="100">
                    </itss-input>
                    <itss-input label="Bairro"
                                id="neighborhoodAddress"
                                [min]="3"
                                [max]="50"
                                [maxlength]="50"
                                [required]="true"
                                [disabled]="loading || isViewing"
                                [(ngModel)]="address.neighborhood"
                                fxFlex
                                fxFlex.lt-sm="100">
                    </itss-input>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="group-panel-register new-group-panel-register"
         id="buttonNewAddress"
         *ngIf="!loading && !isViewing"
         (click)="addNewAddress()">
        <label> + Adicionar endereço </label>
    </div>
</div>

<ng-template #useInInvoice
             let-address>
    <itss-checkbox label="Endereço padrão"
                   id="useInInvoiceAddress"
                   class="checkbox-center"
                   color="primary"
                   [disabled]="loading || isViewing"
                   [ngModel]="address.useInInvoice"
                   (change)="validateUseInInvoice(address)"
                   fxFlex="15"
                   fxFlex.lt-sm="30">
    </itss-checkbox>
</ng-template>
