import { BehaviorSubject } from 'rxjs';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationConnectConfigService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.connectConfig, 'v1', baseService);
        this.baseService = baseService;
        /** Se trocar as permissões enquanto estiver na tela que usa a connect */
        this.updated$ = new BehaviorSubject(false);
    }
}
RegistrationConnectConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationConnectConfigService_Factory() { return new RegistrationConnectConfigService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationConnectConfigService, providedIn: "root" });
