import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { PageEvent } from '@angular/material/paginator';
import { RegistrationProductConsultDTO } from '@gipi-registration/product/models/dto/product-consult.dto';
import { RegistrationProductFilterDTO } from '@gipi-registration/product/models/dto/product-filter.dto';
import { RegistrationProductService } from '@gipi-registration/product/service/product.service';
import { ArrayUtil, CurrencyUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface RegistrationProductListData {
    productsSelected: RegistrationProductConsultDTO[];
    selectOne: boolean;
}

@Component({
    templateUrl: './product-list-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class RegistrationProductListDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('checkboxAllTemplate', { static: true }) checkboxAllTemplate: TemplateRef<any>;

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public columns: TableColumnDTO[] = [];

    public productsSelected: RegistrationProductConsultDTO[] = [];
    public selectOne: boolean = false;

    public filter: RegistrationProductFilterDTO = new RegistrationProductFilterDTO();

    public productPage: GIPIPageModel<RegistrationProductConsultDTO> = new GIPIPageModel();

    public selectedAll: boolean = false;
    public indeterminateAll: boolean = false;

    public allTypesChecked: boolean = false;
    public indeterminateAllTypes: boolean = false;
    public enabledCheckbox: boolean = false;
    public disabledCheckbox: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<RegistrationProductListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RegistrationProductListData = { productsSelected: [], selectOne: false, },
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _productService: RegistrationProductService,
    ) {
        super(baseService, activatedRoute);
        this.basePermissionList = {
            MAKE: '',
            UPDATE: '',
            READ: '',
            DELETE: '',
            ENABLE_DISABLE: ''
        };
    }

    ngOnInit() {
        super.ngOnInit();
        this.productsSelected = [...this.data.productsSelected];
        this.selectOne = this.data.selectOne;

        this.columns = this.createTableColumns();

        this.indeterminateAllTypes = true;
        this.enabledCheckbox = true;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkboxAllTemplate)
                .template(this.checkboxTemplate)
                .width(7)
                .align('center center')
                .visible(!this.selectOne)
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .marginLeft(15)
                .sortable(true)
                .value((obj: RegistrationProductConsultDTO) => obj.description ? obj.description : '')
                .action((obj: RegistrationProductConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
            TableColumnBuilder.instance()
                .property('section')
                .name('Seção')
                .marginLeft(15)
                .sortable(true)
                .value((obj: RegistrationProductConsultDTO) => obj.section ? obj.section : '')
                .action((obj: RegistrationProductConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
            TableColumnBuilder.instance()
                .property('grouping')
                .name('Grupo')
                .marginLeft(15)
                .sortable(true)
                .value((obj: RegistrationProductConsultDTO) => obj.grouping ? obj.grouping : '')
                .action((obj: RegistrationProductConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
            TableColumnBuilder.instance()
                .property('retailPrice')
                .name('Preço de varejo')
                .width(13)
                .value((obj: RegistrationProductConsultDTO) => obj.retailPrice ? CurrencyUtil.transform(obj.retailPrice, '1.2-2') : '')
                .action((obj: RegistrationProductConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(13)
                .marginLeft(5)
                .marginRight(5)
                .template(this.statusTemplate)
                .action((obj: RegistrationProductConsultDTO) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
        ];
    }

    public close(isConfirm: boolean, productSelected?: RegistrationProductConsultDTO): void {
        try {
            if (isConfirm) {
                if (this.selectOne) {
                    this.dialogRef.close([productSelected]);
                } else {
                    this.dialogRef.close(this.productsSelected);
                }
            } else {
                this.dialogRef.close(null);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    public setTextStatus(entity: RegistrationProductConsultDTO): string {
        return entity.enabled ? 'Ativo' : 'Inativo';
    }

    public setColorStatus(entity: RegistrationProductConsultDTO): string {
        return entity.enabled ? '#02840f' : '#c24245';
    }

    public select(entity: RegistrationProductConsultDTO, validate: boolean): void {
        setTimeout(() => {
            try {
                if (entity.selected) {
                    this.productsSelected.push(entity);
                } else {
                    this.productsSelected.splice(this.productsSelected.findIndex(p => p.id === entity.id), 1);
                }

                if (validate) {
                    this._validateAllSelected();
                }
            } catch (e) {
                this.handleError(e);
            }
        });
    }

    public checkAll(): void {
        this.productPage.content.forEach((entity) => {
            entity.selected = this.selectedAll;
            this.select(entity, false);
        });
        this._validateAllSelected();
    }

    private _validateAllSelected(): void {
        const list: RegistrationProductConsultDTO[] = this.productPage.content.filter((entity) => entity.selected);
        this.selectedAll = list.length > 0;
        this.indeterminateAll = (list.length > 0) && (list.length !== this.productPage.content.length);
    }

    public checkAllStatus(): void {
        this.enabledCheckbox = false;
        this.disabledCheckbox = false;

        this.indeterminateAllTypes = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.enabledCheckbox &&
            this.disabledCheckbox
        ) {
            this.allTypesChecked = true;
            this.indeterminateAllTypes = false;
        } else if (
            this.enabledCheckbox ||
            this.disabledCheckbox
        ) {
            this.indeterminateAllTypes = true;
            this.allTypesChecked = false;
        } else {
            this.indeterminateAllTypes = false;
            this.allTypesChecked = false;
        }
    }

    public findAll(pageEvent?: PageEvent): void {
        try {
            this.loading = true;
            this.productPage = new GIPIPageModel<RegistrationProductConsultDTO>();

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
                this.filter.offset = pageEvent.pageIndex * pageEvent.pageSize;
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }

            if (this.allTypesChecked) {
                this.filter.enabled = null;
            } else if (this.enabledCheckbox) {
                this.filter.enabled = true;
            } else if (this.disabledCheckbox) {
                this.filter.enabled = false;
            }

            this.filter.productsIdsExcluded = [];
            if (!ArrayUtil.isEmpty(this.productsSelected)) {
                this.filter.productsIdsExcluded = this.productsSelected.map(p => p.id);
            }

            this._productService.findAll(this.filter).toPromise().then(page => {
                this.productPage = page;
                this.loading = false;
            }, (error) => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

}
