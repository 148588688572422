import { BaseModel } from '@gipi-shared/models/base.model';

export class RegistrationEmail extends BaseModel {

    email: string;

    useInInvoice: boolean;

    /** @Transient */
    key: number;

}
