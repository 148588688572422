<gipi-abstract-form id="dialogReceivementCard"
                    name="dialogReceivementCard"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogReceivementCard">
        Receber com cartão
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutAlign="start"
             fxLayoutGap="16px">
            <itss-input label="Tipo de cobrança"
                        [disabled]="true"
                        [value]="receivementCard?.chargeType?.description"
                        fxFlex="50"
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-select-entity-paged label="Administradora de cartão"
                                      [required]="true"
                                      [disabled]="loading"
                                      [property]="'description'"
                                      [(model)]="receivementCard.cardAdministrator"
                                      [nextBatchFn]="cardAdministratorFindByValueFn"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutAlign="start"
             fxLayoutGap="16px">
            <itss-select-entity-paged label="Bandeira do cartão"
                                      [required]="true"
                                      [disabled]="loading"
                                      [property]="'description'"
                                      [(model)]="receivementCard.flagCard"
                                      [nextBatchFn]="flagCardFindByValueFn"
                                      fxFlex="50"
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
            <itss-input label="Qtd. de parcelas"
                        *ngIf="receivementCard?.chargeType?.type === 'CREDIT_CARD'"
                        [required]="true"
                        [disabled]="loading"
                        [(ngModel)]="quantityInstallment"
                        [mask]="'0*'"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input-currency label="Valor"
                                 [required]="true"
                                 [disabled]="loading"
                                 [(ngModel)]="receivementCard.amountCard"
                                 [options]="optionAmountCard"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Valor restante"
                                 *ngIf="receivementCard?.chargeType?.type === 'CREDIT_CARD'"
                                 [disabled]="true"
                                 [value]="(amountRemaining$ | async)"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutAlign="end end"
             fxLayoutGap="16px">
            <gipi-button label="Adicionar"
                         gipi-primary
                         id="btnAddReceivementCard"
                         [disabled]="!(isValidAddReceivementCard$ | async) && loading"
                         (click)="addReceivementCard()"
                         fxFlex="20"
                         fxFlex.lt-md="100">
            </gipi-button>
        </div>
        <itss-table [columns]="columns"
                    [loading]="loading"
                    [paginator]="false"
                    [paginatorPageSize]="receivementCardList.length"
                    [backendPagination]="false"
                    [data]="receivementCardList">
        </itss-table>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="20"
                     (click)="close(false)"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #actions
             let-entity>
    <itss-button tooltip="Excluir"
                 svgIcon="trash"
                 color="warn"
                 type="icon"
                 [disabled]="loading"
                 (click)="removeReceivementCard(entity)">
    </itss-button>
</ng-template>
