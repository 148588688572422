<gipi-abstract-form id="dialogInstallment"
                    name="dialogInstallment"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="tollbarDialogInstallment">
        {{ editing ? 'Editar parcelas' : 'Parcelar Lançamento' }}
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         style="height: 100%;">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column"
             *ngIf="!editing">
            <itss-input-currency label="Valor"
                                 [placeholder]="'R$ 0,00'"
                                 [(ngModel)]="installment.amount"
                                 disabled
                                 required="true"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-select-enum label="Periodicidade"
                              [enum]="periodicityEnum"
                              [(ngModel)]="periodicity"
                              [disabled]="isLoad"
                              (selectionChange)="generate()"
                              required="true"
                              fxFlex
                              fxFlex.lt-md="100">
            </itss-select-enum>
            <itss-input label="Quantidade de dias"
                        [(ngModel)]="periodicityDay"
                        [disabled]="isLoad"
                        name="periodicityDay"
                        *ngIf="periodicity === 'FREE'"
                        (ngModelChange)="generate()"
                        maxlength="3"
                        mask="0*"
                        required="true"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-select-entity label="Parcelas"
                                [entities]="portionNumberList"
                                [(model)]="portionNumber"
                                [disabled]="isLoad"
                                required="true"
                                (selectionChange)="selectionChangePortionNumber($event)"
                                property="description"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
            <itss-select-entity label="Iniciar na parcela"
                                [entities]="portionStartList"
                                [(model)]="portionStart"
                                (selectionChange)="selectionChangePortionStart($event)"
                                required="true"
                                property="description"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column"
             *ngIf="editing">
            <itss-select-enum label="Filtrar"
                              [enum]="editTypeEnum"
                              [(ngModel)]="editType"
                              [disabled]="isLoad"
                              (selectionChange)="filterInstallmentList($event)"
                              required="true"
                              fxFlex
                              fxFlex.lt-md="100">
            </itss-select-enum>
            <itss-input-currency label="Novo valor"
                                 [placeholder]="'R$ 0,00'"
                                 [(ngModel)]="amount"
                                 [disabled]="isLoad || editType === 'PAID' || installmentFilteredList.length === 0"
                                 required="true"
                                 fxFlex
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <div style="align-items: center;"
                 fxFlex="15"
                 fxFlex.lt-md="100">
                <itss-button label="Atualizar"
                             color="accent"
                             (click)="update()"
                             [disabled]="isLoad || editType === 'PAID' || installmentFilteredList.length === 0"
                             style="height: 40px; padding-top: 4px;"
                             type="spinner"
                             fxFlex>
                </itss-button>
            </div>
        </div>
        <itss-table *ngIf="!editing && installmentList.length > 0"
                    [paginator]="true"
                    [backendPagination]="false"
                    [loading]="loading"
                    [columns]="columns"
                    [paginatorLength]="installmentList.length"
                    [paginatorPageSize]="5"
                    [data]="installmentList">
        </itss-table>
        <itss-table *ngIf="editing"
                    [paginator]="true"
                    [backendPagination]="false"
                    [loading]="loading"
                    [columns]="columns"
                    [paginatorLength]="installmentFilteredList.length"
                    [paginatorPageSize]="5"
                    [data]="installmentFilteredList">
            <ng-template #actions
                         let-entity
                         *ngIf="editing">
                <itss-button svgIcon="trash"
                             color="accent"
                             type="icon"
                             tooltip="Excluir"
                             [disabled]="isLoad"
                             *ngIf="entity.status === 'PAYABLE' || entity.status === 'PAYABLE_OVERDUE' || entity.status === 'RECEIVABLE' || entity.status === 'RECEIVABLE_OVERDUE' && !entity.billRenegotiated"
                             (click)="delete(false, entity)">
                </itss-button>
            </ng-template>
        </itss-table>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Excluir"
                     gipi-primary
                     id="btnDelete"
                     [disabled]="isLoad || (editing && (editType === 'PAID' ||installmentFilteredList.length === 0))"
                     *ngIf="editing"
                     (click)="delete(true)"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="isLoad || (editing && editType === 'PAID')"
                     [loading]="loading"
                     (click)="save()"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     [disabled]="isGeneratingBillet"
                     (click)="close(false)"
                     fxFlex="15"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #documentNumberTemplate
             let-entity>
    <itss-input [(ngModel)]="entity.documentNumber"
                [disabled]="entity.status === 'PAID' || entity.status === 'RECEIVED' || entity.status === 'GROUPED' || entity.status === 'RENEGOTIATED' || entity.status === 'RECEIVED_PARTIAL' || entity.bill.type === 'RECEIVABLE' "
                fxFlex="90">
    </itss-input>
</ng-template>

<ng-template #dueDateTemplate
             let-entity>
    <gipi-datepicker [(ngModel)]="entity.dueDate"
                     [disabled]="entity.status === 'PAID' || entity.status === 'RECEIVED' || entity.status === 'GROUPED' || entity.status === 'RENEGOTIATED' || entity.status === 'RECEIVED_PARTIAL'"
                     fxFlex="90">
    </gipi-datepicker>
</ng-template>

<ng-template #amountTemplate
             let-entity>
    <itss-input-currency [(ngModel)]="(entity.status === 'GROUPED' || entity.status === 'RENEGOTIATED') ? 0 : entity.amount"
                         [disabled]="entity.status === 'PAID' || entity.status === 'RECEIVED' || entity.status === 'GROUPED' || entity.status === 'RENEGOTIATED' || entity.status === 'RECEIVED_PARTIAL'"
                         [options]="editing ? { min: 0 } : { min: 0, max: installment.amount  }"
                         (keyup)="recalcAmounts(entity)"
                         fxFlex="90">
    </itss-input-currency>
</ng-template>

<ng-template #statusTemplate
             let-entity>
    <itss-icon class="icon-table"
               icon="fiber_manual_record"
               [color]="setColorStatus(entity)"
               [tooltip]="setHintStatus(entity)">
    </itss-icon>
</ng-template>
