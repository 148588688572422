<gipi-abstract-form id="dialogRegisterIntermediary"
                    name="dialogRegisterIntermediary"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterIntermediary">
        Cadastrar intermediador
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <itss-input label="CNPJ"
                        id="intermediaryCnpj"
                        name="intermediaryCnpj"
                        mask="00.000.000/0000-00"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="intermediary.cnpj"
                        fxFlex
                        fxFlex.lt-sm="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="intermediaryEnabled"
                                    name="intermediaryEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex
                                    fxFlex.lt-sm="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-input label="Nome"
                        id="intermediaryName"
                        name="intermediaryName"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="intermediary.name"
                        [min]="3"
                        [max]="60"
                        [maxlength]="60"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Nome do usuário"
                        id="intermediaryUsername"
                        name="intermediaryUsername"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="intermediary.username"
                        [min]="3"
                        [max]="60"
                        [maxlength]="60"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
