<gipi-abstract-form id="dialogActionsCheckReceived"
                    name="dialogActionsCheckReceived"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogActionsCheckReceived">
        {{ setTitle() }}
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlexFill>
            <gipi-datepicker [label]="setLabelDate()"
                             [(ngModel)]="moveDate"
                             [disabled]="loading"
                             required="true"
                             [fxFlex]="action === 'COMPENSATE_DEPOSIT_CHECK' ? '100' : '30'"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-select-entity-paged label="Conta bancária"
                                      required="true"
                                      property="description"
                                      *ngIf="action !== 'COMPENSATE_DEPOSIT_CHECK'"
                                      [(model)]="bankAccount"
                                      [nextBatchFn]="bankAccountFindByTypeFn"
                                      [disabled]="loading"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         [fxLayoutAlign]="action === 'COMPENSATE_DEPOSIT_CHECK' ? 'center end' : 'end end'"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [loading]="loading"
                     [disabled]="loading"
                     (click)="confirm()"
                     [fxFlex]="action === 'COMPENSATE_DEPOSIT_CHECK' ? '40' : '32'"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     type="raised"
                     [fxFlex]="action === 'COMPENSATE_DEPOSIT_CHECK' ? '40' : '32'"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
