import { of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, StringUtil, UUIDUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class RegistrationConnectConsultService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(RegistrationPaths.connectConsult, 'v1', baseService);
        this.baseService = baseService;
    }
    summaryByClientId(consultType, documentType, clientId, version) {
        if (StringUtil.isEmpty(consultType) ||
            StringUtil.isEmpty(documentType) ||
            (!StringUtil.isEmpty(documentType) && (documentType === 'FOREIGN_PERSON')) ||
            !UUIDUtil.isValid(clientId)) {
            return of(null);
        }
        return this.baseService.httpClient.get(this.url(`summary-by-client-id/${consultType}/${documentType}/${clientId}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    summaryByCpfOrCnpj(consultType, documentType, cpfOrCnpj, version) {
        if (StringUtil.isEmpty(consultType) ||
            StringUtil.isEmpty(documentType) ||
            (!StringUtil.isEmpty(documentType) && (documentType === 'FOREIGN_PERSON')) ||
            StringUtil.isEmpty(cpfOrCnpj)) {
            return of(null);
        }
        return this.baseService.httpClient.get(this.url(`summary-by-cpf-or-cnpj/${consultType}/${documentType}/${cpfOrCnpj}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    recoverConsultPdfById(idConsult, version) {
        return this.baseService.httpClient.get(this.url(`recover-consult-pdf-by-id/${idConsult}`, version), { observe: 'response', responseType: 'blob' }).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
RegistrationConnectConsultService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationConnectConsultService_Factory() { return new RegistrationConnectConsultService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: RegistrationConnectConsultService, providedIn: "root" });
