import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUserInformationDTO } from '@gipi-financial/user/models/dto/user-information.dto';
import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { OAuthFileService } from '@gipi-oauth/file/services/file.service';
import { AbstractComponent, AuthenticationService, EmailUtil, MessageService, ObjectUtil, PasswordUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    selector: 'app-user-information-dialog',
    templateUrl: './user-information-dialog.component.html',
    styleUrls: ['./user-information-dialog.component.scss']
})
export class UserInformationDialogComponent extends AbstractComponent implements OnInit {

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

    user: OAuthUser;

    extensions: string[] = ['.png', '.jpg', '.jpeg'];

    maximumSize: number = 3;

    newUsername: string;

    confirmNewUsername: string;

    newPassword: string;

    isViewNewPassword: boolean = false;

    newPasswordConfirm: string;

    isViewNewPasswordConfirm: boolean = false;

    validPassword: string;

    isViewValidPassword: boolean = false;

    editUsername: boolean = false;

    editPassword: boolean = false;

    existEmail: boolean = false;

    isLoadFile: boolean = false;

    userPhotoSrc: string = '';

    passwordFocused: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<UserInformationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OAuthUser,
        private _userService: OAuthUserService,
        private _authenticationService: AuthenticationService<OAuthUser>,
        private _fileService: OAuthFileService,
        messageService: MessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        this.dialogRef.disableClose = true;
        this.user = this.data;
        this.user.name = this.user.name.toUpperCase();
        this.user.deletedPhoto = false;
        await this.getUserPhoto();
    }

    async save(): Promise<void> {
        try {
            if (this._validate()) {
                this.loading = true;
                await this._userService.confirmPassword(Number(this.user.id), this.validPassword).toPromise().then(async isMatch => {
                    if (isMatch) {
                        if (this.user.deletedPhoto) {
                            await this.deletePhoto();
                        }

                        if (!ObjectUtil.isNull(this.user.filePhoto)) {
                            await this.uploadPhoto();
                        }

                        const userInformationDto = this.userToUserInformationDTO(this.user);
                        userInformationDto.password = this.editPassword ? this.newPassword : this.user.password;

                        await this._userService.updateInformationOfUser(userInformationDto).toPromise().then(user => {
                            this._authenticationService.logout();
                            this.router.navigate(['/oauth/login']);
                            this.dialogRef.close(null);
                        }, error => {
                            this.loading = false;
                            this.handleError(error);
                        });

                    } else {
                        throw new Error('Senha inválida');
                    }
                }, error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private userToUserInformationDTO(user: OAuthUser): OAuthUserInformationDTO {
        const userInformationDto = new OAuthUserInformationDTO();
        userInformationDto.id = user.id;
        userInformationDto.name = user.name;
        userInformationDto.password = user.password;
        userInformationDto.photo = user.photo;
        return userInformationDto;
    }

    async close(): Promise<void> {
        this.dialogRef.close(null);
    }

    private _validate(): boolean {
        if (this.editUsername) {
            if ((StringUtil.isEmpty(this.newUsername) || StringUtil.isEmpty(this.confirmNewUsername))) {
                this.addWarningMessage('Campos obrigatórios (*) não informados');
                return false;
            }
            if (!EmailUtil.isValid(this.newUsername.toLowerCase())) {
                this.addWarningMessage('E-mail inválido');
                return false;
            }
            if (this.newUsername.toUpperCase() !== this.confirmNewUsername.toUpperCase()) {
                this.addWarningMessage('O novo email e a confirmação do novo email devem ser iguais');
                return false;
            }
        }

        if (this.editPassword && !this._validatePassword()) {
            return false;
        }
        if (StringUtil.isEmpty(this.validPassword)) {
            this.addWarningMessage('Para alterar suas informações informe a senha atual para realizar a confirmação');
            return false;
        }
        if (this.existEmail) {
            this.addWarningMessage('Este e-mail já está sendo usado por outro usuário');
            return false;
        }

        return true;
    }

    private _validatePassword(): boolean {
        if (StringUtil.isEmpty(this.newPassword)) {
            this.addWarningMessage('Campo "Senha" é obrigatório e não foi informado');
            return false;
        } else if (StringUtil.isEmpty(this.newPasswordConfirm)) {
            this.addWarningMessage('Campo "Confirmação senha" é obrigatório e não foi informado');
            return false;
        } else if (!PasswordUtil.isValidMinLength(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 6 caracteres');
            return false;
        } else if (!PasswordUtil.isValidMaxLength(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no máximo 15 caracteres');
            return false;
        } else if (!PasswordUtil.hasUppercase(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 1 caractere maiúsculo');
            return false;
        } else if (!PasswordUtil.hasLowercase(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 1 caractere minúsculo');
            return false;
        } else if (!PasswordUtil.hasNumber(this.newPassword)) {
            this.addWarningMessage('A senha deve conter no mínimo 1 número');
            return false;
        } else if (PasswordUtil.hasBackspace(this.newPassword)) {
            this.addWarningMessage('A senha não deve conter espaços');
            return false;
        } else if (this.newPassword !== this.newPasswordConfirm) {
            this.addWarningMessage('A senha e a confirmação da senha devem ser iguais');
            return false;
        }

        return true;
    }

    changeEditUsername(): void {
        this.editUsername = !this.editUsername;
        if (!this.editUsername) {
            this.newUsername = '';
            this.confirmNewUsername = '';
        }
    }

    changeEditPassword(): void {
        this.editPassword = !this.editPassword;
        if (!this.editPassword) {
            this.newPassword = '';
            this.newPasswordConfirm = '';
        }
    }

    async validEmail(email: string): Promise<void> {
        try {
            if (!StringUtil.isEmpty(email)) {
                await this._userService.getByUserName(email.toLowerCase()).toPromise().then(saved => {
                    this.existEmail = !ObjectUtil.isNull(saved.id);
                }, error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    loadFile(): void {
        const htmlInputElement: HTMLInputElement = this.fileInput.nativeElement;
        htmlInputElement.onchange = async () => {
            const file: File = htmlInputElement.files[0];
            if (!ObjectUtil.isNull(file) && this.validateExtensions(file) && this.validateMaximumSize(file)) {
                this.user.filePhoto = file;
                this.user.deletedPhoto = false;
                const fileReader: FileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onloadend = () => {
                    (this.userPhotoSrc = fileReader.result as string);
                };
            }
        };
        htmlInputElement.click();
    }

    async deleteFile(): Promise<void> {
        try {
            setTimeout(() => {
                this.user.deletedPhoto = true;
                this.fileInput.nativeElement.value = '';
                this.userPhotoSrc = '';
            });
        } catch (e) {
            this.fileInput.nativeElement.value = '';
            this.loading = false;
            this.handleError(e);
        }
    }

    private validateExtensions(file: File): boolean {
        if (this.extensions.filter(extension => file.name.endsWith(extension)).length === 0) {
            this.addErrorMessage(`São permitidos somente arquivos do tipo: ${this.extensions.reduce((previous, current) => previous + ', ' + current)}`);
            return false;
        }
        return true;
    }

    private validateMaximumSize(file: File): boolean {
        if (file.size > (this.maximumSize * 1000000)) {
            this.addErrorMessage(`O arquivo deve ter no máxmio ${this.maximumSize} MB.`);
            return false;
        }
        return true;
    }

    async deletePhoto(): Promise<void> {
        try {
            await this._fileService.delete(this.user.photo).toPromise().then(() => {
                this.user.deletedPhoto = true;
                this.user.photo = null;
                this.fileInput.nativeElement.value = '';
                this.userPhotoSrc = '';
            }, () => {
                throw new Error('Não foi possível excluir a foto');
            });
        } catch (e) {
            this.fileInput.nativeElement.value = '';
            this.loading = false;
            this.handleError(e);
        }
    }

    async uploadPhoto(): Promise<void> {
        try {
            await this._fileService.upload('users', this.user.filePhoto, 'Private').toPromise().then((url: string) => {
                this.user.photo = url;
            }, () => {
                throw new Error('Não foi possível alterar a foto');
            });
        } catch (e) {
            this.fileInput.nativeElement.value = '';
            this.loading = false;
            this.handleError(e);
        }
    }

    async getUserPhoto(): Promise<void> {
        try {
            if (!ObjectUtil.isNewModel(this.user) && !StringUtil.isEmpty(this.user.photo)) {
                this.isLoadFile = true;
                await this._fileService.download(this.user.photo).toPromise().then(resp => {
                    const fileReader: FileReader = new FileReader();
                    fileReader.readAsDataURL(resp.body);
                    fileReader.onloadend = () => this.userPhotoSrc = fileReader.result as string;
                    this.isLoadFile = false;
                }, error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.isLoadFile = false;
            this.handleError(e);
        }
    }

}
