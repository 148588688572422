import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationApplication } from '@gipi-registration/application/models/application.model';
import { RegistrationApplicationService } from '@gipi-registration/application/services/application.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface ApplicationData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    application: RegistrationApplication;
    returnObject?: boolean;
}

@Component({
    templateUrl: './application-form-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class ApplicationFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public application: RegistrationApplication = new RegistrationApplication();

    public returnObject: boolean = false;

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    constructor(
        protected service: RegistrationApplicationService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<ApplicationFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ApplicationData = { typeOperation: 'NEW', application: null, returnObject: false, },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.returnObject = this.data.returnObject;
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.application = this.data.application;

            if (!this.application.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.application)) {
                return;
            }
            if (StringUtil.isEmpty(this.application.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.application.description.length < 5) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 5 caracteres');
                return;
            }
            this.loading = true;

            this.application.enabled = (this.optionActiveSituationValue === 'ENABLED');

            this.service.save(this.application).toPromise().then((responseEntity) => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE', responseEntity);
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     *
     * @var object Retorna o objeto que acabou de ser criado.
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE', object?: RegistrationApplication): void {
        if (this.returnObject && !ObjectUtil.isNewModel(object)) {
            this.dialogRef.close(object);
        } else {
            this.dialogRef.close(operation);
        }
    }

}
