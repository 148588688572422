<gipi-abstract-form id="dialogReceivementDeposit"
                    name="dialogReceivementDeposit"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogReceivementDeposit">
        Receber via depósito
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         (keydown.enter)="confirm()"
         style="overflow: hidden !important;">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-select-entity label="Conta bancária"
                                [entities]="bankAccountList"
                                [(model)]="receivement.bankAccount"
                                [disabled]="data.isCashier"
                                property="description"
                                required="true"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-textarea label="Histórico"
                           [(ngModel)]="receivement.historic"
                           [rows]="4"
                           fxFlex
                           fxFlex.lt-md="100">
            </itss-textarea>
        </div>
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutAlign="end center"
             fxLayoutAlign.lt-md="start">
            <input #fileInput
                   [accept]="extensions.join(',')"
                   type="file"
                   style="display:none;"
                   [disabled]="isLoad || isLoadUploadAttachment || isLoadDeleteAttachment || isLoadDeleteAttachment">
            <div class="input-file">
                <div
                     (click)="isLoadUploadAttachment && isLoadDeleteAttachment && isLoadViewAttachment ? null : (receivement.attachment || receivement.fileAttachment) ? deleteFile() : loadFile()">
                    <itss-icon *ngIf="!isLoadUploadAttachment && !isLoadDeleteAttachment && !isLoadViewAttachment"
                               [svgIcon]="(receivement.attachment || receivement.fileAttachment) ? 'trash' : 'attach'"
                               [matTooltip]="(receivement.attachment || receivement.fileAttachment) ? 'Remover arquivo' : 'Anexar arquivo'">
                    </itss-icon>

                    <div *ngIf="isLoadUploadAttachment || isLoadDeleteAttachment || isLoadViewAttachment"
                         class="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <span *ngIf="!isLoad && !isLoadUploadAttachment && !isLoadDeleteAttachment && !isLoadViewAttachment"
                      (click)="(receivement.attachment || receivement.fileAttachment) && viewAttachment()"
                      [ngStyle]="{'cursor': (receivement.attachment || receivement.fileAttachment) ? 'pointer' : 'default'}"
                      [matTooltip]="(receivement.attachment || receivement.fileAttachment) && 'Clique para visualizar o arquivo'">
                    {{ (receivement.attachment || receivement.fileAttachment) ? attachmentName : 'Anexar arquivo' }}
                </span>
                <span *ngIf="isLoadUploadAttachment || isLoadDeleteAttachment || isLoadViewAttachment"
                      [style.color]="'#f3465c'">
                    {{ (isLoadDeleteAttachment)
                    ? 'Aguarde, removendo arquivo'
                    : (isLoadUploadAttachment) ? 'Aguarde, anexando arquivo' : 'Aguarde, processando arquivo' }}
                </span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     fxFlex="30"
                     (click)="close(false)"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
