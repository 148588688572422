import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialCashierShiftService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.cashierShift, http, authenticationService);
    }
    getOpenedByCurrentUser() {
        return this.http.get(this.url('opened'), this.options()).pipe(map(this.mapper), catchError(this.handleError));
    }
    existCashierBankAccountByChargeType(cashierShift, typeChargeType) {
        return new Promise(resolve => {
            let hasBankAccountByChargeType = false;
            let typeChargeTypeAux = typeChargeType;
            if ((typeChargeType !== 'CREDIT_CARD') && (typeChargeType !== 'DEBIT_CARD') && (typeChargeType !== 'CHECK')) {
                if ((typeChargeType !== 'PIX') && (typeChargeType !== 'DEPOSIT') && (typeChargeType !== 'TRANSFER')) {
                    typeChargeTypeAux = 'OTHER';
                }
                hasBankAccountByChargeType = cashierShift.cashier.cashierBankAccountList.filter(e => e.chargeType.type === typeChargeTypeAux).length > 0;
            }
            resolve(hasBankAccountByChargeType);
        });
    }
}
FinancialCashierShiftService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCashierShiftService_Factory() { return new FinancialCashierShiftService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialCashierShiftService, providedIn: "root" });
