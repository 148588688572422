export enum FinancialReceivementCardTypeEnum {
    RECEIVABLE = 'A receber',
    RECEIVED = 'Recebido',
    OVERDUE = 'Atrasado'
}

export type FinancialReceivementCardType = (
    'RECEIVABLE' |
    'RECEIVED' |
    'OVERDUE'
);
