import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class NotificationCustomerNotificationConfig extends GIPIBaseAbstractModel {

    /** @Required Indica se a notificação de conta a pagar está habilitada */
    accountPayableNotificationEnabled: boolean;

    /** @Required Indica se a notificação de conta a receber está habilitada */
    accountReceivableNotificationEnabled: boolean;

    /** @Required Indica se a notificação da chave de pagamento do boleto está ativada */
    billetKeyNotificationEnabled: boolean;

    /** @Required Indica se a notificação de lembrete de boleto está habilitada */
    billetReminderNotificationEnabled: boolean;

    /** @Required Indica se a notificação de aniversário está habilitada */
    birthdayNotificationEnabled: boolean;

    /** @Required Cnpj do cliente */
    cnpj: string;

    /** @Required E-mail do cliente */
    emailCustomer: string;

    /** @Required Número de telefone */
    phoneNumber: string;

    /** @Required Indica se a notificação de cliente potenciais está habilitada */
    potentialClientNotificationEnabled: boolean;

    /** @Required Indica se a notificação de promoções e sugestões está habilitada */
    promotionsAndSuggestionsNotificationEnabled: boolean;

}
