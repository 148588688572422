import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class ConfigurationScanntechUrl extends GIPIBaseAbstractModel {

    /** @Required Indica se é a url padrão */
    defaultURL: boolean;

    /** @Required URL base para acessar a API Scanntech */
    url: string;

    /** @Transient */
    key: number;

}
