import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialCashierShift } from '@gipi-financial/cashier-shift/models/cashier-shift.model';
import { FinancialChargeType } from '@gipi-financial/charge-type/models/charge-type.model';
import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { FinancialEmployee } from '@gipi-financial/employe/models/employee.model';
import { FinancialPayment } from '@gipi-financial/payment/models/payment.model';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialAutomaticDecrease } from '../enums/automatic-decrease.enum';
import { FinancialBillStatus } from '../enums/bill-status.enum';
import { FinancialBillAssessment } from './bill-accessment.model';
import { FinancialBill } from './bill.model';

export class FinancialBillInstallment extends GIPIBaseAbstractModel {

    bill: FinancialBill;

    status: FinancialBillStatus;

    portion: number;

    description: string;

    bankAccount: FinancialBankAccount;

    postingCategory: FinancialPostingCategory;

    paymentDate: Date;

    chargeType: FinancialChargeType;

    documentNumber: string;

    issuanceDate: Date;

    dueDate: Date;

    dischargeDate: Date;

    competency: Date;

    amount: number;

    dischargeAmount: number;

    attachment: string;

    /** @Transient */
    fileAttachment: File;

    billGrouped: boolean;

    billRenegotiated: boolean;

    conciled: boolean = false;

    saleBillInstallmentId: GIPIUuid;

    // Used when receivable
    seller: FinancialEmployee;

    comments: string;

    automaticDecrease: FinancialAutomaticDecrease = 'false';

    // Used when receivable and status == GROUP or RENEGOTIATE
    groupedBillInstallmentId: GIPIUuid;

    // Used when receivable
    originalAmount: number;

    // Used when receivable and status == GROUP or RENEGOTIATE
    userId: GIPIUuid;

    // Used when partial receivement is made
    partialInstallmentRef: number;

    // Used when payable
    assessmentList: FinancialBillAssessment[];

    shift: FinancialCashierShift;

    billetId: GIPIUuid;

    fineCharged: boolean;

    // Used when bill conciliation
    createdByConciliation: boolean;

    // Used when notification closing month
    createdByIntegrationInvoice: boolean;

    ourNumber: number;

    postingCategoryForAddition: FinancialPostingCategory;

    /** @Transient */
    fine?: number;

    /** @Transient */
    interest?: number;

    /** @Transient */
    discount?: number;

    /** @Transient */
    addition?: number;

    /** @Transient */
    amountPaid?: number;

    /** @Transient */
    company?: FinancialCompany;

    /** @Transient */
    payment?: FinancialPayment;

    /** @Transient Used when bill conciliation */
    createdByDifferenceConciliation: boolean;

    /** @Transient Used when bill conciliation */
    chargeTypeReceivement: FinancialChargeType;

    /** @Transient Used when bill conciliation */
    transactionConciliationId: GIPIUuid;

    /** @Transient */
    orderId: number;

    constructor() {
        super();
        this.portion = 1;
        this.issuanceDate = new Date();
        this.assessmentList = [];
    }

}
