import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { FinancialReasonRefund } from '@gipi-financial/reason-refund/models/reason-refund.model';
import { ReasonRefundService } from '@gipi-financial/reason-refund/services/reason-refund.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, ArrayUtil, DateUtil, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';

export interface RefundDialogData {
    entityList: FinancialCheckReceived[],
    action: string
};

@Component({
    templateUrl: './refund-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class RefundDialogComponent extends AbstractComponent implements OnInit {

    checkReceivedList: FinancialCheckReceived[] = [];

    reasonRefund: FinancialReasonRefund;

    moveDate: Date = new Date();

    action: string;

    reasonRefundFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialReasonRefund> = await this._reasonRefundService.findByValue(value, page, 10).toPromise();
        if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
            this.reasonRefund = result.content[0];
        }
        return result;
    };

    constructor(
        public dialogRef: MatDialogRef<RefundDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RefundDialogData,
        private _checkReceivedService: FinancialCheckReceivedService,
        private _reasonRefundService: ReasonRefundService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.checkReceivedList = Object.keys(this.data.entityList).map(key => this.data.entityList[key]);
        this.action = this.data.action.toUpperCase();
    }

    private validateMovement(): void {
        if (!DateUtil.isValid(this.moveDate)) {
            throw new Error('Campo data devolução foi informado incorretamente');
        }
        if (ObjectUtil.isNewModel(this.reasonRefund)) {
            throw new Error('Campo motivo da devolução é obrigatório e não informado');
        }
    }

    async confirm(): Promise<void> {
        try {
            this.validateMovement();
            this.loading = true;

            this.checkReceivedList.forEach(checkReceived => {
                checkReceived.check.moveDate = this.moveDate;
                checkReceived.check.reasonRefund = this.reasonRefund;
                if (checkReceived.check.status === 'DEPOSITED') {
                    checkReceived.check.status = 'REFUND1';
                } else {
                    checkReceived.check.status = 'REFUND2';
                }
            });

            await this._checkReceivedService.moveCheckReceived(this.checkReceivedList, this.action).toPromise().then(() => {
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
