<gipi-abstract-form id="dialogIntegrations"
                    name="dialogIntegrations"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogIntegrations">
        Integração da Scanntech
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayoutGap="16px"
                 fxFlex>
                <gipi-input-select-enum label="Situação"
                                        id="panelScanntechEnabled"
                                        name="panelScanntechEnabled"
                                        [required]="true"
                                        [disabled]="loading || (panelScanntechIsNewRecord | async)"
                                        [valuesExclud]="[]"
                                        [enum]="panelScanntechOptionActiveSituationEnum"
                                        [(ngModel)]="panelScanntechOptionActiveSituationValue"
                                        fxFlex
                                        fxFlex.lt-md="100">
                </gipi-input-select-enum>
                <itss-input label="Código da empresa"
                            id="panelScanntechCompanyCode"
                            name="panelScanntechCompanyCode"
                            tooltip="Código da empresa (companyCode) fornecido pela Scanntech."
                            [required]="true"
                            [disabled]="loading"
                            [(ngModel)]="scanntechConfig.companyCode"
                            [mask]="'0*'"
                            [min]="1"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <itss-input label="Código da loja"
                            id="panelScanntechStoreCode"
                            name="panelScanntechStoreCode"
                            tooltip="O código da loja representa o número da loja. Caso tenha mais de uma loja, use os números correspondentes, como 1, 2, 3, etc."
                            [required]="true"
                            [disabled]="loading"
                            [(ngModel)]="scanntechConfig.storeCode"
                            [mask]="'0*'"
                            [min]="1"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
            </div>

            <div fxLayout="row"
                 fxLayoutGap="16px"
                 fxFlex>
                <itss-input label="Intervalo de sincronização"
                            id="panelScanntechIntervalBetweenSyncs"
                            name="panelScanntechIntervalBetweenSyncs"
                            tooltip="Intervalo de tempo em minutos entre cada sincronização."
                            [required]="true"
                            [disabled]="loading"
                            [(ngModel)]="scanntechConfig.intervalBetweenSyncs"
                            [mask]="'0*'"
                            [min]="1"
                            [maxlength]="7"
                            fxFlex
                            fxFlex.lt-md="100">
                    <ng-template #suffix>
                        <div class="scanntech-suffix-time">
                            Min
                        </div>
                    </ng-template>
                </itss-input>
                <itss-input label="Qtd vendas por lote"
                            id="panelScanntechSalesQuantityPerLot"
                            name="panelScanntechSalesQuantityPerLot"
                            tooltip="Quantidade de vendas enviadas em cada sincronização."
                            placeholder="Ex: 1"
                            [required]="true"
                            [disabled]="loading"
                            [(ngModel)]="scanntechConfig.salesQuantityPerLot"
                            [mask]="'0*'"
                            [min]="1"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <itss-input label="Horário envio fechamento"
                            id="panelScanntechTimeForSendingPendingClosings"
                            name="panelScanntechTimeForSendingPendingClosings"
                            tooltip="Horário para realizar o envio do fechamento para Scanntech."
                            placeholder="00:00"
                            [required]="true"
                            [disabled]="loading"
                            [(ngModel)]="timeForSendingPendingClosings"
                            [mask]="'00:00'"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <gipi-datepicker label="Data inicio da sincronização"
                                 id="panelScanntechDataSendingStartDate"
                                 name="panelScanntechDataSendingStartDate"
                                 [disabled]="loading"
                                 [(ngModel)]="scanntechConfig.dataSendingStartDate"
                                 fxFlex
                                 fxFlex.lt-md="100">
                </gipi-datepicker>
            </div>

            <div id="panelScanntechInfoAuth"
                 class="content-information">
                <label for="contentInformation">
                    Informações de autenticação
                </label>
                <div id="contentInformation"
                     fxLayout="row"
                     fxLayout.lt-md="column"
                     fxLayoutGap="16px">
                    <itss-input label="Usuário"
                                id="panelScanntechUserName"
                                name="panelScanntechUserName"
                                [required]="true"
                                [disabled]="loading"
                                [(ngModel)]="scanntechConfig.userName"
                                [max]="254"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                    <itss-input label="Senha"
                                #panelScanntechInputPassword
                                id="panelScanntechPassword"
                                name="panelScanntechPassword"
                                [autocomplete]="'new-password'"
                                [type]="panelScanntechBtnViewPassword ? 'text' : 'password'"
                                [required]="true"
                                [disabled]="loading"
                                [(ngModel)]="scanntechConfig.password"
                                [max]="254"
                                fxFlex
                                fxFlex.lt-md="100">
                        <ng-template #suffix>
                            <gipi-button [tooltip]="panelScanntechBtnViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                         gipi-icon
                                         id="panelScanntechBtnViewPassword"
                                         name="panelScanntechBtnViewPassword"
                                         [disabled]="loading"
                                         [forInput]="true"
                                         [svgIcon]="panelScanntechBtnViewPassword ? 'eye' : 'eye_off'"
                                         (click)="panelScanntechBtnViewPassword = !panelScanntechBtnViewPassword">
                            </gipi-button>
                        </ng-template>
                    </itss-input>
                </div>
            </div>

            <gipi-expansion-panel panelTitle="URLs"
                                  id="scanntechPanelUrl"
                                  name="scanntechPanelUrl"
                                  svgIcon="scanntech_url"
                                  [iconSize]="24"
                                  [disabled]="loading"
                                  fxFlex>
                <div fxLayout="column"
                     fxFlex
                     fxLayoutGap="16px">
                    <ng-container *ngFor="let url of scanntechConfig?.urlList; let i = index">
                        <div fxLayout="column"
                             fxFlex
                             fxLayoutGap="16px"
                             class="group-panel-register"
                             *ngIf="scanntechConfig?.urlList && (scanntechConfig?.urlList.length > 0) && !loading"
                             [attr.id]="'groupUrl' + i">

                            <gipi-button tooltip="Excluir"
                                         id="buttonScanntechDeleteUrl"
                                         name="buttonScanntechDeleteUrl"
                                         gipi-icon
                                         class="btn-delete-group"
                                         icon="close"
                                         *ngIf="!loading"
                                         (click)="scanntechDeleteUrl(url)">
                            </gipi-button>

                            <div fxLayout="row"
                                 fxLayoutAlign="start"
                                 fxLayoutGap="16px"
                                 fxLayout.lt-sm="column"
                                 fxLayout.lt-sm="column">
                                <itss-input label="URL"
                                            id="inputScanntechUrl"
                                            name="inputScanntechUrl"
                                            [placeholder]="'https://exemplo.com'"
                                            [required]="true"
                                            [disabled]="loading"
                                            [(ngModel)]="url.url"
                                            fxFlex
                                            fxFlex.lt-sm="100">
                                </itss-input>
                                <itss-checkbox label="URL padrão"
                                               id="scanntechDefaultUrl"
                                               name="scanntechDefaultUrl"
                                               class="checkbox-center"
                                               color="primary"
                                               [disabled]="loading"
                                               [ngModel]="url.defaultURL"
                                               (change)="scanntechValidateDefaultUrl(url)"
                                               fxFlex="20"
                                               fxFlex.lt-sm="30">
                                </itss-checkbox>
                            </div>
                        </div>
                    </ng-container>

                    <div class="group-panel-register new-group-panel-register"
                         id="buttonScanntechNewUrl"
                         id="buttonScanntechNewUrl"
                         *ngIf="!loading && (scanntechConfig?.urlList.length < 3)"
                         (click)="scanntechAddNewUrl()">
                        <label> + Adicionar URL </label>
                    </div>
                </div>
            </gipi-expansion-panel>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnIntegrationsConfirm"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnIntegrationsReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
    </div>
</gipi-abstract-form>