export enum SaleTaxInvoiceStatusEnum {
    NOT_TRANSMITTED = 'Não transmitida',
    AUTHORIZED = 'Autorizada',
    CANCELED = 'Cancelada',
    UNUSABLE = 'Inutilizada',
    CONTINGENCY = 'Contingência',
    NOT_GENERATED = 'Não gerada',
}

export type SaleTaxInvoiceStatus = 'NOT_TRANSMITTED' | 'AUTHORIZED' | 'CANCELED' | 'UNUSABLE' | 'CONTINGENCY' | 'NOT_GENERATED';
