<gipi-abstract-form id="dialogRegisterUnityMeasurement"
                    name="dialogRegisterUnityMeasurement"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterUnityMeasurement">
        Cadastrar unidade de medida
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <itss-input label="Descrição"
                        id="unitMeasurementDescription"
                        name="unitMeasurementDescription"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="unitMeasurement.description"
                        [min]="2"
                        [max]="30"
                        [maxlength]="30"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="unitMeasurementEnabled"
                                    name="unitMeasurementEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex="30"
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <itss-input label="Iniciais"
                        id="unitMeasurementInitials"
                        name="unitMeasurementInitials"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="unitMeasurement.initials"
                        [min]="1"
                        [max]="6"
                        [maxlength]="6"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Decimais"
                        id="unitMeasurementDecimals"
                        name="unitMeasurementDecimals"
                        mask="0*"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="unitMeasurement.decimals"
                        [min]="0"
                        [maxlength]="3"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
