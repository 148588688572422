import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, DateUtil, GIPIAbstractCrudService, GIPIAbstractFilterModel, GIPIBaseService, GIPIPageModel, ObjectUtil } from '@gipisistemas/ng-core';
import { FinancialReceivementCardFilterDTO } from '../models/dto/receivement-card-filter.dto';
import { FinancialReceivementCardAdministrator } from '../models/receivement-card-administrator.model';
import { FinancialReceivementCard } from '../models/receivement-card.model';

@Injectable({ providedIn: 'root' })
export class FinancialReceivementCardService extends GIPIAbstractCrudService<FinancialReceivementCard, FinancialReceivementCardFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.receivementCard, 'v1', baseService);
    }

    findByIds(idList: number[], version?: string): Observable<FinancialReceivementCard[]> {
        return this.baseService.httpClient.post(this.url('find-by-ids', version), idList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByBillInstallment(id: number, version?: string): Observable<FinancialReceivementCard[]> {
        return this.baseService.httpClient.get(this.url(`find-by-installment/${id}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    cancel(entityList: FinancialReceivementCard[], version?: string): Observable<FinancialReceivementCard[]> {
        return this.baseService.httpClient.post(this.url(`cancel`, version), entityList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    receive(entityList: FinancialReceivementCardAdministrator[], version?: string): Observable<FinancialReceivementCard[]> {
        return this.baseService.httpClient.post(this.url(`receive`, version), entityList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findAll<F extends GIPIAbstractFilterModel = any, T = any>(filter?: F, version?: string): Observable<GIPIPageModel<T>> {
        return this.baseService.httpClient.post(this.url('find-all', version), filter, this.options()).pipe(
            map(json => {
                const page: GIPIPageModel<FinancialReceivementCard> = this.handleMapper(json);

                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    const date: Date = new Date();
                    const compareDate: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

                    for (let i = 0; i < page.content.length; i++) {
                        const dueDate: Date = new Date(moment(page.content[i].dueDate).format('yyyy/MM/DD'));
                        const isLessThan: boolean = DateUtil.isLessThan(
                            new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()),
                            new Date(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate())
                        );

                        if (isLessThan && page.content[i].status === 'RECEIVABLE') {
                            page.content[i].status = 'OVERDUE';
                        }
                    }
                }

                return page;
            }),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
