import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { GIPIAbstractDTO, GIPIUuid, ObjectUtil } from '@gipisistemas/ng-core';
import { FinancialReceivement } from '../receivement.model';

export class FinancialReceivementDTO extends GIPIAbstractDTO {

    /** @Required Acréscimo */
    addition: number;

    /** @Required Valor a receber */
    amountReceivable: number;

    /** @Required Valor recebido */
    amountReceived: number;

    /** Id da conta bancária */
    bankAccountId: GIPIUuid;

    /** Retorno do boleto */
    billetReturn: boolean;

    /** @Required Lista de ids das parcelas que serão recebidas */
    billinstallmentIdlist: GIPIUuid[];

    /** @Required Tipo de cobrança do recebimento */
    chargeTypeId: GIPIUuid;

    /** Lista de cheques recebidos */
    checkReceivedList: FinancialCheckReceived[];

    /** Crédito gerado ao cliente */
    customerCreditAmount: number;

    /** @Required Desconto */
    discount: number;

    /** @Required Multa */
    fine: number;

    /** @Required Multa alterada no recebimento */
    fineChangedOnReceipt: boolean;

    /** @Required Juros */
    interest: number;

    /** @Required Juros alterados no recebimento */
    interestChangedOnReceipt: boolean;

    /** Lista de parcelas de cartão de crádito/débito */
    receivementCardList: FinancialReceivementCard[];

    /** @Required Data do recebimento */
    receivementDate: Date;

    /** @Required Id do turno de caixa */
    shiftId: GIPIUuid;

    /** @Required Usar crédito do cliente */
    useClientCredit: boolean;

    /** Crédito do cliente usado */
    usedCustomerCredit: number;

    constructor() {
        super();
        this.addition = 0;
        this.amountReceivable = 0;
        this.amountReceived = 0;
        this.bankAccountId = null;
        this.billetReturn = false;
        this.billinstallmentIdlist = [];
        this.chargeTypeId = null;
        this.checkReceivedList = [];
        this.customerCreditAmount = 0;
        this.discount = 0;
        this.fine = 0;
        this.fineChangedOnReceipt = false;
        this.interest = 0;
        this.interestChangedOnReceipt = false;
        this.receivementCardList = [];
        this.receivementDate = new Date();
        this.shiftId = null;
        this.useClientCredit = false;
        this.usedCustomerCredit = 0;
    }

    public static transformInReceivementDTO(receivement: FinancialReceivement, billInstallmentList: FinancialBillInstallment[], useClientCredit: boolean): FinancialReceivementDTO {
        const receivementDTO: FinancialReceivementDTO = new FinancialReceivementDTO();
        receivementDTO.addition = receivement.addition;
        receivementDTO.amountReceivable = receivement.amountReceivable;
        receivementDTO.amountReceived = receivement.amountReceived;
        receivementDTO.bankAccountId = !ObjectUtil.isNull(receivement.bankAccount) ? receivement.bankAccount.id : null;
        receivementDTO.billetReturn = receivement.billetReturn;
        receivementDTO.billinstallmentIdlist = billInstallmentList.map(b => b.id);
        receivementDTO.chargeTypeId = !ObjectUtil.isNull(receivement.chargeType) ? receivement.chargeType.id : null;
        receivementDTO.checkReceivedList = receivement.checkReceivedList;
        receivementDTO.customerCreditAmount = receivement.customerCreditAmount;
        receivementDTO.discount = receivement.discount;
        receivementDTO.fine = receivement.fine;
        receivementDTO.fineChangedOnReceipt = receivement.fineChangedOnReceipt;
        receivementDTO.interest = receivement.interest;
        receivementDTO.interestChangedOnReceipt = receivement.interestChangedOnReceipt;
        receivementDTO.receivementCardList = receivement.receivementCardList;
        receivementDTO.receivementDate = receivement.receivementDate;
        receivementDTO.shiftId = !ObjectUtil.isNull(receivement.shift) ? receivement.shift.id : null;
        receivementDTO.useClientCredit = useClientCredit;
        receivementDTO.usedCustomerCredit = receivement.usedCustomerCredit;
        return receivementDTO;
    }

}
