<gipi-abstract-form id="dialogRegisterEmployeeRole"
                    name="dialogRegisterEmployeeRole"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterEmployeeRole">
        Cadastrar cargo
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <itss-input label="Descrição"
                        id="employeeRoleDescription"
                        name="employeeRoleDescription"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="employeeRole.description"
                        [min]="5"
                        [max]="80"
                        [maxlength]="80"
                        fxFlex
                        fxFlex.lt-sm="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="employeeRoleEnabled"
                                    name="employeeRoleEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex="30"
                                    fxFlex.lt-sm="100">
            </gipi-input-select-enum>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
