import { HttpParams } from '@angular/common/http';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialBilletFinancialService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.billet, 'v1', baseService);
        this.baseService = baseService;
    }
    download(installmentIds) {
        return this.baseService.httpClient.post(this.url('download'), installmentIds, Object.assign({}, this.options(), { observe: 'response', responseType: 'blob' })).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    generateBillet(billInstallment, generatePdf) {
        const httpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.baseService.httpClient.post(this.url('generate-billet'), billInstallment, Object.assign({}, this.options(httpParams), { observe: 'response', responseType: 'blob' })).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    generateBilletAll(billInstallmentList, generatePdf) {
        const httpParams = new HttpParams().append('generatePdf', String(generatePdf));
        return this.baseService.httpClient.post(this.url('generate-billet-all'), billInstallmentList, Object.assign({}, this.options(httpParams), { observe: 'response', responseType: 'blob' })).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    generateBilletForBillInstallmentWithoutBillet() {
        return this.baseService.httpClient.post(this.url('generate-billet-for-bill-installment-without-billet'), null, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    downloadBillet(billInstallmentList) {
        return this.baseService.httpClient.post(this.url('download'), billInstallmentList, { observe: 'response', responseType: 'blob' }).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialBilletFinancialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBilletFinancialService_Factory() { return new FinancialBilletFinancialService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialBilletFinancialService, providedIn: "root" });
