import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { RegistrationBankAccountService } from "@gipi-registration/bank-account/services/bank-account.service";
import { RegistrationCardAdministrator } from "@gipi-registration/card-administrator/models/card-administrador.model";
import { RegistrationCardAdministratorService } from "@gipi-registration/card-administrator/services/card-administrator.service";
import { OptionActiveSituationEnum } from "@gipi-shared/enums/option-active-situation.enum";
import { APP_MESSAGES, DocumentUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil } from "@gipisistemas/ng-core";
export class CardAdministratorFormDialogComponent extends GIPIAbstractComponent {
    constructor(service, baseService, activatedRoute, dialogRef, data = { typeOperation: 'NEW', cardAdministrator: null }, _bankAccountService) {
        super(baseService, activatedRoute);
        this.service = service;
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this.dialogRef = dialogRef;
        this.data = data;
        this._bankAccountService = _bankAccountService;
        this.typeOperation = 'NEW';
        this.cardAdministrator = new RegistrationCardAdministrator();
        this.optionActiveSituationEnum = OptionActiveSituationEnum;
        this.optionActiveSituationValue = 'ENABLED';
        this.bankAccountFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 10).toPromise();
            return result;
        });
        this.dialogRef.disableClose = true;
    }
    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.cardAdministrator = this.data.cardAdministrator;
            if (!this.cardAdministrator.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    confirm() {
        try {
            if (ObjectUtil.isNull(this.cardAdministrator)) {
                return;
            }
            if (StringUtil.isEmpty(this.cardAdministrator.cnpj)) {
                this.addWarningMessage('Campo CNPJ é obrigatório e não foi informado');
                return;
            }
            if (!DocumentUtil.isValidCnpj(this.cardAdministrator.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return;
            }
            if (StringUtil.isEmpty(this.cardAdministrator.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.cardAdministrator.description.length < 3) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 3 caracteres');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.bankAccount)) {
                this.addWarningMessage('Campo conta bancária é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.cashFee)) {
                this.addWarningMessage('Campo taxa à vista é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.installmentFee)) {
                this.addWarningMessage('Campo taxa parcelado é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.debitFee)) {
                this.addWarningMessage('Campo taxa do débito é obrigatório e não foi informado');
                return;
            }
            // if (ObjectUtil.isNull(this.cardAdministrator.creditFee)) {
            //     this.addWarningMessage('Campo taxa do crédito é obrigatório e não foi informado');
            //     return;
            // }
            if (ObjectUtil.isNull(this.cardAdministrator.debitAnticipationFee)) {
                this.addWarningMessage('Campo taxa de antecipação do débito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.creditAnticipationFee)) {
                this.addWarningMessage('Campo taxa de antecipação do crédito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.dayCashReceipt)) {
                this.addWarningMessage('Campo dias para recebimento dinheiro é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.dayDebitReceipt)) {
                this.addWarningMessage('Campo dias para recebimento débito é obrigatório e não foi informado');
                return;
            }
            if (ObjectUtil.isNull(this.cardAdministrator.dayCreditReceipt)) {
                this.addWarningMessage('Campo dias para recebimento crédito é obrigatório e não foi informado');
                return;
            }
            this.loading = true;
            this.cardAdministrator.enabled = (this.optionActiveSituationValue === 'ENABLED');
            this.service.save(this.cardAdministrator).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
