<gipi-abstract-form id="dialogRegisterProductGroupings"
                    name="dialogRegisterProductGroupings"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterProductGroupings">
        Cadastrar agrupamento de produto
    </gipi-toolbar>
    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <itss-input label="Descrição da seção"
                        id="productSectionDescription"
                        name="productSectionDescription"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="productSection.description"
                        [min]="3"
                        [max]="80"
                        [maxlength]="80"
                        fxFlex
                        fxFlex.lt-sm="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="productSectionEnabled"
                                    name="productSectionEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationSectionValue"
                                    fxFlex="20"
                                    fxFlex.lt-sm="100">
            </gipi-input-select-enum>
        </div>

        <div class="content-grouping">
            <label for="contentProductGroup">
                Grupo
            </label>
            <div id="contentProductGroup"
                 fxLayout="column"
                 fxLayoutGap="16px"
                 fxLayoutAlign="start"
                 fxFlex>
                <div fxLayout="row"
                     fxLayoutGap="16px"
                     fxLayoutAlign="start end"
                     fxFlex>
                    <itss-input label="Descrição do grupo"
                                id="productGroupDescription"
                                name="productGroupDescription"
                                [min]="3"
                                [max]="80"
                                [maxlength]="80"
                                [required]="true"
                                [disabled]="loading || (typeOperation === 'VIEW')"
                                [(ngModel)]="productGroup.description"
                                (keydown.enter)="(typeOperation === 'NEW') && addGroupFromSection()"
                                fxFlex
                                fxFlex.lt-md="100">
                    </itss-input>
                    <gipi-input-select-enum label="Situação"
                                            id="productGroupEnabled"
                                            name="productGroupEnabled"
                                            [disabled]="loading || (typeOperation !== 'EDIT')"
                                            [required]="true"
                                            [valuesExclud]="[]"
                                            [enum]="optionActiveSituationEnum"
                                            [(ngModel)]="optionActiveSituationGroupValue"
                                            (keydown.enter)="(typeOperation === 'EDIT') && addGroupFromSection()"
                                            fxFlex="20"
                                            fxFlex.lt-md="100">
                    </gipi-input-select-enum>
                    <gipi-button label="Adicionar"
                                 gipi-primary
                                 id="btnAddProductGroup"
                                 name="btnAddProductGroup"
                                 icon="add_circle_outline"
                                 *ngIf="!isViewing()"
                                 [disabled]="!productSection || !productSection?.description || !productGroup || !productGroup?.description|| loading || isViewing()"
                                 (click)="addGroupFromSection()"
                                 fxFlex="20"
                                 fxFlex.lt-md="100">
                    </gipi-button>
                </div>

                <itss-table [paginator]="true"
                            [loading]="loading"
                            [expandable]="true"
                            [columnExpandableFixed]="true"
                            [expandableOneRow]="true"
                            [ignoreChildrenExpandRow]="true"
                            [backendPagination]="false"
                            [paginatorLength]="(productSection && productSection.children) ? productSection.children.length : 0"
                            [paginatorPageSize]="5"
                            [columns]="_columnsTableGroup"
                            [data]="productSection?.children">
                    <ng-template #expanded
                                 let-entity>
                        <div class="subgroup">
                            <div class="content-grouping">
                                <label for="contentProductSubgroup">
                                    Subgrupo
                                </label>
                                <div id="contentProductSubgroup"
                                     fxLayout="row"
                                     fxLayout.lt-md="column"
                                     fxLayoutAlign="start end"
                                     fxLayoutGap="16px"
                                     fxFlex>
                                    <itss-input label="Descrição do subgrupo"
                                                id="productSubgroupDescription"
                                                name="productSubgroupDescription"
                                                [min]="3"
                                                [max]="80"
                                                [maxlength]="80"
                                                [required]="true"
                                                [disabled]="loading || (typeOperation === 'VIEW')"
                                                [(ngModel)]="productSubgroup.description"
                                                (keydown.enter)="(typeOperation === 'NEW') && addSubgroupFromGroupOfSection(entity)"
                                                fxFlex
                                                fxFlex.lt-md="100">
                                    </itss-input>
                                    <gipi-input-select-enum label="Situação"
                                                            id="productSubgroupEnabled"
                                                            name="productSubgroupEnabled"
                                                            [disabled]="loading || (typeOperation !== 'EDIT')"
                                                            [required]="true"
                                                            [valuesExclud]="[]"
                                                            [enum]="optionActiveSituationEnum"
                                                            [(ngModel)]="optionActiveSituationSubgroupValue"
                                                            (keydown.enter)="(typeOperation === 'EDIT') && addSubgroupFromGroupOfSection(entity)"
                                                            fxFlex="20"
                                                            fxFlex.lt-md="100">
                                    </gipi-input-select-enum>
                                    <gipi-button label="Adicionar"
                                                 gipi-primary
                                                 id="btnAddProductSubgroup"
                                                 name="btnAddProductSubgroup"
                                                 icon="add_circle_outline"
                                                 *ngIf="!isViewing()"
                                                 [disabled]="!productSubgroup || !productSubgroup?.description || loading || isViewing()"
                                                 (click)="addSubgroupFromGroupOfSection(entity)"
                                                 fxFlex="20"
                                                 fxFlex.lt-md="100">
                                    </gipi-button>
                                </div>
                            </div>

                            <itss-table [paginator]="true"
                                        [loading]="loading"
                                        [backendPagination]="false"
                                        [paginatorLength]="(entity && entity.children) ? entity.children.length : 0"
                                        [paginatorPageSize]="5"
                                        [columns]="_columnsTableSubgroup"
                                        [data]="entity?.children"
                                        fxFlexFill>
                            </itss-table>
                        </div>
                    </ng-template>
                </itss-table>
            </div>
        </div>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #statusGroupTemplate
             let-entity>
    <gipi-badge [label]="setTextStatus(entity)"
                [color]="setColorStatus(entity)"
                style="width: 80%;">
    </gipi-badge>
</ng-template>

<ng-template #actionsGroupTemplate
             let-entity>
    <itss-button tooltip="Editar"
                 svgIcon="edit"
                 color="primary"
                 type="icon"
                 *ngIf="(typeOperation !== 'VIEW') && entity.enabled"
                 (click)="editGroupFromSection(entity)">
    </itss-button>
    <itss-button tooltip="Ativar | Inativar"
                 svgIcon="lock"
                 color="primary"
                 type="icon"
                 *ngIf="(typeOperation !== 'VIEW')"
                 (click)="entity.enabled = !entity.enabled">
    </itss-button>
    <itss-button tooltip="Excluir"
                 svgIcon="trash"
                 color="accent"
                 type="icon"
                 *ngIf="(typeOperation !== 'VIEW')"
                 (click)="removeGroupFromSection(entity)">
    </itss-button>
</ng-template>

<ng-template #statusSubgroupTemplate
             let-entity>
    <gipi-badge [label]="setTextStatus(entity)"
                [color]="setColorStatus(entity)"
                style="width: 80%;">
    </gipi-badge>
</ng-template>

<ng-template #actionsSubgroupTemplate
             let-entity>
    <itss-button tooltip="Editar"
                 svgIcon="edit"
                 color="primary"
                 type="icon"
                 *ngIf="(typeOperation !== 'VIEW') && entity.enabled"
                 (click)="editSubgroupFromGroupOfSection(entity)">
    </itss-button>
    <itss-button tooltip="Ativar | Inativar"
                 svgIcon="lock"
                 color="primary"
                 type="icon"
                 *ngIf="(typeOperation !== 'VIEW')"
                 (click)="entity.enabled = !entity.enabled">
    </itss-button>
    <itss-button tooltip="Excluir"
                 svgIcon="trash"
                 color="accent"
                 type="icon"
                 *ngIf="(typeOperation !== 'VIEW')"
                 (click)="removeSubgroupFromGroupOfSection(entity)">
    </itss-button>
</ng-template>
