import { BaseModel } from '@gipi-shared/models/base.model';
import { RegistrationLegalPersonType } from '../enums/legal-person-type.enum';

export class RegistrationLegalPerson extends BaseModel {

    /**
     * Código CNAE
     * @Min 0 characters
     * @Max 10 characters
     */
    cnae: string;

    /** CNPJ */
    cnpj: string;

    /**
     * Inscrição municipal
     * @Min 0 characters
     * @Max 20 characters
     */
    municipalRegistration: string;

    /**
     * Inscrição estadual
     * @Min 0 characters
     * @Max 30 characters
     */
    stateRegistration: string;

    /** Tipo da linha de negócio --- [ NATIONAL_SIMPLE, NATIONAL_SIMPLE_EXCESS_SUB_LIMIT_OF_GROSS_REVENUE, NORMAL_REGIME ] */
    type: RegistrationLegalPersonType;

}
