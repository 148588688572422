import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { OAuthUser } from '@gipi-financial/user/models/user.model';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIAbstractComponent, GIPIBaseService, InputComponent, ObjectUtil, StringUtil, TokenDTO } from '@gipisistemas/ng-core';
@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends GIPIAbstractComponent implements OnInit {

    @ViewChild('firstInput', { static: true }) firstInput: InputComponent;

    public username: string = '';
    public password: string = '';

    public isViewPassword: boolean = false;

    public numberFailedAttempts: number = 0;
    public tokenReCAPTCHA: string | undefined;

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _router: Router,
        private _customAuthenticationService: CustomAuthenticationService,
    ) {
        super(baseService, activatedRoute);

        const token: TokenDTO<OAuthUser> = this._customAuthenticationService.token;
        const isOAuthRoute: boolean = this._router.url.includes('/oauth/login');
        if (this._customAuthenticationService.isValidToken(token) && isOAuthRoute) {
            this._router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        setTimeout(() => this.firstInput._elementRef.nativeElement.focus());
    }

    public login(): void {
        try {
            this.numberFailedAttempts++;
            if (this._isValid()) {
                this.loading = true;
                this._customAuthenticationService.login(this.username, this.password)
                    .pipe(first())
                    .subscribe({
                        next: _ => {
                            // this._router.navigate(['/']);
                            location.reload();
                            this.loading = false;
                        },
                        error: error => {
                            this.addErrorMessage(error);
                            this.loading = false;
                        }
                    });
            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _isValid(): boolean {
        if ((this.numberFailedAttempts >= 3) && (StringUtil.isEmpty(this.tokenReCAPTCHA) || ObjectUtil.isNull(this.tokenReCAPTCHA))) {
            if (this.numberFailedAttempts >= 4) {
                this.addWarningMessage('Marque o reCAPTCHA para entrar');
            }
            return false;
        }
        if (StringUtil.isEmpty(this.username)) {
            this.addWarningMessage('O campo e-mail é obrigatório');
            return false;
        }
        if (StringUtil.isEmpty(this.password)) {
            this.addWarningMessage('O campo senha é obrigatório');
            return false;
        }
        return true;
    }

}
