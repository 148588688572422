import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationManufacturer } from '@gipi-registration/manufacturer/models/manufacturer.model';
import { RegistrationManufacturerService } from '@gipi-registration/manufacturer/services/manufacturer.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, DocumentUtil, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface ManufacturerData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    manufacturer: RegistrationManufacturer;
}

@Component({
    templateUrl: './manufacturer-form-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class ManufacturerFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public manufacturer: RegistrationManufacturer = new RegistrationManufacturer();

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    constructor(
        protected service: RegistrationManufacturerService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<ManufacturerFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ManufacturerData = { typeOperation: 'NEW', manufacturer: null },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.manufacturer = this.data.manufacturer;

            if (!this.manufacturer.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.manufacturer)) {
                return;
            }
            if (StringUtil.isEmpty(this.manufacturer.person.name)) {
                this.addWarningMessage('Campo razão social é obrigatório e não foi informado');
                return;
            }
            if (this.manufacturer.person.name.length < 5) {
                this.addWarningMessage('Campo razão social deve conter no mínimo 5 caracteres');
                return;
            }
            if (!StringUtil.isEmpty(this.manufacturer.person.legalPerson.cnpj) && !DocumentUtil.isValidCnpj(this.manufacturer.person.legalPerson.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return;
            }
            this.loading = true;

            this.manufacturer.enabled = (this.optionActiveSituationValue === 'ENABLED');

            this.service.save(this.manufacturer).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
