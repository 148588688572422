import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { FinancialBankAccountTransfer } from '@gipi-financial/bank-account-transfer/models/bank-account-transfer.model';
import { FinancialBankAccountTransferService } from '@gipi-financial/bank-account-transfer/services/bank-account-transfer.service';
import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { APP_MESSAGES, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIUuid, INJECTOR, ObjectUtil, PageDTO } from '@gipisistemas/ng-core';

export type BankAccountTransferType = 'NEW' | 'EDIT' | 'VIEW' | 'DELETE_BANK_ACCOUNT';

export interface BankAccountTransferData {
    typeOperation: BankAccountTransferType;
    bankAccountTransfer: FinancialBankAccountTransfer;
    isBillReceivable?: boolean;
    isTransactionConciliation?: boolean;
}

@Component({
    selector: 'gipi-bank-accounts-transfer-dialog',
    templateUrl: './bank-accounts-transfer-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class BankAccountsTransferDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public bankAccountTransfer: FinancialBankAccountTransfer = new FinancialBankAccountTransfer();

    public amountOrigin$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public amountDestiny$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public isLoad: boolean = false;

    public typeOperation: BankAccountTransferType;

    public isBillReceivable: boolean = false;

    public isTransactionConciliation: boolean = false;

    bankAccountOriginFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialBankAccount> = await this._bankAccountService.findByValue(value, page, 10).toPromise();
        return result;
    };

    bankAccountDestinyFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<FinancialBankAccount> = await this._bankAccountService.findByValue(value, page, 10).toPromise();
        return result;
    };

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _bankAccountTransferService: FinancialBankAccountTransferService,
        private _bankAccountService: FinancialBankAccountService,
        public dialogRef: MatDialogRef<BankAccountsTransferDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BankAccountTransferData = { typeOperation: 'NEW', bankAccountTransfer: null },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.isLoad = true;
        super.ngOnInit();

        this.typeOperation = this.data.typeOperation;

        this.isLoad = false;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.amountOrigin$.complete();
        this.amountDestiny$.complete();
    }

    protected async onInitForm(): Promise<void> {
        if (this.data.typeOperation !== 'NEW') {
            this.bankAccountTransfer = this.data.bankAccountTransfer;
        }

        if (this.data.isTransactionConciliation) {
            this.bankAccountTransfer = this.data.bankAccountTransfer;
            this.isBillReceivable = this.data.isBillReceivable;
            this.isTransactionConciliation = this.data.isTransactionConciliation;

            const idBankAccount: GIPIUuid = this.isBillReceivable ? this.bankAccountTransfer.destiny.id : this.bankAccountTransfer.origin.id;
            const bankAccount: FinancialBankAccount = await this._bankAccountService.getOne(idBankAccount).toPromise();
            if (this.isBillReceivable) {
                this.bankAccountTransfer.destiny = ObjectUtil.clone(bankAccount);
            } else {
                this.bankAccountTransfer.origin = ObjectUtil.clone(bankAccount);
            }
        }

        if ((this.data.typeOperation !== 'VIEW') && (this.typeOperation === 'EDIT')) {
            this.bankAccountTransfer.originPreviousBankAccountId = this.bankAccountTransfer.origin.id;
            this.bankAccountTransfer.destinationPreviousBankAccountId = this.bankAccountTransfer.destiny.id;
            this.bankAccountTransfer.previousAmount = this.bankAccountTransfer.amount;
        }
    }

    refreshOriginList(): void {
        this.amountDestiny$.next(!ObjectUtil.isNewModel(this.bankAccountTransfer.destiny) ? this.bankAccountTransfer.destiny.currentBalance : 0);
    }

    refreshDestinyList(): void {
        this.amountOrigin$.next(!ObjectUtil.isNewModel(this.bankAccountTransfer.origin) ? this.bankAccountTransfer.origin.currentBalance : 0);
    }

    private _isValidTransfer(): boolean {
        if (ObjectUtil.isNull(this.bankAccountTransfer.origin) || ObjectUtil.isNewModel(this.bankAccountTransfer.origin)) {
            this.addWarningMessage('Campo conta de origem é obrigatório e não foi informado');
            return false;
        }
        if (this.bankAccountTransfer.origin.currentBalance < 0) {
            this.addWarningMessage('Conta de origem está com saldo negativo, portanto, não é possível realizar a transferência');
            return false;
        }
        if (this.bankAccountTransfer.origin.currentBalance === 0) {
            this.addWarningMessage('Conta de origem está com saldo R$ 0,00, portanto, não é possível realizar a transferência');
            return false;
        }
        if (ObjectUtil.isNull(this.bankAccountTransfer.destiny) || ObjectUtil.isNewModel(this.bankAccountTransfer.destiny)) {
            this.addWarningMessage('Campo conta de destino é obrigatório e não foi informado');
            return false;
        }
        if (this.bankAccountTransfer.amount < 0) {
            this.addWarningMessage('Valor da transferência não pode ser inferior à R$ 0,00');
            return false;
        }
        if (this.bankAccountTransfer.amount === 0) {
            this.addWarningMessage('Valor da transferência não pode ser R$ 0,00');
            return false;
        }
        if (ObjectUtil.isNull(this.bankAccountTransfer.amount)) {
            this.addWarningMessage('Campo valor da transferência é obrigatório e não foi informado');
            return false;
        }
        if (this.bankAccountTransfer.amount > this.bankAccountTransfer.origin.currentBalance) {
            this.addWarningMessage('Valor da transferência não pode ser superior ao saldo da conta de origem');
            return false;
        }
        if (ObjectUtil.isNull(this.bankAccountTransfer.date)) {
            this.addWarningMessage('Campo data é obrigatório e não foi informado');
            return false;
        }
        if (!DateUtil.isValid(this.bankAccountTransfer.date)) {
            this.addWarningMessage('Data da transferência informada não é válida');
            return false;
        }
        if (this.bankAccountTransfer.origin.id === this.bankAccountTransfer.destiny.id) {
            this.addWarningMessage('A conta de origem deve ser diferente da conta de destino');
            return false;
        }

        return true;
    }

    public confirm(): void {
        try {
            if (!this._isValidTransfer()) {
                return;
            }

            this.isLoad = true;
            this.loading = true;

            if (this.isTransactionConciliation) {
                this.close('SAVED');
            } else {
                this._bankAccountTransferService.transfer(this.bankAccountTransfer).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close('RELOAD_TABLE');
                }, error => {
                    throw new Error(error);
                });
            }
        } catch (e) {
            this.isLoad = false;
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE' | 'SAVED'): void {
        this.dialogRef.close(operation);
    }

}
