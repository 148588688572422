import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialBilletFinancialService } from '@gipi-billet/bank-slip/services/billet-financial.service';
import { FinancialPeriodicity, FinancialPeriodicityEnum } from '@gipi-financial/bill/enums/periodicity.enum';
import { FinancialBillInstallment } from '@gipi-financial/bill/models/bill-installment.model';
import { FinancialBill } from '@gipi-financial/bill/models/bill.model';
import { FinancialBillService } from '@gipi-financial/bill/services/bill.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { BankCodes, BankCodeUtil } from '@gipi-shared/utils/bank-codes-billet.util';
import { AbstractComponent, APP_MESSAGES, ConfirmationService, DateUtil, INJECTOR, MonthYear, NumberUtil, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './recurring-release-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class RecurringReleaseDialogComponent extends AbstractComponent implements OnInit {

    installment: FinancialBillInstallment;

    installmentList: FinancialBillInstallment[] = [];

    periodicity: FinancialPeriodicity = 'MONTHLY';

    periodicityDay: number;

    periodicityEnum: typeof FinancialPeriodicityEnum = FinancialPeriodicityEnum;

    dayList: { id: number, description: string }[] = [];

    day: { id: number, description: string };

    begin: MonthYear = null;

    end: MonthYear = null;

    initialDueDate: Date = null;

    selectPeriod: boolean = false;

    numberInstallments: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FinancialBillInstallment,
        public dialogRef: MatDialogRef<RecurringReleaseDialogComponent>,
        private _billService: FinancialBillService,
        private _confirmationService: ConfirmationService,
        private _billetFinancialService: FinancialBilletFinancialService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
        this._generateDayList();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.installment = this.data;

        setTimeout(() => {
            this.selectionChangePeriodicity();
        });
    }

    private _generateDayList(): void {
        this.dayList = Array.from({ length: 31 }, (_, index) => ({
            id: index + 1,
            description: ('00' + (index + 1)).slice(-2)
        }));
    }

    selectionChangePeriodicity(): void {
        const date: Date = new Date(moment(this.installment.dueDate).format('yyyy/MM/DD'));
        this.begin = new MonthYear(date.getMonth(), date.getFullYear());
        this.initialDueDate = new Date(date);

        this.selectPeriod = false;
    }

    modelChangeSelectPeriod(): void {
        const date: Date = new Date(moment(this.installment.dueDate).format('yyyy/MM/DD'));
        this.day = this.dayList.find(day => day.id === date.getDate());
        this.begin = new MonthYear(date.getMonth(), date.getFullYear());
        this.initialDueDate = new Date(date);

        this.numberInstallments = 1;
        this.periodicityDay = 1;
    }

    async save(): Promise<void> {
        try {
            this.loading = true;

            if ((this.installment.bill.type === 'RECEIVABLE') && (this.installment.chargeType.type === 'BANK_SLIP')) {
                if (this.installment.bankAccount.type !== 'CHECKING_ACCOUNT') {
                    this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja do tipo "Conta corrente"');
                    this.loading = false;
                    return;
                }

                const allowedBankAccount: boolean = BankCodeUtil.isValidBankCode((this.installment.bankAccount.bank.code as BankCodes));
                if (!allowedBankAccount) {
                    this.loading = false;
                    this.loadingChange.emit(this.loading);
                    this.addWarningMessage('Não é possível gerar boleto usando uma conta bancária que não seja válida');
                    return;
                }
            }

            this._generateInstallments();

            const bill: FinancialBill = this.billInstallListToBill(this.installmentList);

            await this._billService.save(bill).toPromise().then(b => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                if ((this.installment.bill.type === 'RECEIVABLE') && (this.installment.chargeType.type === 'BANK_SLIP')) {
                    this.confirmGenerateBankSlip(b);
                } else {
                    this.close(true);
                }
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    billInstallListToBill(billInstallmentList: FinancialBillInstallment[]): FinancialBill {
        const billInstallmentListClone: FinancialBillInstallment[] = [];

        for (let i: number = 0; i < billInstallmentList.length; i++) {
            const billInstalment: FinancialBillInstallment = ObjectUtil.clone(billInstallmentList[i]);
            billInstalment.bill = null;
            billInstallmentListClone.push(billInstalment);
        }

        const bill: FinancialBill = ObjectUtil.clone(billInstallmentList[0].bill);
        bill.billInstallmentList = billInstallmentListClone;

        return bill;
    }

    close(navigate: boolean): void {
        this.dialogRef.close(navigate);
    }

    private _validate(): void {
        if (!NumberUtil.isPositive(this.installment.amount)) {
            throw new Error('Valor é obrigatório e não foi informado');
        } else if (ObjectUtil.isNull(this.periodicity)) {
            throw new Error('Periodicidade é obrigatório e não foi informado');
        } else if ((this.periodicity.toString() !== 'MONTHLY') && (ObjectUtil.isNull(this.numberInstallments) || (Number(this.numberInstallments) <= 0))) {
            throw new Error('Qtd. de parcelas é obrigatório e não foi informado');
        } else if ((this.periodicity.toString() !== 'MONTHLY') && DateUtil.isLessThan(new Date(this.initialDueDate), new Date(this.installment.issuanceDate))) {
            throw new Error('Data de vencimento não pode ser menor que a data de emissão');
        } else if (this.periodicity.toString() === 'FREE' && (ObjectUtil.isNull(this.periodicityDay) || (Number(this.periodicityDay) <= 0))) {
            throw new Error('Qtd. de dias entre parcelas é obrigatório e não foi informado');
        } else if ((this.periodicity.toString() === 'MONTHLY') && (ObjectUtil.isNull(this.numberInstallments) || (Number(this.numberInstallments) <= 0))) {
            throw new Error('Qtd. de parcelas é obrigatório e não foi informado');
        } else if (this.periodicity.toString() === 'MONTHLY' && this.selectPeriod) {
            const issuanceDate: Date = new Date(moment(this.installment.issuanceDate).format('yyyy/MM/DD'));
            const dateBegin: Date = moment().day(this.day.id).month(this.begin.month).year(this.begin.year).toDate();
            const dateEnd: Date = moment().day(this.day.id).month(this.end.month).year(this.end.year).toDate();
            const lastDayOfMonth: number = DateUtil.getLastDayMonth(this.begin.month);

            if (ObjectUtil.isNull(this.day)) {
                throw new Error('Dia do vencimento é obrigatório e não foi informado');
            } else if (ObjectUtil.isNull(this.begin)) {
                throw new Error('Mês/Ano inicial é obrigatório e não foi informado');
            } else if (ObjectUtil.isNull(this.end)) {
                throw new Error('Mês/Ano final é obrigatório e não foi informado');
            } else if (DateUtil.isLessThanMonth(dateEnd, dateBegin)) {
                throw new Error('Mês/Ano final não pode ser menor que Mês/Ano inicial');
            } else if (DateUtil.isLessThan(new Date(dateBegin), new Date(issuanceDate))) {
                throw new Error('Mês/Ano inicial não pode ser menor que a Data emissão');
            } else if (this.day.id > lastDayOfMonth) {
                throw new Error(`Mês/Ano inicial possui ${lastDayOfMonth} dias. Portanto, o dia do vencimento é inválido`);
            }
        }
    }

    private _generateInstallments(): void {
        try {
            this.loading = true;
            this._validate();

            const dateList: Date[] = this._getDateList();
            const description: string = StringUtil.isEmpty(this.installment.description) ? '' : this.installment.description;

            const installmentList: FinancialBillInstallment[] = dateList.map(date => {
                const installment: FinancialBillInstallment = ObjectUtil.clone(this.installment);
                installment.id = null;
                installment.saleBillInstallmentId = null;
                installment.bill.id = null;
                installment.bill.saleBillId = null;
                installment.documentNumber = null;
                installment.bill.periodicity = this.periodicity;
                installment.portion = dateList.indexOf(date) + 1;
                installment.description = description;
                installment.dueDate = date;
                installment.ourNumber = null;

                return installment;
            });

            // for (let i = 0; i < dateList.length; i++) {
            //     const installment: FinancialBillInstallment = ObjectUtil.clone(this.installment);
            //     installment.id = null;
            //     installment.bill.periodicity = this.periodicity;
            //     installment.portion = dateList.indexOf(dateList[i]) + 1;
            //     installment.description = description;
            //     installment.dueDate = dateList[i];
            //     installment.ourNumber = null;
            //     installmentList.push(installment);
            // }

            // dateList.forEach(date => {
            //     const installment: FinancialBillInstallment = ObjectUtil.clone(this.installment);
            //     installment.id = null;
            //     installment.bill.periodicity = this.periodicity;
            //     installment.portion = dateList.indexOf(date) + 1;
            //     installment.description = description;
            //     installment.dueDate = date;
            //     installment.ourNumber = null;
            //     installmentList.push(installment);
            // });
            this.installmentList = installmentList;
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _getDateList(): Date[] {
        const dateList: Date[] = [];
        const dateInitial: Date = this.selectPeriod
            ? moment().day(this.day.id).month(this.begin.month).year(this.begin.year).toDate()
            : new Date(this.initialDueDate);

        const dateFinal: Date = this.selectPeriod
            ? moment().day(this.day.id).month(this.end.month).year(this.end.year).toDate()
            : null;

        if (this.selectPeriod) {
            dateInitial.setDate(this.day.id);
            dateFinal.setDate(this.day.id);
            while (DateUtil.isLessThanOrEqual(dateInitial, dateFinal, 'date')) {
                dateList.push(new Date(dateInitial));
                this._setDatePeriodicity(dateInitial);
            }
        } else {
            for (let i: number = 0; i < Number(this.numberInstallments); i++) {
                dateList.push(new Date(dateInitial));
                this._setDatePeriodicity(dateInitial);
            }
        }
        return dateList;
    }

    private _setDatePeriodicity(date: Date): void {
        switch (this.periodicity.toString()) {
            case 'WEEKLY':
                date.setDate(date.getDate() + 7);
                break;
            case 'MONTHLY':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'BIMONTHLY':
                date.setMonth(date.getMonth() + 2);
                break;
            case 'QUARTERLY':
                date.setMonth(date.getMonth() + 3);
                break;
            case 'SEMESTER':
                date.setMonth(date.getMonth() + 6);
                break;
            case 'YEARLY':
                date.setFullYear(date.getFullYear() + 1);
                break;
            case 'FREE':
                date.setDate(date.getDate() + Number(this.periodicityDay));
                break;
        }
    }

    // selectionChangeDay(day: any): void {
    //     this.day = day;
    //     this.begin.setDate(this.day.id);
    //     this.end.setDate(this.day.id);
    // }

    async confirmGenerateBankSlip(bill: FinancialBill): Promise<void> {
        const isConfirmed: boolean = await this._confirmationService.confirm({
            title: 'Confirmação',
            message: 'Deseja imprimir o boleto?',
        });

        await this.generateBankSlip(bill.billInstallmentList, isConfirmed);
    }

    async generateBankSlip(installmentList: FinancialBillInstallment[], generatePdf: boolean): Promise<void> {
        try {
            this.loading = true;
            await this._billetFinancialService.generateBilletAll(installmentList, generatePdf).toPromise().then(resp => {
                if (generatePdf) {
                    const file: Blob = new Blob([resp.body], { type: 'application/pdf' });
                    const fileURL: string = URL.createObjectURL(file);
                    window.open(fileURL);
                }

                this.close(true);
            }, error => {
                this.loading = false;
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

}
