import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RegistrationDepartment } from '@gipi-registration/department/models/department.model';
import { RegistrationDepartmentService } from '@gipi-registration/department/services/department.service';
import { OptionActiveSituation, OptionActiveSituationEnum } from '@gipi-shared/enums/option-active-situation.enum';
import { APP_MESSAGES, GIPIAbstractComponent, GIPIBaseService, INJECTOR, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';

export interface DepartmentFormData {
    typeOperation: 'NEW' | 'EDIT' | 'VIEW';
    department: RegistrationDepartment;
}

@Component({
    templateUrl: './department-form-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class DepartmentFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    public typeOperation: 'NEW' | 'EDIT' | 'VIEW' = 'NEW';

    public department: RegistrationDepartment = new RegistrationDepartment();

    public optionActiveSituationEnum: typeof OptionActiveSituationEnum = OptionActiveSituationEnum;
    public optionActiveSituationValue: OptionActiveSituation = 'ENABLED';

    constructor(
        protected service: RegistrationDepartmentService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        public dialogRef: MatDialogRef<DepartmentFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DepartmentFormData = { typeOperation: 'NEW', department: null },
    ) {
        super(baseService, activatedRoute);
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.typeOperation = this.data.typeOperation;
        if (this.typeOperation !== 'NEW') {
            this.department = this.data.department;

            if (!this.department.enabled) {
                this.optionActiveSituationValue = 'DISABLED';
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.department)) {
                return;
            }
            if (StringUtil.isEmpty(this.department.description)) {
                this.addWarningMessage('Campo descrição é obrigatório e não foi informado');
                return;
            }
            if (this.department.description.length < 5) {
                this.addWarningMessage('Campo descrição deve conter no mínimo 5 caracteres');
                return;
            }
            this.loading = true;

            this.department.enabled = (this.optionActiveSituationValue === 'ENABLED');

            this.service.save(this.department).toPromise().then(() => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.loading = false;
                this.close('RELOAD_TABLE');
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
