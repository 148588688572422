import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { ConfigurationPaths } from '@gipi-paths/configuration.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { ConfigurationScanntech } from './../models/scanntech.model';

@Injectable({ providedIn: 'root' })
export class ConfigurationScanntechService extends GIPIAbstractCrudService<ConfigurationScanntech, GIPIBaseAbstractFilterModel> {

    /** Se trocar as permissões enquanto estiver na tela que usa a scanntech */
    public updated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(protected baseService: GIPIBaseService) {
        super(ConfigurationPaths.scanntech, 'v1', baseService);
    }

    findEnabled(version?: string): Observable<ConfigurationScanntech> {
        return this.baseService.httpClient.get(this.url(`enabled`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(_ => of(null)),
        );
    }

    save<T = any>(entity: T, version?: string): Observable<T> {
        this.validate(entity);
        return this.baseService.httpClient.post(this.url(`scanntech-configuration`, version), entity, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
