<gipi-abstract-form id="dialogPayment"
                    name="dialogPayment"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogPayment">
        Efetuar pagamento
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         (keydown.enter)="isLoad ? null : confirm()">
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-currency label="Valor bruto"
                                 [(ngModel)]="payment.amount"
                                 disabled="true"
                                 fxFlex="25"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Multa"
                                 [(ngModel)]="payment.fine"
                                 [disabled]="isLoad || billInstallmentList.length > 1"
                                 (keyup)="amountPaid()"
                                 required="true"
                                 fxFlex="25"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Juros"
                                 [(ngModel)]="payment.interest"
                                 [disabled]="isLoad || billInstallmentList.length > 1"
                                 (keyup)="amountPaid()"
                                 required="true"
                                 fxFlex="25"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <itss-input-currency label="Desconto"
                                 [(ngModel)]="payment.discount"
                                 [disabled]="isLoad || billInstallmentList.length > 1"
                                 (keyup)="amountPaid()"
                                 required="true"
                                 fxFlex="25"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start"
             fxLayoutGap="16px"
             fxLayout.lt-sm="column">
            <itss-input-currency label="Valor pagamento"
                                 [(ngModel)]="payment.amountPaid"
                                 disabled="true"
                                 fxFlex="23.592"
                                 fxFlex.lt-md="100">
            </itss-input-currency>
            <gipi-datepicker label="Data pagamento"
                             [(ngModel)]="payment.paymentDate"
                             [disabled]="isLoad"
                             required="true"
                             fxFlex="23.592"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-select-entity #firstInput
                                label="Conta bancária"
                                [(model)]="payment.bankAccount"
                                [entities]="bankAccountList"
                                [disabled]="isLoad || isCashier"
                                required="true"
                                property="description"
                                fxFlex
                                fxFlex.lt-md="100">
            </itss-select-entity>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [loading]="loading"
                     (click)="confirm()"
                     [disabled]="isLoad"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('REMARK_SELECTED')"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
