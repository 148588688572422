import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { FinancialPostingCategoryOperation } from '../enums/operation.enum';
import { FinancialPostingCategoryFilterDTO } from '../models/dto/posting-category-filter.dto';
import { FinancialPostingCategory } from '../models/posting-category.model';

@Injectable({ providedIn: 'root' })
export class FinancialPostingCategoryService extends GIPIAbstractCrudService<FinancialPostingCategory, FinancialPostingCategoryFilterDTO> {

    constructor(protected baseService: GIPIBaseService,) {
        super(FinancialPaths.postingCategory, 'v1', baseService);
    }

    getNextCode(upperCategory: FinancialPostingCategory, version?: string): Observable<number> {
        return this.baseService.httpClient.get(this.url(`next-code?upperCategoryId=${ObjectUtil.isNewModel(upperCategory) ? 0 : upperCategory.id}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    findByOperation(operation: FinancialPostingCategoryOperation, page: number, size: number = 10, version?: string): Observable<GIPIPageModel<FinancialPostingCategory>> {
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        return this.baseService.httpClient.get(this.url(`find-by-operation/${operation}?page=${page}&size=${size}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string, operation?: FinancialPostingCategoryOperation, onlyChildren?: boolean): Observable<GIPIPageModel<T>> {
        const validatedValue: string = value.trim();
        const validatedPage: number = Math.max(page, 0);
        const validatedSize: number = Math.max(size, 10);
        const validatedOperation: FinancialPostingCategoryOperation = !StringUtil.isEmpty(operation) ? operation : 'CREDIT';
        const validatedOnlyChildren: boolean = onlyChildren || true;

        const params: URLSearchParams = new URLSearchParams();
        params.set('value', validatedValue.toString());
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        params.set('operation', validatedOperation.toString());
        params.set('onlyChildren', validatedOnlyChildren.toString());

        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            params.set('sort', `${sort.field},${sort.direction}`);
        }

        const queryString: string = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';

        return this.baseService.httpClient.get(this.url(`find-by-value${queryString}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
