import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialPaymentService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.payment, 'v1', baseService);
        this.baseService = baseService;
    }
    findByBillInstallment(billInstallmentId) {
        return this.baseService.httpClient.get(this.url(`find-by-bill-installment/${billInstallmentId}?page=${0}&size=300`), this.options()).pipe(map((json) => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                return page.content;
            }
            return [];
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    pay(paymentDto) {
        return this.baseService.httpClient.post(this.url('pay'), paymentDto).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialPaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialPaymentService_Factory() { return new FinancialPaymentService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialPaymentService, providedIn: "root" });
