<gipi-abstract-form id="dialogListCheckReceived"
                    name="dialogListCheckReceived"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogListCheckReceived">
        Selecione o cheque desejado
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutAlign="space-between center"
                 fxLayoutGap="16px">
                <div fxFlex="40"
                     style="width: 100%;"
                     fxLayout="row"
                     fxLayoutGap="16px">
                    <gipi-form-field id="inputSearch"
                                     #search
                                     fxFlex
                                     type="search"
                                     placeholder="Pesquisar"
                                     showSearch="true"
                                     [disabled]="loading"
                                     [(ngModel)]="filter.researchField"
                                     (onKeydownEnter)="find(null)"
                                     (onSearch)="find(null)">
                    </gipi-form-field>

                    <gipi-button tooltip="Filtrar"
                                 gipi-primary-icon
                                 #searchButton
                                 id="btnOptionsSearch"
                                 svgIcon="filter"
                                 [notification]="appliedFilters && (appliedFilters.length > 0)"
                                 [popover]="true"
                                 [disabled]="loading">
                        <div fxLayout="column"
                             fxLayoutGap="12px"
                             style="max-width: 36rem; padding: 8px;">
                            <div fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlexAlign="space-between center">
                                <gipi-datepicker label="Data inicial"
                                                 id="beginDate"
                                                 name="beginDate"
                                                 [disabled]="loading"
                                                 [(ngModel)]="filter.begin"
                                                 fxFlex="48">
                                </gipi-datepicker>
                                <gipi-datepicker label="Data final"
                                                 id="endDate"
                                                 name="endDate"
                                                 [disabled]="loading"
                                                 [(ngModel)]="filter.end"
                                                 fxFlex="48">
                                </gipi-datepicker>
                            </div>
                            <gipi-form-field label="Emitente"
                                             id="inputFilterIssuer"
                                             name="inputFilterIssuer"
                                             type="text"
                                             [disabled]="loading"
                                             [(ngModel)]="filter.issuer"
                                             fxFlex>
                            </gipi-form-field>
                            <div fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlexAlign="space-between center">
                                <gipi-form-field label="Número do cheque"
                                                 id="inputFilterCheckNumber"
                                                 name="inputFilterCheckNumber"
                                                 type="text"
                                                 mask="0*"
                                                 [disabled]="loading"
                                                 [(ngModel)]="filter.checkNumber"
                                                 fxFlex="48">
                                </gipi-form-field>
                                <gipi-input-currency label="Valor"
                                                     id="inputFilterAmount"
                                                     name="inputFilterAmount"
                                                     type="currency"
                                                     placeholder="R$ 0,00"
                                                     [disabled]="loading"
                                                     [(ngModel)]="filter.amount"
                                                     fxFlex="48">
                                </gipi-input-currency>
                            </div>
                            <gipi-input-select-paged label="Cliente"
                                                     [disabled]="loading"
                                                     [(model)]="filter.client"
                                                     [nextBatchFn]="clientFindByValueFn"
                                                     [propertyFn]="clientFn"
                                                     fxFlex>
                            </gipi-input-select-paged>
                            <gipi-input-select-paged label="Conta bancária"
                                                     [disabled]="loading"
                                                     [(model)]="filter.bankAccount"
                                                     [nextBatchFn]="bankAccountFindByValueFn"
                                                     [property]="'description'"
                                                     fxFlex>
                            </gipi-input-select-paged>
                            <gipi-input-select-paged label="Categoria"
                                                     [disabled]="loading"
                                                     [(model)]="filter.category"
                                                     [nextBatchFn]="postingCategoryFindByOperationFn"
                                                     [propertyFn]="postingCategoryFn"
                                                     fxFlex>
                            </gipi-input-select-paged>
                            <div style="margin-top: 12px;"
                                 fxLayout="row"
                                 fxLayoutGap="12px"
                                 fxFlex>
                                <gipi-button label="Pesquisar"
                                             gipi-primary
                                             id="btnFind"
                                             icon="search"
                                             [disabled]="loading"
                                             (click)="find(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                                <gipi-button label="Limpar"
                                             gipi-secondary
                                             id="btnClear"
                                             icon="clear"
                                             [disabled]="loading"
                                             (click)="clear(); searchButton.popoverTrigger.togglePopover()"
                                             fxFlex>
                                </gipi-button>
                            </div>
                        </div>
                    </gipi-button>
                </div>
            </div>
            <gipi-chips label="Filtros aplicados:"
                        style="max-width: 70%;"
                        [disabled]="loading"
                        [showRemovable]="true"
                        [showRemovableAll]="true"
                        [(ngModel)]="appliedFilters"
                        (removeChange)="removeAppliedFilter($event)"
                        (removeAllChange)="clear()">
            </gipi-chips>
        </div>

        <itss-table [loading]="loading"
                    [columns]="columnsTable"
                    [paginator]="true"
                    [paginatorPageSize]="5"
                    [paginatorLength]="checkReceivedPage.totalElements"
                    [paginatorPageIndex]="checkReceivedPage.number"
                    [data]="checkReceivedPage.content"
                    (page)="find($event)">
        </itss-table>
    </div>
    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="checkReceivedPage?.content?.length <= 0"
                     (click)="close(true)"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close(false)"
                     fxFlex="20"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>

</gipi-abstract-form>

<ng-template #checkboxTemplate
             let-entity>
    <itss-checkbox color="primary"
                   [(ngModel)]="entity.selected"
                   (change)="validateAllSelected()"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>

<ng-template #checkAllTemplate>
    <itss-checkbox color="primary"
                   [(ngModel)]="selected"
                   [(indeterminate)]="indeterminate"
                   (change)="checkAll()"
                   fxFlex="4">
    </itss-checkbox>
</ng-template>
