import { BaseModel } from '@gipi-shared/models/base.model';
import { SaleLegalPersonType, SaleLegalPersonTypeEnum } from '../enums/legal-person-type.enum';

export class SaleLegalPerson extends BaseModel {

    cnpj: string;

    stateRegistration: string;

    type: SaleLegalPersonTypeEnum | SaleLegalPersonType;

    municipalRegistration: string;

    cnae: string;

}
