<gipi-abstract-form id="dialogRecurringRelease"
                    name="dialogRecurringRelease"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRecurringRelease">
        Lançamento recorrente
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px">
            <itss-select-enum label="Periodicidade"
                              name="periodicity"
                              [enum]="periodicityEnum"
                              [(ngModel)]="periodicity"
                              [required]="true"
                              (selectionChange)="selectionChangePeriodicity()"
                              [fxFlex]="periodicity === 'FREE' ? '33.33' : !selectPeriod ? '50' : '100'">
            </itss-select-enum>
            <itss-input label="Qtd. de dias entre parcelas"
                        name="periodicityDay"
                        [(ngModel)]="periodicityDay"
                        *ngIf="periodicity === 'FREE'"
                        [required]="true"
                        maxlength="3"
                        minlength="1"
                        [min]="1"
                        mask="0*"
                        fxFlex="33.33">
            </itss-input>
            <itss-input label="Qtd. de parcelas"
                        name="numberInstallments"
                        [(ngModel)]="numberInstallments"
                        [required]="true"
                        *ngIf="!selectPeriod"
                        maxlength="3"
                        minlength="1"
                        mask="0*"
                        [min]="1"
                        [fxFlex]="periodicity === 'FREE' ? '33.33' : '50'">
            </itss-input>
        </div>
        <div fxLayout="column"
             fxLayoutGap="16px"
             fxFlexFill
             fxFlex="14">
            <itss-checkbox label="Selecionar o período entre parcelas"
                           name="daysBetweenInstallments"
                           color="primary"
                           [(ngModel)]="selectPeriod"
                           (ngModelChange)="modelChangeSelectPeriod()"
                           *ngIf="periodicity === 'MONTHLY'">
            </itss-checkbox>
            <div fxFlex> </div>
        </div>
        <div fxFlex
             *ngIf="periodicity !== 'MONTHLY'">
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px">
            <itss-select-entity label="Dia do vencimento"
                                property="description"
                                [entities]="dayList"
                                [(model)]="day"
                                [required]="true"
                                *ngIf="selectPeriod"
                                fxFlex="33.33">
                <!-- (selectionChange)="selectionChangeDay($event)" -->
            </itss-select-entity>
            <gipi-datepicker label="Data do vencimento inicial"
                             name="initialDueDate"
                             [(ngModel)]="initialDueDate"
                             [required]="true"
                             *ngIf="!selectPeriod"
                             [fxFlex]="selectPeriod ? '33.33' : '100'">
            </gipi-datepicker>
            <gipi-month-year-picker label="Mês/Ano inicial"
                                    id="monthYearBeginPicker"
                                    name="monthYearBeginPicker"
                                    *ngIf="selectPeriod"
                                    [required]="true"
                                    [(ngModel)]="begin"
                                    fxFlex="33.33">
            </gipi-month-year-picker>
            <gipi-month-year-picker label="Mês/Ano final"
                                    id="monthYearEndPicker"
                                    name="monthYearEndPicker"
                                    *ngIf="selectPeriod"
                                    [required]="true"
                                    [(ngModel)]="end"
                                    fxFlex="33.33">
            </gipi-month-year-picker>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutGap="16px"
         fxLayoutAlign="end center">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="save()"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close(false)"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
