import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OAuthUserPublicService } from '@gipi-financial/user/services/user-public.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, EmailUtil, StringUtil } from '@gipisistemas/ng-core';

@Component({
    templateUrl: './request-password.component.html',
    styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent extends AbstractComponent {

    username: string;

    constructor(
        private _userPublicService: OAuthUserPublicService,
        messageService: CustomMessageService,
        router: Router,
        activatedRoute: ActivatedRoute
    ) {
        super(messageService, router, activatedRoute);
    }

    async send(): Promise<void> {
        try {
            this.validate();
            this.loading = true;
            await this._userPublicService.recovery(this.username).toPromise().then(() => {
                this.addSuccessMessage('Operação realizada com sucesso');
                this.router.navigate(['oauth/resend-recovery', this.username]);
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    validate(): void {
        if (!this.isValidUser()) {
            throw new Error('E-mail inválido');
        }
    }

    isValidUser(): boolean {
        return !StringUtil.isEmpty(this.username) && EmailUtil.isValid(this.username.toLowerCase());
    }

    keydownSend(): void {
        if (this.isValidUser()) {
            this.send();
        }
    }

    returnToLogin(): void {
        this.router.navigate(['/oauth/login']);
    }

}
