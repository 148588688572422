import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { DashboardSummaryBankAccount } from '@gipi-dashboard/models/summary-bank-account.model';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { GIPIPageModel, GIPIUuid, NumberUtil, ObjectUtil, PageDTO, StringUtil } from '@gipisistemas/ng-core';
import { FinancialPaths } from '../../../paths/financial.paths';
import { FinancialBankAccount } from '../models/bank-account.model';
import { FinancialBankAccountFilterDTO } from '../models/dto/bank-account-filter.dto';
import { FinancialOfxFileDTO } from '../models/dto/ofx-file.dto';

@Injectable({ providedIn: 'root' })
export class FinancialBankAccountService extends BaseCrudService<FinancialBankAccount, FinancialBankAccountFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService) {
        super(FinancialPaths.bankAccount, http, authenticationService);
    }

    // findByType(type: 'CHECKING_ACCOUNT' | 'CREDIT_CARD' | 'SAVINGS' | 'INVESTMENT' | 'AUTOMATIC_APPLICATION' | 'OTHER', page: number, size: number = 10): Observable<PageDTO<BankAccount>> {
    //     if (!type) {
    //         type = 'CHECKING_ACCOUNT';
    //     }
    //     if ((!page) || (page && (page < 0))) {
    //         page = 0;
    //     }
    //     if ((!size) || (size && (size <= 0))) {
    //         size = 10;
    //     }

    //     return this.http.get(this.url(`find-by-type/${type}?page=${page}&size=${size}`), this.options()).pipe(
    //         map(this.mapper),
    //         catchError(this.handleError)
    //     );
    // }

    findByValue(value: string, page: number, size?: number, sort?: { property: string; direction: 'asc' | 'desc'; }, type?: 'CHECKING_ACCOUNT' | 'CREDIT_CARD' | 'SAVINGS' | 'INVESTMENT' | 'AUTOMATIC_APPLICATION' | 'OTHER'): Observable<PageDTO<FinancialBankAccount>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.property)) {
            lSort = `&sort=${sort.property},${sort.direction}`
        }
        let lType: string = '';
        if (!StringUtil.isEmpty(type)) {
            lType = `&type=${type}`;
        }

        return this.http.get(this.url(`find-by-value?page=${page}&size=${size}${lType}${lSort}&value=${lValue}`), this.options()).pipe(
            takeUntil(this.onDestroy),
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    validate(entity: FinancialBankAccount): void {
        super.validate(entity);
        switch (entity.type.toString()) {
            case 'CHECKING_ACCOUNT':
                this.validateCheckingAccount(entity);
                break;
            case 'CREDIT_CARD':
                this.validateCreditCard(entity);
                break;
            case 'SAVINGS':
                this.validateSavings(entity);
                break;
            case 'INVESTMENT':
                this.validateInvestment(entity);
                break;
            case 'AUTOMATIC_APPLICATION':
                this.validateAutomaticApplication(entity);
                break;
            case 'OTHER':
                this.validateOthers(entity);
                break;
        }
    }

    private validateCheckingAccount(entity: FinancialBankAccount): void {
        if (ObjectUtil.isNewModel(entity.bank)
            || StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.modality)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)
        ) {
            throw new Error('Campos obrigatórios (*) não informados');
        }

        if (entity.bank.code !== '999') {
            if (ObjectUtil.isNull(entity.agency)
                || ObjectUtil.isNull(entity.account)
            ) {
                throw new Error('Campos obrigatórios (*) não informados');
            }
        }
    }

    private validateCreditCard(entity: FinancialBankAccount): void {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNewModel(entity.bankAccount)
            || StringUtil.isEmpty(entity.lastFourNumbers)
            || !NumberUtil.isPositive(entity.closingDay)
            || !NumberUtil.isPositive(entity.expirationDay)
            || ObjectUtil.isNull(entity.flagCard)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        if (entity.closingDay > 31) {
            throw new Error('O Fechamento da Fatura não deve ser maior que 31');
        }
        if (entity.expirationDay > 31) {
            throw new Error('O Vencimento do Cartão não deve ser maior que 31');
        }
    }

    private validateSavings(entity: FinancialBankAccount): void {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.modality)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    private validateInvestment(entity: FinancialBankAccount): void {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    private validateAutomaticApplication(entity: FinancialBankAccount): void {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNewModel(entity.bank)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    private validateOthers(entity: FinancialBankAccount): void {
        if (StringUtil.isEmpty(entity.description)
            || ObjectUtil.isNull(entity.currentBalance)
            || ObjectUtil.isNull(entity.balanceDate)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
    }

    existConciliation(id: GIPIUuid): Observable<boolean> {
        return this.http.get(this.url(`exist-conciliation/${id}`), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    readOfxFile(file: File): Observable<FinancialOfxFileDTO> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(this.url('read-ofx-file'), formData).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    importTransactions(id: GIPIUuid, file: File): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('id', String(id));
        return this.http.post(this.url('import-ofx-transactions'), formData).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    existMovements(bankAccountId: GIPIUuid): Observable<boolean> {
        return this.http.get(this.url(`exist-movements/${String(bankAccountId)}`), this.options()).pipe(
            map(this.mapper),
            catchError(this.handleError)
        );
    }

    dashboardSummary(page: number, size: number): Observable<GIPIPageModel<DashboardSummaryBankAccount>> {
        const validatedPage: number = Math.max(page, 0);
        const validatedSize: number = Math.max(size, 10);

        const params: URLSearchParams = new URLSearchParams();
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());
        params.set('sort', 'description,ASC');

        const queryString: string = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';

        return this.http.get(this.url(`dashboard-summary${queryString}`), this.options()).pipe(
            map(this.mapper),
            takeUntil(this.onDestroy),
            catchError(this.handleError),
        );
    }

}
