import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialBankService } from '@gipi-financial/bank/services/bank.service';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCheck } from '@gipi-financial/check/models/check.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialReceivementService } from '@gipi-financial/receivement/services/receivement.service';
import { CustomMessageService } from '@gipi-shared/services/custom-message.service';
import { AbstractComponent, APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, DocumentUtil, GIPISortModel, INJECTOR, NumberUtil, ObjectUtil, StringUtil, TableColumnBuilder } from '@gipisistemas/ng-core';
export class ReceivementCheckDialogComponent extends AbstractComponent {
    constructor(dialogRef, data = { receivement: null, installmentList: [], isCashier: false, useClientCredit: false }, _receivementService, _bankService, _postingCategoryService, messageService, router, activatedRoute) {
        super(messageService, router, activatedRoute);
        this.dialogRef = dialogRef;
        this.data = data;
        this._receivementService = _receivementService;
        this._bankService = _bankService;
        this._postingCategoryService = _postingCategoryService;
        this.billInstallmentList = [];
        this.checkReceivedList = [];
        this.checkReceived = new FinancialCheckReceived();
        this.receivementAmount = 0;
        this.amountAdded = 0;
        this.amountRemaining = 0;
        this.bankFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankService.findByValue(value, page, 50).toPromise();
            return result;
        });
        this.bankFn = (obj) => `${obj.code} - ${obj.name}`;
        this.postingCategoryFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._postingCategoryService.findByValue(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'CREDIT', true).toPromise();
            if (!ObjectUtil.isNull(result) && !ArrayUtil.isEmpty(result.content)) {
                if (this.billInstallmentList.length === 1) {
                    this.checkReceived.check.category = this.billInstallmentList[0].postingCategory;
                }
            }
            return result;
        });
        this.postingCategoryFn = (obj) => `${obj.fullCode} - ${obj.description}`;
    }
    ngOnInit() {
        super.ngOnInit();
        this.receivement = this.data.receivement;
        this.receivement.amountNet = this.receivement.amountReceivable;
        this.receivementAmount = (this.receivement.amountReceived ? this.receivement.amountReceived : this.receivement.amountReceivable);
        this.receivement.checkReceivedList = [];
        this.billInstallmentList = this.data.installmentList;
        this.newCheckReceived();
    }
    addCheck() {
        try {
            this.validateAddNewCheckReceived();
            this.receivement.checkReceivedList = Array(...this.receivement.checkReceivedList, ObjectUtil.clone(this.checkReceived));
            this.newCheckReceived();
        }
        catch (e) {
            this.handleError(e);
        }
    }
    removeCheck(check) {
        const checkList = [];
        checkList.push(...this.receivement.checkReceivedList);
        checkList.splice(checkList.indexOf(check), 1);
        this.receivement.checkReceivedList = checkList;
        this.calculateAmountReceivable();
    }
    newCheckReceived() {
        this.checkReceived = new FinancialCheckReceived();
        this.checkReceived.check = new FinancialCheck();
        this.checkReceived.check.status = 'OPENED';
        this.checkReceived.check.category = ((this.billInstallmentList) && (this.billInstallmentList.length <= 1)) ? this.billInstallmentList[0].postingCategory : null;
        this.checkReceived.client = this.billInstallmentList[0].bill.client;
        this.checkReceived.issuer = this.checkReceived.client.person.name;
        this.checkReceived.cpfOrCnpjIssuer = ' ';
        if (!ObjectUtil.isNewModel(this.checkReceived.client.person.legalPerson)) {
            this.checkReceived.cpfOrCnpjIssuer = this.checkReceived.client.person.legalPerson.cnpj ? this.checkReceived.client.person.legalPerson.cnpj : ' ';
        }
        else if (!ObjectUtil.isNewModel(this.checkReceived.client.person.naturalPerson)) {
            this.checkReceived.cpfOrCnpjIssuer = this.checkReceived.client.person.naturalPerson.cpf ? this.checkReceived.client.person.naturalPerson.cpf : ' ';
        }
        this.checkReceived.bank = null;
        this.checkReceived.bankAccount = this.data.receivement.bankAccount;
        this.checkReceived.check.issuanceDate = new Date();
        this.checkReceived.check.dueDate = new Date();
        this.checkReceived.comments = ' ';
        this.calculateAmountReceivable();
    }
    calculateAmountReceivable() {
        this.amountAdded = this.receivement.checkReceivedList.reduce((sum, checkReceived) => sum += checkReceived.check.amount, 0);
        this.amountRemaining = this.receivementAmount - this.amountAdded;
        this.checkReceived.check.amount = this.amountRemaining;
    }
    confirm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.validateConfirm();
                this.loading = true;
                yield this._receivementService.receive(this.receivement, this.billInstallmentList, [], this.data.useClientCredit).toPromise().then(() => {
                    this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                    this.close(true);
                }, error => {
                    this.loading = false;
                    this.handleError(error);
                });
            }
            catch (e) {
                this.loading = false;
                this.handleError(e);
            }
        });
    }
    validateConfirm() {
        const amountAdded = Number(this.receivement.checkReceivedList.reduce((sum, checkReceived) => sum += checkReceived.check.amount, 0).toFixed(2));
        const amountReceivable = Number((this.receivementAmount - amountAdded).toFixed(2));
        if (amountAdded < this.receivementAmount) {
            throw new Error(`Ainda falta lançar R$ ${CurrencyUtil.transform(amountReceivable, '1.2-2')} para corresponder o valor total do recebimento`);
        }
        if (amountAdded > this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${CurrencyUtil.transform(Number((amountAdded).toFixed(2)), '1.2-2')} está ultrapassando o valor total do recebimento`);
        }
        if (ArrayUtil.isEmpty(this.receivement.checkReceivedList)) {
            throw new Error('É necessário adicionar no mínimo um cheque, para salvar o recebimento');
        }
    }
    validateAddNewCheckReceived() {
        if (StringUtil.isEmpty(this.checkReceived.cpfOrCnpjIssuer.trim())) {
            throw new Error('Campo CPF/CNPJ é obrigatório e não foi informado');
        }
        else if ((this.checkReceived.cpfOrCnpjIssuer.length !== 11) && (this.checkReceived.cpfOrCnpjIssuer.length !== 14)) {
            throw new Error('Campo CPF/CNPJ foi informado incorretamente');
        }
        else {
            if ((this.checkReceived.cpfOrCnpjIssuer.length === 11) && !DocumentUtil.isValidCpf(this.checkReceived.cpfOrCnpjIssuer)) {
                throw new Error('Campo CPF/CNPJ foi informado incorretamente');
            }
            if ((this.checkReceived.cpfOrCnpjIssuer.length === 14) && !DocumentUtil.isValidCnpj(this.checkReceived.cpfOrCnpjIssuer)) {
                throw new Error('Campo CPF/CNPJ foi informado incorretamente');
            }
        }
        if (StringUtil.isEmpty(this.checkReceived.issuer)) {
            throw new Error('Campo emitente é obrigatório e não foi informado');
        }
        if (!StringUtil.isEmpty(this.checkReceived.phone) && this.checkReceived.phone.length !== 10) {
            if (!StringUtil.isEmpty(this.checkReceived.phone) && this.checkReceived.phone.length !== 11) {
                throw new Error('Campo telefone foi informado incorretamente');
            }
        }
        if (!NumberUtil.isPositive(this.checkReceived.check.checkNumber)) {
            throw new Error('Campo n° cheque é obrigatório e não foi informado');
        }
        const amountAdded = this.receivement.checkReceivedList.reduce((sum, checkReceived) => sum += checkReceived.check.amount, 0);
        if (amountAdded === this.receivementAmount) {
            throw new Error(`O valor lançado R$ ${(amountAdded).toFixed(2)} já corresponde ao valor total do recebimento`);
        }
        if ((this.checkReceived.check.amount + amountAdded) > this.receivementAmount) {
            throw new Error(`O valor que você está tentando lançar R$ ${(amountAdded).toFixed(2)} ultprassa o valor total do recebimento`);
        }
        if (!NumberUtil.isPositive(this.checkReceived.check.amount)) {
            throw new Error('Campo valor é obrigatório e não foi informado');
        }
        if (!DateUtil.isValid(this.checkReceived.check.issuanceDate)) {
            throw new Error('Campo data da emissão é obrigatório e não foi informado');
        }
        if (!DateUtil.isValid(this.checkReceived.check.dueDate)) {
            throw new Error('Campo data do vencimento é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(this.checkReceived.check.category) || ObjectUtil.isNull(this.checkReceived.check.category)) {
            throw new Error('Campo categoria é obrigatório e não foi informado');
        }
        if (ObjectUtil.isNewModel(this.checkReceived.bankAccount) || ObjectUtil.isNull(this.checkReceived.bankAccount)) {
            throw new Error('Campo conta bancária para depósito é obrigatório e não foi informado');
        }
    }
    createTableColumns() {
        return [
            TableColumnBuilder.instance()
                .property('dueDate')
                .name('Vencimento')
                .value((obj) => DateUtil.format(obj.check.dueDate, DateUtil.DATE_FORMAT))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('checkNumber')
                .name('N° cheque')
                .value((obj) => obj.check.checkNumber)
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('postingCategory.description')
                .name('Categoria')
                .value((obj) => obj.check.category.description)
                .width(15)
                .build(),
            TableColumnBuilder.instance()
                .property('eminent')
                .name('Emitente')
                .value((obj) => obj.issuer)
                .build(),
            TableColumnBuilder.instance()
                .property('bankAccount.description')
                .name('Conta bancária')
                .value((obj) => obj.bankAccount.description)
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj) => CurrencyUtil.transform(obj.check.amount, '1.2-2'))
                .align('center center')
                .width(10)
                .build(),
            TableColumnBuilder.instance()
                .property('actions')
                .name('Ações')
                .template(this.actions)
                .align('center center')
                .width(7)
                .build(),
        ];
    }
    close(isSaved) {
        this.dialogRef.close(isSaved);
    }
}
