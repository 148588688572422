import { HttpClient } from '@angular/common/http';

import { FinancialCompany } from '@gipi-financial/company/models/company.model';
import { AbstractCrudService, FilterDTO } from '@gipisistemas/ng-core';
import { AbstractModel } from '@gipisistemas/ng-core/core/models/abstract.model';
import { CustomAuthenticationService } from './custom-authentication.service';

export class BaseCrudService<T extends AbstractModel, F extends FilterDTO> extends AbstractCrudService<T, F> {

    constructor(
        path: string,
        http: HttpClient,
        protected authenticationService: CustomAuthenticationService,
    ) {
        super(path, http);
    }

    getCurrentCompany(): FinancialCompany {
        const company = new FinancialCompany();
        company.id = this.authenticationService.token.user.currentCompany;
        return company;
    }

}
