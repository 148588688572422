import { FinancialAccounting } from '@gipi-financial/accounting/models/accounting.model';
import { FinancialLineOfBusiness } from '@gipi-financial/line-of-business/models/line-of-business.model';
import { FinancialPerson } from '@gipi-financial/person/models/person.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { GIPIUuid } from '@gipisistemas/ng-core';

export class FinancialCompany extends GIPIBaseAbstractModel {

    /** Contabilidade da empresa */
    accounting: FinancialAccounting;

    /**
     * Caminho da logo da empresa
     * @Min 0 characters
     * @Max 50 characters
     */
    brand: string;

    /** Linha de negócio da empresa */
    lineOfBusiness: FinancialLineOfBusiness;

    /** @Required Pessoa */
    person: FinancialPerson;

    /** Nome do responsável */
    responsibleName: string;

    registrationCompanyId: GIPIUuid;

}
