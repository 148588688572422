import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { ConfigurationScanntechUrl } from './scanntech-url.model';

export class ConfigurationScanntech extends GIPIBaseAbstractModel {
    /**
     * @Required Código da empresa, fornecido pela Scanntech
     * @Min 1 characters
     */
    companyCode: number;

    /** Data de início do envio de dados. (Configuração de integração) */
    dataSendingStartDate: Date;

    /**
     * @Required Intervalo entre sincronizações, definido em minutos.
     * @Min 1 minute
     */
    intervalBetweenSyncs: number;

    /** @Required Senha para autenticação */
    password: string;

    /**
     * @Required Quantidade de vendas por lote
     * @Min 1 sale
     * @Max 300 sales
     */
    salesQuantityPerLot: number;

    /**
     * @Required Código da loja. fornecido pela Scanntech. Por exemplo, para duas lojas: 1, 2.
     * @Min 1 characters
     */
    storeCode: number;

    /** Horário para envio dos fechamentos. Por exemplo, '09:00' */
    timeForSendingPendingClosings: string;

    /** @Required Lista de URL base para acessar a API Scanntech */
    urlList: ConfigurationScanntechUrl[];

    /** @Required Nome de usuário para autenticação */
    userName: string;

}
