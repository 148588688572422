import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

import { RegistrationApplication } from '@gipi-registration/application/models/application.model';
import { RegistrationApplicationFilterDTO } from '@gipi-registration/application/models/dto/application-filter.dto';
import { RegistrationApplicationService } from '@gipi-registration/application/services/application.service';
import { ArrayUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, StringUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ApplicationListData {
    applicationsSelected: RegistrationApplication[];
    selectOne: boolean;
}

@Component({
    templateUrl: './application-list-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `]
})
export class ApplicationListDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    @ViewChild('checkboxAllTemplate', { static: true }) checkboxAllTemplate: TemplateRef<any>;

    @ViewChild('checkboxTemplate', { static: true }) checkboxTemplate: TemplateRef<any>;

    @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;

    public columns: TableColumnDTO[] = [];

    public applicationsSelected: RegistrationApplication[] = [];
    public selectOne: boolean = false;

    public filter: RegistrationApplicationFilterDTO = new RegistrationApplicationFilterDTO();

    public applicationPage: GIPIPageModel<RegistrationApplication> = new GIPIPageModel();

    public selectedAll: boolean = false;
    public indeterminateAll: boolean = false;

    public allStatusChecked: boolean = false;
    public indeterminateStatusChecked: boolean = true;
    public enabledStatusChecked: boolean = true;
    public disabledStatusChecked: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ApplicationListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ApplicationListData = { applicationsSelected: [], selectOne: false },
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _applicationService: RegistrationApplicationService,
    ) {
        super(baseService, activatedRoute);
        this.basePermissionList = {
            MAKE: '',
            UPDATE: '',
            READ: '',
            DELETE: '',
            ENABLE_DISABLE: ''
        };
    }

    ngOnInit() {
        super.ngOnInit();
        this.applicationsSelected = [...this.data.applicationsSelected];
        this.selectOne = this.data.selectOne;

        this.columns = this.createTableColumns();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public createTableColumns(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('checkAll')
                .templateHeader(this.checkboxAllTemplate)
                .template(this.checkboxTemplate)
                .width(7)
                .align('center center')
                .visible(!this.selectOne)
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Descrição')
                .marginLeft(15)
                .sortable(true)
                .value((obj: RegistrationApplication) => !StringUtil.isEmpty(obj.description) ? obj.description : '')
                .action((obj: RegistrationApplication) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
            TableColumnBuilder.instance()
                .property('status')
                .name('Status')
                .align('center center')
                .width(13)
                .marginLeft(5)
                .marginRight(5)
                .template(this.statusTemplate)
                .action((obj: RegistrationApplication) => {
                    obj.selected = !obj.selected;
                    this.select(obj, true);
                    if (this.selectOne) {
                        this.close(true, obj);
                    }
                })
                .build(),
        ];
    }

    public close(isConfirm: boolean, applicationSelected?: RegistrationApplication): void {
        try {
            if (isConfirm) {
                if (this.selectOne) {
                    this.dialogRef.close([applicationSelected]);
                } else {
                    this.dialogRef.close(this.applicationsSelected);
                }
            } else {
                this.dialogRef.close(null);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    public setTextStatus(entity: RegistrationApplication): string {
        return entity.enabled ? 'Ativo' : 'Inativo';
    }

    public setColorStatus(entity: RegistrationApplication): string {
        return entity.enabled ? '#02840f' : '#c24245';
    }

    public select(entity: RegistrationApplication, validate: boolean): void {
        setTimeout(() => {
            try {
                if (entity.selected) {
                    this.applicationsSelected.push(entity);
                } else {
                    this.applicationsSelected.splice(this.applicationsSelected.findIndex(e => e.id === entity.id), 1);
                }

                if (validate) {
                    this._validateAllSelected();
                }
            } catch (e) {
                this.handleError(e);
            }
        });
    }

    public checkAll(): void {
        this.applicationPage.content.forEach(entity => {
            entity.selected = this.selectedAll;
            this.select(entity, false);
        });
        this._validateAllSelected();
    }

    private _validateAllSelected(): void {
        const list: RegistrationApplication[] = this.applicationPage.content.filter(entity => entity.selected);
        this.selectedAll = list.length > 0;
        this.indeterminateAll = (list.length > 0) && (list.length !== this.applicationPage.content.length);
    }

    public checkAllStatus(): void {
        this.enabledStatusChecked = false;
        this.disabledStatusChecked = false;

        this.indeterminateStatusChecked = false;
    }

    public validateAllStatusSelected(): void {
        if (
            this.enabledStatusChecked &&
            this.disabledStatusChecked
        ) {
            this.allStatusChecked = true;
            this.indeterminateStatusChecked = false;
        } else if (
            this.enabledStatusChecked ||
            this.disabledStatusChecked
        ) {
            this.indeterminateStatusChecked = true;
            this.allStatusChecked = false;
        } else {
            this.indeterminateStatusChecked = false;
            this.allStatusChecked = false;
        }
    }

    private _setStatusInFilter(): void {
        if (this.allStatusChecked) {
            this.filter.enabled = null;
        }
        if (this.enabledStatusChecked) {
            this.filter.enabled = true;
        }
        if (this.disabledStatusChecked) {
            this.filter.enabled = false;
        }
    }

    public findAll(pageEvent?: PageEvent): void {
        try {
            this.loading = true;
            this.applicationPage = new GIPIPageModel<RegistrationApplication>();

            if (pageEvent) {
                this.filter.pageNumber = pageEvent.pageIndex;
                this.filter.pageSize = pageEvent.pageSize;
                this.filter.offset = pageEvent.pageIndex * pageEvent.pageSize;
            } else {
                this.filter.pageNumber = 0;
                this.filter.pageSize = 5;
                this.filter.offset = 0;
            }

            this._setStatusInFilter();

            this.filter.applicationsIdsExcluded = [];
            if (!ArrayUtil.isEmpty(this.applicationsSelected)) {
                this.filter.applicationsIdsExcluded = this.applicationsSelected.map(p => p.id);
            }

            this._applicationService.findAll(this.filter).toPromise().then(page => {
                this.applicationPage = page;
                this.loading = false;
            }, (error) => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.handleError(e);
        }
    }

}
