<gipi-abstract-form id="dialogRegisterManufacturer"
                    name="dialogRegisterManufacturer"
                    [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterManufacturer">
        Cadastrar fabricante
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             fxLayoutGap="16px">
            <itss-input label="Nome"
                        id="manufacturerName"
                        name="manufacturerName"
                        [required]="true"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="manufacturer.person.name"
                        [min]="5"
                        [max]="60"
                        [maxlength]="60"
                        fxFlex
                        fxFlex.lt-sm="100">
            </itss-input>
            <gipi-input-select-enum label="Situação"
                                    id="manufacturerEnabled"
                                    name="manufacturerEnabled"
                                    [disabled]="loading || (typeOperation !== 'EDIT')"
                                    [required]="true"
                                    [valuesExclud]="[]"
                                    [enum]="optionActiveSituationEnum"
                                    [(ngModel)]="optionActiveSituationValue"
                                    fxFlex="30"
                                    fxFlex.lt-sm="100">
            </gipi-input-select-enum>
        </div>
        <div fxLayout="row"
             fxLayout.lt-md="column"
             fxLayoutGap="16px">
            <itss-input label="CNPJ"
                        id="manufacturerCNPJ"
                        name="manufacturerCNPJ"
                        mask="00.000.000/0000-00"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="manufacturer.person.legalPerson.cnpj"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Inscrição estadual"
                        id="manufacturerStateRegistration"
                        name="manufacturerStateRegistration"
                        mask="0*"
                        [disabled]="loading || (typeOperation === 'VIEW')"
                        [(ngModel)]="manufacturer.person.legalPerson.stateRegistration"
                        [min]="0"
                        [max]="30"
                        [maxlength]="30"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>
