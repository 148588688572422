import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { RegistrationConnectConfigVersion } from '../enums/connect-config-version.enum';

export class RegistrationConnectConfig extends GIPIBaseAbstractModel {

    /** Id do cliente na connect */
    idClient: string;

    /** Login da connect */
    login: string;

    /** Senha da connect */
    password: string;

    /** Versão da integração com a connect V1, V8 */
    version: RegistrationConnectConfigVersion;

}
