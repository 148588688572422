import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { NotificationPaths } from '@gipi-paths/notification.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIUuid, UUIDUtil } from '@gipisistemas/ng-core';
import { NotificationNotificationClosingMonthDTO } from '../models/dto/notification-closing-month.dto';
import { NotificationNotificationClosingMonth } from '../models/notification-closing-month.model';

@Injectable({ providedIn: 'root' })
export class NotificationNotificationClosingMonthService extends GIPIAbstractCrudService<NotificationNotificationClosingMonth, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService) {
        super(NotificationPaths.notificationClosingMonth, 'v1', baseService);
    }

    closeMonth(entity: NotificationNotificationClosingMonthDTO, version?: string): Observable<GIPIUuid[]> {
        return this.baseService.httpClient.post(this.url(`close-month`, version), entity, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    openMonth(idBillInstallment: GIPIUuid, version?: string): Observable<GIPIUuid> {
        if (!UUIDUtil.isValid(idBillInstallment)) {
            return null;
        }

        return this.baseService.httpClient.post(this.url(`open-month/${idBillInstallment}`, version), null, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
