import { Injectable } from '@angular/core';
import { GIPIPageModel } from '@gipisistemas/ng-core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { NotificationPaths } from '@gipi-paths/notification.paths';
import { ArrayUtil, DateUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { NotificationNotificationType } from '../enums/notification-type.enum';
import { NotificationNotificationFilterDTO } from '../models/dto/notification-filter.dto';
import { NotificationNotificationStatementDTO } from '../models/dto/notification-statement.dto';
import { NotificationNotificationDTO } from '../models/dto/notification.dto';
import { NotificationNotificationResponse } from '../models/notification-response.model';
import { NotificationNotification } from '../models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationInvoiceIntegrationsService extends GIPIAbstractCrudService<NotificationNotification, NotificationNotificationFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(NotificationPaths.notification, 'v1', baseService);
    }

    findIfHaveNotificationToReceive(version?: string): Observable<boolean> {
        return this.baseService.httpClient.get(this.url(`find-if-have-notification-to-receive`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    findNotificationStatement(initialDate?: Date, endDate?: Date, isClosed?: boolean, version?: string): Observable<NotificationNotificationStatementDTO> {
        const isValidDate = (date: Date) => !ObjectUtil.isNull(date) && DateUtil.isValid(date);
        const params: URLSearchParams = new URLSearchParams();
        if (isValidDate(initialDate)) {
            params.set('initialDate', initialDate.toISOString());
        }
        if (isValidDate(endDate)) {
            params.set('endDate', endDate.toISOString());
        }
        if (!ObjectUtil.isNull(isClosed)) {
            params.set('isClosed', String(isClosed));
        }

        const queryString: string = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';

        return this.baseService.httpClient.get(this.url(`find-notification-statement${queryString}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    findNotificationStatementIgnoreTenancy(page: number, size: number = 10, initialDate?: Date, endDate?: Date, isClosed: boolean = null, version?: string): Observable<GIPIPageModel<NotificationNotificationStatementDTO>> {
        const validatedPage: number = Math.max(page, 0);
        const validatedSize: number = Math.max(size, 10);

        const params: URLSearchParams = new URLSearchParams();
        params.set('page', validatedPage.toString());
        params.set('size', validatedSize.toString());

        const isValidDate = (date: Date) => !ObjectUtil.isNull(date) && DateUtil.isValid(date);
        if (isValidDate(initialDate)) {
            params.set('initialDate', initialDate.toISOString());
        }
        if (isValidDate(endDate)) {
            params.set('endDate', endDate.toISOString());
        }

        if (isClosed !== null) {
            params.set('isClosed', isClosed.toString());
        }

        const queryString: string = !StringUtil.isEmpty(params.toString()) ? `?${params.toString()}` : '';

        return this.baseService.httpClient.get(this.url(`find-notification-statement-ignore-tenancy${queryString}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    sendWhatsappNotifications(notificationType: NotificationNotificationType, notificationList: NotificationNotificationDTO[], version?: string): Observable<NotificationNotificationResponse> {
        if (ArrayUtil.isEmpty(notificationList)) {
            throw new Error('Informe ao menos uma mensagem à ser enviada');
        }

        return this.baseService.httpClient.post(this.url(`send-whatsapp-notifications/${notificationType}`, version), notificationList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
