import { RegistrationBankAccount } from '@gipi-registration/bank-account/models/bank-account.model';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';

export class RegistrationCardAdministrator extends GIPIBaseAbstractModel {

    /** Conta bancária */
    bankAccount: RegistrationBankAccount;

    /** @Required Porcentagem da taxa de venda à vista */
    cashFee: number;

    /**
     * @Required CNPJ da administradora
     * @Min 14 characters
     * @Max 14 characters
     */
    cnpj: string;

    /** Percentual de antecipação de crédito */
    creditAnticipationFee: number;

    /** @Required Porcentagem para crédito */
    // creditFee: number;

    /**
     * @Required Dias para recebimento de dinheiro
     * @Min 0 characters
     */
    dayCashReceipt: number;

    /**
     * @Required Dias para recebimento de crédito
     * @Min 0 characters
     * @Max 99 characters
     */
    dayCreditReceipt: number;

    /**
     * @Required Dias para recebimento de débito
     * @Min 0 characters
     * @Max 99 characters
     */
    dayDebitReceipt: number;

    /** Percentual de antecipação de débito */
    debitAnticipationFee: number;

    /** @Required Porcentagem para valor de débito */
    debitFee: number;

    /**
     * @Required Descrição
     * @Min 5 characters
     * @Max 150 characters
     */
    description: string;

    /** @Required Porcentagem para venda parcelada */
    installmentFee: number;

}
